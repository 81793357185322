import ApiBase from '@/services/z-api/v1/api-base'
import { userStore } from '@/stores'

class Products extends ApiBase {
  constructor (transport) {
    super(transport)
    this.getProduct = this.getProduct.bind(this)
    this.getProductPrices = this.getProductPrices.bind(this)
    this.getPromoProductPrices = this.getPromoProductPrices.bind(this)
  }

  /**
   * Get a product by SKU
   * @param {string} code
   */
  getProduct (code) {
    return this._withDecoratedPromise(() => {
      if (userStore().isRegisteredUser || userStore().isRegisteredGuest) {
        return this.transport.get(`/rest/api/v2/products/${encodeURIComponent(code)}`)
      } else {
        return this.transport.get(`/resource/api/v2/products/${encodeURIComponent(code)}`, undefined, {
          'Is-valid-request': 'true'
        })
      }
    })
  }

  /**
   * Get employee prices by SKUs
   * @param {Array.<string>} codesArr
   */
  getProductPrices (codesArr) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v2/prices/employeePrices', codesArr)
    })
  }

  /**
   * Get buy x get y promo prices by SKUs
   * @param {Array.<string>} codesArr
   * @param {string} promotionCode
   */
  getPromoProductPrices (codesArr, promotionCode) {
    return this._withDecoratedPromise(async () => {
      const response = await this.transport
        .post('/rest/api/v2/prices/promotionBuyXGetYPrices',
          {
            productCodes: codesArr,
            promotionCode
          }
        )
      return response
    })
  }
}

export default Products
