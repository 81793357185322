/**
 * There is a document which returns 404 status code on non-local envs
 * This function loads this document so that SEO receives the true 404 status code
 * Initialize a new instance of the API class.
 */
export function redirectToNotFound () {
  window.location.href = '/404'
}

const noImage = require('@/assets/no-image.jpg')

export function onImageError (event) {
  if (event?.target?.src && event.target.src !== noImage) {
    event.target.src = noImage
  }
}
