<script>
export default {
  name: 'IcoBell',
  render () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17.902 15.976"
        role="presentation"
      >
        <g>
          <rect
            x="8.531"
            width="1"
            height="2.574"
            fill="#fff"
          />
          <path
            d="M8.951,15.976a2.477,2.477,0,0,1-2.592-2.411l1-.041-.5.021.5-.024a1.5,1.5,0,0,0,1.593,1.455A1.438,1.438,0,0,0,10.543,13.6l1,.046A2.436,2.436,0,0,1,8.951,15.976Z"
            fill="#fff"
          />
          <path
            d="M0,14.089H17.9V13.48l-2.08-1.249a2.274,2.274,0,0,1-.963-2.05,37.771,37.771,0,0,0-.33-3.815C14,2.1,10.675,1.022,9.031,1.065h-.14a5.579,5.579,0,0,0-5.519,5.3,34.752,34.752,0,0,0-.326,3.9,2.232,2.232,0,0,1-.967,1.964L0,13.479Zm5.043-3.884c0-.012.02-1.24.314-3.591C5.789,3.15,8.531,3.1,8.87,3.065h.175c.267-.013,3.066.084,3.5,3.549.285,2.276.313,3.5.317,3.5a4.667,4.667,0,0,0,.339,1.976H4.7A4.469,4.469,0,0,0,5.043,10.205Z"
            fill="#fff"
          />
        </g>
      </svg>
    )
  }
}
</script>
