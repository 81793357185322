import { ONLY_LETTERS_REGEX } from '@/helpers'

export default {
  methods: {
    pushDataToForm (data) {
      Object.values(this.form).forEach((field) => {
        if (Object.keys(data).includes(field.title)) {
          field.value = data[field.title]
        }
      })
    },
    pushFormToData (data) {
      Object.values(this.form).forEach((field) => {
        if (field.title) {
          data[field.title] = field.value
        }
      })
    },
    emailFormatter (val) {
      return val.toLowerCase()
    },
    mapFormErrors (errors) {
      if (errors && errors.length) {
        this.clearFormServerErrors()
        errors.forEach(error => {
          let field
          if (this.form && Array.isArray(this.form)) {
            field = this.form.find(el => el.title === error.subject)
          } else if (this.form && typeof this.form === 'object') {
            field = Object.entries(this.form).find(entry => {
              return entry[1].title === error.subject
            })
            if (field && field[1]) field = field[1]
          }

          if (field) {
            const message = error.message === 'Invalid field: {0}' ? 'Invalid field' : error.message
            field.errors = { ...field.errors || {}, server: message }
          } else {
            this.form._errors.push(error)
          }
        }, this)
      }
    },
    mapFormValidationErrors (errors) {
      if (errors && errors.length) {
        const filteredErrors = errors.filter(error => ['ValidationError', 'invalid_grant', 'unauthorized', 'VoucherOperationError'].includes(error.type))
        this.mapFormErrors(filteredErrors)
      }
    },
    onFieldChange (field) {
      this.$v.$touch()
      if (field && field.errors) {
        this.clearFieldServerError(field)
      }
    },
    clearFieldServerError (field, vField) {
      if (field?.errors?.server) {
        delete field.errors.server
        // @TODO investigate the reason behind faulty $touch, hack to trigger the update
        const _value = field.value
        field.value = ''
        field.value = _value
      }
    },
    clearFormServerErrors () {
      for (const key in this.form) {
        this.clearFieldServerError(this.form[key])
      }
      this.form._errors = []
    },
    withPresendRoutine (cb) {
      this.clearFormServerErrors()
      if (this.isModelValid) {
        return cb()
      } else {
        this.isValidationRun = true
      }
    },
    sendForm (action, data, asyncCallback) {
      this.isLoading = true
      this.$emit('loading', true)
      this.isValidationRun = true
      let response
      return new Promise((resolve, reject) => {
        action(data)
          .then(async res => {
            if (asyncCallback) await asyncCallback()
            this.isValidationRun = false
            response = res
            return res
          })
          .catch((reason) => {
            if (reason?.response?.data?.length) {
              this.mapFormValidationErrors(reason.response.data)
            }
            reject(reason)
          })
          .finally(() => {
            this.isLoading = false
            this.$emit('loading', false)
            resolve(response)
          })
      })
    },
    server (value, model) {
      return !model.errors.server
    },
    password (value) {
      if (value.length < 8 || value.length > 15) return false
      const reg = [/[a-z]/, /[A-Z]/, /[0-9]/, /[(!@#$%&]/]
      let count = 0
      reg.forEach(r => { if (r.test(value)) count++ })
      return count >= 3
    },
    zip (value) {
      // zip code validation regex supports only 5 or 9 digits and dash
      return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
    },
    cvv (value) {
      return this.cardType === 'americanexpress' ? value.length === 4 : value.length === 3
    },
    lettersAndSpaces (value) {
      return !!ONLY_LETTERS_REGEX.test(value)
    },
    expFormat (value) {
      const month = value.substring(0, 2) - 1
      const year = value.substring(2)
      const currentDate = new Date().getTime()
      const lastDayOfMonth = (new Date(year, month, 0)).getDate()
      const expDate = new Date(
        new Date(
          new Date(year, month, lastDayOfMonth + 1)
        ).setMilliseconds(-1)
      )
      return !value || (month < 12 && currentDate < expDate)
    }
  },
  computed: {
    isModelValid () {
      return !this.$v.$invalid
    },
    cardType () {
      if (this.form.card?.value[0]) {
        switch (+this.form.card.value[0]) {
          case 3:
            return 'americanexpress'
          case 4:
            return 'visa'
          case 5:
            return 'mastercard'
          case 6:
            return 'discover'
          default:
            return null
        }
      } else {
        return null
      }
    }
  }
}
