import ContentfulService from '@/services/contentful'
import { defineStore } from 'pinia'

const defaultData = {
  title: '404',
  subtitle: 'Oops, the page you are trying to access cannot be found.',
  tip: 'Head back home to continue shopping.',
  link: {
    url: '/',
    text: 'RETURN TO DIRECTTOOLSOUTLET.COM'
  },
  image: {
    url: '/img/404-image.37b0d39d.png',
    alt: '404 image'
  }
}

const state = {
  pageData: null
}

const mutations = {
  SET_NOTFOUND_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setNotFoundPageData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'compositeCollection',
      'not-found'
    ))?.fields
    let items = null
    if (entry.items) {
      items = entry.items.filter(el => el.fields)
    }
    const pageData = entry && items
      ? {
        title: entry.title || defaultData.title,
        subtitle: items.find(el => el.fields.key === 'not-found-subtitle')?.fields?.textString || defaultData.subtitle,
        tip: items.find(el => el.fields.key === 'not-found-tip')?.fields?.textString || defaultData.tip,
        link: items.find(el => el.fields.key === 'not-found-link')
          ? {
            text: items.find(el => el.fields.key === 'not-found-link').fields?.title,
            url: items.find(el => el.fields.key === 'not-found-link').fields?.url
          }
          : defaultData.link,
        image: items.find(el => el.fields.key === 'not-found-image') ? ContentfulService.extractImage(items.find(el => el.fields.key === 'not-found-image').fields?.image) : defaultData.image
      }
      : defaultData

    pageData.meta = {
      title: pageData.title,
      robots: 'noindex, nofollow'
    }
    this.SET_NOTFOUND_PAGE_DATA(pageData)
  }
}

export const useNotFound = defineStore('notFound', {
  state: () => ({
    ...state
  }),
  actions
})
