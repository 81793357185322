import Vue from 'vue'
import router from '@/router'
import { cartStore, checkoutFlowStore } from '@/stores'

export const handleValidationError = async (e) => {
  if (e.response?.data && e.response?.data[0].type) {
    const message = e.response?.data[0].message
    const type = e.response?.data[0].type
    if (e.response?.data[0].type === 'PaymentAuthError') {
      Vue.toasted.error(
        '<span>There was a problem authorizing your credit card payment.<br>Please verify that you have entered the information correctly.<br>If you are still unable to place your order please contact your financial institution for further assistance.</span>'
      )
    } else if (
      type === 'ValidationError' ||
      message === 'Delivery address is not set' ||
      message === 'Delivery mode is not set'
    ) {
      Vue.toasted.error(
        '<span>There was a problem processing your order.<br>Please ensure that you have correctly entered the address and delivery mode.<br></span>'
      )
      await checkoutFlowStore().resetCheckoutFlow()
      await cartStore().setCartData() // refresh the cart
      router.push({ name: 'Cart' })
    } else if (
      message === 'Cannot place order. There was no checkout cart created yet!' // the cart id seems to be outdated
    ) {
      Vue.toasted.info(checkoutRedirectMessage)
      await checkoutFlowStore().resetCheckoutFlow()
      await cartStore().setCartData() // refresh the cart
      router.push({ name: 'Cart' })
    } else if (message) {
      Vue.toasted.error(message)
    }
  } else {
    throw e
  }
}

export const checkoutRedirectMessage = '<span>Please be aware that there have been recent changes to your cart.<br>Kindly review the updated contents on the cart page for accuracy and completeness.<br></span>'
