<template>
  <div
    ref="modal"
    :aria-label="title"
    class="modal"
    role="dialog"
    tabindex="0"
    @keydown.esc="closeModal"
  >
    <div
      class="modal-window"
      :class="{ 'modal-window--wide': wide }"
    >
      <div class="modal-header">
        <h3>{{ title }}</h3>
        <button
          v-if="showCloseBtn"
          ref="closeBtn"
          class="hamburger-btn hamburger-btn--cross"
          aria-label="Close Modal"
          @click="closeModal"
        >
          <div class="hamburger-btn-box">
            <div class="hamburger-btn-inner" />
          </div>
        </button>
      </div>
      <div class="modal-body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'pinia'
import { appStore } from '@/stores'

export default {
  name: 'Modal',
  props: {
    title: {
      type: String,
      required: true,
      default: 'Modal window'
    },
    showCloseBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    mountInBody: {
      type: Boolean,
      default: true
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  mounted () {
    if (this.mountInBody) {
      document.body.appendChild(this.$refs.modal)
    }
    this.$refs.closeBtn?.focus()
    this.SET_OVERFLOW_HIDDEN(true)
  },
  beforeDestroy () {
    if (this.mountInBody) {
      this.$refs.modal.remove()
    }
    this.SET_OVERFLOW_HIDDEN(false)
  },
  methods: {
    ...mapActions(appStore, ['SET_OVERFLOW_HIDDEN']),
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
