import settings from '@/settings'
import { loadScript } from '@/helpers'
import router from '@/router'
import { cartStore } from '@/stores'
import { app } from '@/main'

const scriptId = 'beacon.riskified.com'

function initScript (sessionId) {
  const shopUrl = 'directtoolsoutlet.com'
  const url = 'https://beacon.riskified.com?shop=' + shopUrl + '&sid=' + sessionId
  loadScript(url, {
    defer: true,
    id: scriptId
  })
}

function isInitialized () {
  return Boolean(document.getElementById(scriptId))
}

export const RiskifiedService = {
  init (sessionId = null) {
    if (settings.services.riskified.enabled) {
      if (sessionId) initScript(sessionId)
      // watch for the cart guid changes and notify the riskified
      app.$watch(() => cartStore().cartGuid, () => {
        this.handleCartGuidChanged.bind(this)
      })
    }
  },
  go (toPath) {
    if (
      isInitialized() &&
      settings.services.riskified.enabled &&
      cartStore().cartGuid &&
      window.RISKX
    ) {
      window.RISKX.go(toPath)
    }
  },
  handleCartGuidChanged () {
    if (
      settings?.services?.riskified?.enabled &&
      cartStore().cartGuid &&
      window.RISKX
    ) {
      if (!isInitialized()) {
        initScript(cartStore().cartGuid)
      } else {
        window.RISKX.setSid(cartStore().cartGuid)
        this.go(router.currentRoute?.path)
      }
    }
  }
}
