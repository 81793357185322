import zApiService from '@/services/z-api'
import { transformOrdersEntry, transformOrderDetailsEntry } from '@/transformers'
import { defineStore } from 'pinia'

const state = {
  ordersData: null,
  ordersTotalResults: null,
  orderDetailsData: null,
  ordersSortBy: 'byDate',
  ordersSortDirection: 'DESC'
}

const mutations = {
  SET_ORDERS (data) {
    this.ordersData = data
  },
  SET_ORDERS_TOTAL_RESULTS (data) {
    this.ordersTotalResults = data
  },
  UNSET_ORDERS_DATA () {
    this.ordersData = null
    this.ordersTotalResults = null
  },
  SET_ORDER_DETAILS (data) {
    this.orderDetailsData = data
  },
  UNSET_ORDER_DETAILS_DATA () {
    this.orderDetailsData = null
  },
  SET_ORDERS_SORT (data) {
    if (data === this.ordersSortBy) {
      this.ordersSortDirection = this.ordersSortDirection === 'DESC' ? 'ASC' : 'DESC'
    } else {
      this.ordersSortBy = data
      this.ordersSortDirection = 'DESC'
    }
  }
}

const actions = {
  ...mutations,
  async getOrders (data) {
    const zOrders = await zApiService.orders.getOrders(data.userId, data.options)
    const orders = zOrders ? transformOrdersEntry(zOrders) : []
    this.SET_ORDERS(orders)
    this.SET_ORDERS_TOTAL_RESULTS(zOrders.pagination.totalResults)
  },
  async getOrderById (data) {
    const zOrder = await zApiService.orders.getOrderById(data.userId, data.orderNumber)
    const order = zOrder ? transformOrderDetailsEntry(zOrder) : null
    this.SET_ORDER_DETAILS(order)
  },
  async getGuestOrderById (orderGUID) {
    const zOrder = await zApiService.orders.getGuestOrderById(orderGUID)
    const order = zOrder ? transformOrderDetailsEntry(zOrder) : null
    this.SET_ORDER_DETAILS(order)
  }
}

export const ordersStore = defineStore('orders', {
  state: () => ({
    ...state
  }),
  actions
})
