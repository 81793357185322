<script>
export default {
  name: 'IcoLike',
  render () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15.209 14.848"
        role="presentation"
      >
        <path
          d="M7.852,14.848l-.634-.44C2.9,11.415.136,7.673.005,4.644A4.209,4.209,0,0,1,1.544,1.091C3.869-.837,6.294.149,7.721,1.3A5.427,5.427,0,0,1,10.673.052a4.322,4.322,0,0,1,3.238,1.211,3.821,3.821,0,0,1,1.3,2.97c-.046,4.229-6.083,9.523-6.771,10.113ZM4.412,2.014a2.447,2.447,0,0,0-1.591.616A2.222,2.222,0,0,0,2,4.558C2.1,6.765,4.366,9.817,7.719,12.307c1.932-1.784,5.464-5.561,5.49-8.1a1.8,1.8,0,0,0-.632-1.458,2.385,2.385,0,0,0-1.764-.706,4.012,4.012,0,0,0-2.344,1.28l-.752.86-.753-.853A3.863,3.863,0,0,0,4.412,2.014Z"
          fill="#646464"
        />
      </svg>
    )
  }
}
</script>
