import ApiBase from '@/services/z-api/v1/api-base'
import { transformAddressBookRecordToForm, transformUserAddress } from '@/transformers'

class AddressBookService extends ApiBase {
  constructor (transport) {
    super(transport)
    this.getRecords = this.getRecords.bind(this)
    this.createRecord = this.createRecord.bind(this)
    this.deleteRecord = this.deleteRecord.bind(this)
    this.patchRecord = this.patchRecord.bind(this)
  }

  _getEndpoint () {
    return `/rest/api/v2/users/${this._getUserId()}/addresses`
  }

  /**
   * @returns {Promise<Object[]>} returns an array of user's addresses
   */
  getRecords () {
    return this._withDecoratedPromise(() => {
      return this.transport.get(this._getEndpoint())
    })
  }

  /**
   * Create a new address in the user's address book
   * @param {Object} data is a new address's model
   * @returns {Promise<Object>} returns a new address
   */
  async createRecord (data) {
    const address = await this._withDecoratedPromise(async () => {
      return this.transport.post(this._getEndpoint(), transformUserAddress(data))
    })
    return transformAddressBookRecordToForm(address)
  }

  /**
   * Delete an address in the user's address book
   * @param {string} addressId is an address's id
   * @returns {Promise<void>}
   */
  deleteRecord (addressId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(this._getEndpoint() + `/${addressId}`)
    })
  }

  /**
   * Update an address in the user's address book
   * @param {Object} data is an address's record to update
   * @returns {Promise<void>}
   */
  patchRecord (data) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(this._getEndpoint() + `/${data.id}`, transformUserAddress(data))
    })
  }
}

export default AddressBookService
