<template>
  <ul
    v-if="crumbsData"
    class="crumbs"
  >
    <li
      v-for="crumb in crumbsData"
      :key="crumb.id"
      class="crumbs__item"
    >
      <router-link
        v-if="crumb.hasAction"
        class="crumbs__link"
        :to="crumb.url"
        v-text="crumb.title"
      />
      <span
        v-else
        class="crumbs__text"
        v-text="crumb.title"
      />
    </li>
  </ul>
</template>

<script>
import '@/styles/components/_crumbs.scss'

export default {
  props: {
    crumbsData: {
      type: Array,
      default: () => []
    }
  }
}
</script>
