import Vue from 'vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createPinia, PiniaVuePlugin } from 'pinia'
import { PiniaSharedState } from 'pinia-shared-state'
import { useNotFound } from '@/stores/notFound.js'
import { useCrumbs } from '@/stores/crumbs'
import { aboutStore } from '@/stores/about'
import { appStore } from '@/stores/app'
import { brandStore } from '@/stores/brand'
import { cartStore } from '@/stores/cart'
import { catalogStore } from '@/stores/catalog'
import { catalogsStore } from '@/stores/catalogs'
import { checkoutFlowStore } from '@/stores/checkoutFlow'
import { companyStore } from '@/stores/company'
import { contentStore } from '@/stores/content'
import { customerStore } from '@/stores/customer'
import { featuredProductsStore } from '@/stores/featuredProducts'
import { homeStore } from '@/stores/home'
import { locationStore } from '@/stores/location'
import { maintenanceStore } from '@/stores/maintenance'
import { ordersStore } from '@/stores/orders'
import { productStore } from '@/stores/product'
import { searchStore } from '@/stores/search'
import { serviceStore } from '@/stores/service'
import { shoppingListsStore } from '@/stores/shoppingLists'
import { userStore } from '@/stores/user'

Vue.use(PiniaVuePlugin)
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(
  PiniaSharedState({
    enable: false,
    initialize: false,
    type: 'localstorage'
  })
)

export {
  useNotFound,
  useCrumbs,
  aboutStore,
  appStore,
  brandStore,
  cartStore,
  catalogStore,
  catalogsStore,
  checkoutFlowStore,
  companyStore,
  contentStore,
  customerStore,
  featuredProductsStore,
  homeStore,
  locationStore,
  maintenanceStore,
  ordersStore,
  productStore,
  searchStore,
  serviceStore,
  shoppingListsStore,
  userStore
}

export default pinia
