import ContentfulService from '@/services/contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { getPageMeta, rendererOptions } from '@/helpers'
import { defineStore } from 'pinia'

const state = {
  pageData: null
}

const mutations = {
  SET_ABOUT_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setAboutPageData (key) {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageAbout',
      key
    ))?.fields

    const pageData = entry
      ? {
        meta: getPageMeta(entry),
        content: {
          isContentImageLeft: entry.isContentImageLeft,
          contentImage: ContentfulService.extractImage(entry.contentImage),
          contentHeading: entry.contentHeading,
          contentParagraph: entry.contentParagraph,
          contentCtaButton: {
            title: entry.contentCtaButton?.fields.title,
            url: entry.contentCtaButton?.fields.url
          },
          contentParagraphRich: documentToHtmlString(entry.contentParagraphRich, rendererOptions)
        },
        isParagraphRichBelowContentBlock: entry.isParagraphRichBelowContentBlock,
        subtitle: entry.subtitle,
        title: entry.title,
        banner: ContentfulService.extractBanner(entry.banner),
        paragraphRich: documentToHtmlString(entry.paragraphRich, rendererOptions)
      }
      : null

    this.SET_ABOUT_PAGE_DATA(pageData)
  }
}

export const aboutStore = defineStore('about', {
  state: () => ({
    ...state
  }),

  actions
})
