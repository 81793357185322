import settings from '@/settings'
import algoliasearch from 'algoliasearch/lite'
import aa from 'search-insights'

const _client = algoliasearch(
  settings.services.algolia.appId,
  settings.services.algolia.accessToken
)

const _getProductIndex = (sortBy) => {
  switch (sortBy) {
    case 'price':
      return settings.services.algolia.productIndexPrice
    case 'price_desc':
      return settings.services.algolia.productIndexPriceDesc
    case 'new-arrival':
      return settings.services.algolia.productIndexNewArrival
    case 'best-seller':
      return settings.services.algolia.productIndexBestSeller
    case 'relevance':
    default:
      return settings.services.algolia.productIndexRelevance
  }
}

if (settings.services.algolia.analyticsEnabled) {
  aa('init', {
    appId: settings.services.algolia.appId,
    apiKey: settings.services.algolia.accessToken,
    useCookie: true
  })
}

export default {
  getProductIndex (sortBy) {
    const index = _getProductIndex(sortBy)
    return _client.initIndex(index)
  },
  getContentPagesSuggestionsIndex () {
    return _client.initIndex(settings.services.algolia.contentPagesSuggestionsIndex)
  },
  getClient () {
    return _client
  },
  getProductIndexName (sortBy) {
    return _getProductIndex(sortBy)
  },
  sendConversionEvent (params) {
    if (settings.services.algolia.analyticsEnabled) {
      return aa('convertedObjectIDsAfterSearch', {
        eventName: 'Product added to cart',
        ...params
      })
    }
  },
  sendClickEvent (params) {
    if (settings.services.algolia.analyticsEnabled) {
      return aa('clickedObjectIDsAfterSearch', {
        eventName: 'Click on product',
        ...params
      })
    }
  }
}
