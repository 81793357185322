import ContentfulService from '@/services/contentful'
import zApiService from '@/services/z-api'
import { getPageMeta } from '@/helpers'
import { toFeaturesCollectionGeoJSON } from '@/transformers'
import { defineStore } from 'pinia'

const state = {
  pageData: null,
  stores: [],
  coordinates: null,
  userDeniedGeolocation: false,
  loadPromise: Promise.resolve()
}

const mutations = {
  SET_PAGE_DATA (data) {
    this.pageData = data
  },
  SET_STORES (data) {
    this.stores = data
  },
  SET_COORDIANTES (data) {
    this.coordinates = data
  }
}

const actions = {
  ...mutations,
  async setPageData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageLocation',
      'location'
    ))?.fields

    const pageData = {
      title: entry.title,
      meta: getPageMeta(entry),
      browseStoresSection: {
        title: 'Browse Stores by State',
        states: entry.browseStoresSection
      },
      comingSoonSection: {
        title: 'Coming Soon',
        states: entry.comingSoonSection
      }
    }

    // Set default meta-description if it is empty
    if (!entry?.meta?.fields?.description) {
      pageData.meta.description = 'Discover Your Nearest Store with Our Convenient Store Finder Looking for our nearest retail location? Use our store finder tool to locate the closest store near you. Find our high-quality products and exceptional deals just around the corner. Visit us today!'
    }
    this.SET_PAGE_DATA(pageData)
  },
  async loadStores (params) {
    const response = await zApiService.stores.getStores(params)
    response.stores = toFeaturesCollectionGeoJSON(response.stores)
    this.SET_STORES(response.stores)
    return response
  }
}

export const locationStore = defineStore('location', {
  state: () => ({
    ...state
  }),
  actions
})
