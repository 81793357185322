import Transport from '@/services/z-api/transport'
import Auth from '@/services/z-api/v1/auth'
import Products from '@/services/z-api/v1/products'
import Cart from '@/services/z-api/v1/cart'
import User from '@/services/z-api/v1/user'
import Optimizely from '@/services/z-api/v1/optimizely'
import Orders from '@/services/z-api/v1/orders'
import AddressBookService from '@/services/z-api/v1/addressBookService'
import Stores from '@/services/z-api/v1/stores'
import ShoppingListService from '@/services/z-api/v1/shopping-lists'

class Api {
  /**
   * Initialize a new instance of the API class.
   * @param settings
   * @return {*}
   */
  constructor (settings = null) {
    if (Api.instance) {
      return Api.instance
    }
    Api.instance = this
    this.initApiModules(settings)
  }

  /**
   * Initialize API modules.
   * @param settings
   */
  initApiModules (settings) {
    this.transport = new Transport(settings.services.api.baseUrl)
    this.auth = new Auth(this.transport, settings.services.api.apiKey)
    this.products = new Products(this.transport)
    this.cart = new Cart(this.transport)
    this.user = new User(this.transport)
    this.optimizely = new Optimizely(this.transport)
    this.orders = new Orders(this.transport)
    this.addressBookService = new AddressBookService(this.transport)
    this.stores = new Stores(this.transport)
    this.shoppingLists = new ShoppingListService(this.transport)
  }
}

export default Api
