import ApiBase from '@/services/z-api/v1/api-base'

class Orders extends ApiBase {
  constructor (transport) {
    super(transport)
    this.getOrders = this.getOrders.bind(this)
    this.getOrderById = this.getOrderById.bind(this)
    this.getGuestOrderById = this.getGuestOrderById.bind(this)
  }
  /**
   * Get user's orders
   * @param {string} userId
   * @param {Object} options
   * @param {string} options.pageSize
   * @param {string} options.sort
   */

  getOrders (userId, options) {
    return this._withDecoratedPromise(() => {
      const query = options?.pageSize || options?.sort ? `?${new URLSearchParams(options).toString()}` : ''
      return this.transport.get(`/rest/api/v2/users/${this._getUserId(userId)}/orders${query}`)
    })
  }

  /**
   * Get user's orders
   * @param {string} userId
   * @param {string} orderNumber
   */

  getOrderById (userId, orderNumber) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId(userId)}/orders/${orderNumber}`)
    })
  }

  /**
   *
   * @param {string} orderGUID
   */

  getGuestOrderById (orderGUID) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/guest/order/${orderGUID}`)
    })
  }
}

export default Orders
