<template>
  <div class="mega-menu-tab mega-menu-tab--specials">
    <div class="container container--page">
      <div class="mega-menu-inner">
        <div class="mega-menu-promo">
          <div
            v-for="(promo, index) in data.promoItems"
            :key="'mega-menu-promo' + promo.key + index"
            class="mega-menu-item"
          >
            <router-link
              v-if="promo.link"
              class="mega-menu-item__logo-link"
              :to="promo.link.url"
            >
              <img
                v-if="promo.image"
                :src="promo.image.url"
                :alt="promo.image.alt"
                loading="lazy"
              >
            </router-link>
            <router-link
              v-if="promo.link"
              class="mega-menu-item__title"
              :to="promo.link.url"
            >
              {{ promo.link.title }}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="7.983"
                height="13.322"
                viewBox="0 0 7.983 13.322"
                role="presentation"
              >
                <path
                  id="Shape_1"
                  data-name="Shape 1"
                  d="M1313,809l6,6-6,6"
                  transform="translate(-1312.339 -808.339)"
                  fill="none"
                  stroke="#646464"
                  stroke-width="1.87"
                />
              </svg>
            </router-link>
          </div>
        </div>
        <ul class="mega-menu-item__list">
          <li
            v-for="(link, index_k) in data.ctaLinks"
            :key="'mega-menu-tab-item-link' + link.key + index_k"
          >
            <router-link :to="link.url">
              {{ link.title }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: [Array, Object],
      default: () => []
    }
  }
}
</script>
