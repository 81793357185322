import ApiBase from '@/services/z-api/v1/api-base'

class Stores extends ApiBase {
  constructor (transport) {
    super(transport)
    this.getStores = this.getStores.bind(this)
    this.defaultParams = { radius: 160934 * 3, fields: 'FULL', pageSize: 10 }
  }

  /**
   * @param {Object} params?
   * @param {number} params.lat? find stores near coordinates
   * @param {number} params.lng? find stores near coordinates
   * @param {string} params.query? find stores near ZIP Code, City, or State
   * @param {number} params.radius? search radius (300 miles in meters = 160934 * 3)
   * @param {string} params.sort? asc/desc
   * @param {number} params.pageSize?
   * @param {number} params.currentPage?
   * @param {number} params.accuracy?
   * @param {string} params.fields? DEFAULT/FULL/BASIC
   */
  getStores (params) {
    const enrichedParams = {
      ...this.defaultParams
    }
    if (params?.lng) enrichedParams.longitude = params.lng
    if (params?.lat) enrichedParams.latitude = params.lat
    if (params?.query) enrichedParams.query = params.query
    if (params?.radius) enrichedParams.radius = params.radius
    if (params?.sort) enrichedParams.sort = params.sort
    if (params?.pageSize) enrichedParams.pageSize = params.pageSize
    if (params?.currentPage) enrichedParams.currentPage = params.currentPage
    if (params?.accuracy) enrichedParams.accuracy = params.accuracy
    if (params?.fields) enrichedParams.fields = params.fields
    return this._withDecoratedPromise(() => {
      let query = ''
      for (const key in enrichedParams) {
        query += `${query.length ? '&' : '?'}${key}=${encodeURIComponent(enrichedParams[key])}`
      }
      return this.transport.get(`/resource/api/v2/stores${query}`, undefined, {
        'Is-valid-request': 'true'
      })
    })
  }
}

export default Stores
