import ContentfulService from '@/services/contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { defineStore } from 'pinia'
import { getPageMeta } from '@/helpers'

const state = {
  pageData: null
}

const mutations = {
  SET_SERVICE_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setServicePageData (key) {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageService',
      key
    ))?.fields

    const pageData = entry
      ? {
        meta: getPageMeta(entry),
        content: [...ContentfulService.extractContent(entry)],
        isParagraphRichBelowContentBlock: entry.isParagraphRichBelowContentBlock,
        subtitle: entry.subtitle,
        title: entry.title,
        paragraphRich: documentToHtmlString(entry.paragraphRich)
      }
      : null

    this.SET_SERVICE_PAGE_DATA(pageData)
  }
}

export const serviceStore = defineStore('service', {
  state: () => ({
    ...state
  }),
  actions
})
