import ApiBase from '@/services/z-api/v1/api-base'

class ShoppingListService extends ApiBase {
  /**
   * Load user's selected shopping list products
   * @param {string} shoppingListName
   * @returns {Promise<{entries: Object[]}>} returns user's selected shopping list products
   */
  loadProducts (shoppingListName) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}`)
    })
  }

  /**
   * Delete user's selected shopping list
   * @param {string} shoppingListName
   */
  deleteList (shoppingListName) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}`)
    })
  }

  /**
   * Load user's shopping lists
   * @returns {Promise<{wishlists?: Object[]}>} returns a list of user's shopping lists
   */
  loadLists () {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId()}/wishlists?fields=DEFAULT`)
    })
  }

  /**
   * Load product codes from all shopping lists
   * @returns {Promise<{wishlists?: String[]}>} returns a list of all product codes
   */
  loadAllProductCodes () {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId()}/wishlists/products/codes`)
    })
  }

  /**
   * Set default shopping list
   * @param {string} shoppingListName
   * @param {Object} updates containing properties to update { newName?: '', default?: true}
   * @returns {Promise<Object>} returns updated list
   */
  updateList (shoppingListName, updates) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(
        `/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}`,
        updates
      )
    })
  }

  /**
   * Add product to shopping list
   * @param {string} shoppingListName
   * @param {string} productId
   * @returns {Promise<Object>} returns updated list
   */
  addProductToList (shoppingListName, productId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}/products`, productId)
    })
  }

  /**
   * Create a shopping list
   * @param {string} shoppingListName
   * @returns {Promise<Object[]>} returns list
   */
  createList (shoppingListName) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}`,
        {
          default: false
        })
    })
  }

  /**
   * Move products to another list
   * @param {Object} payload
   * @param {String[]} payload.products product codes
   * @param {string} payload.source source list
   * @param {string} payload.target target list
   * @returns {Promise<Object[]>} returns updated list
   */
  moveToList (payload) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
          `/rest/api/v2/users/${this._getUserId()}/wishlists/products/replace`, payload)
    })
  }

  /**
   * Delete products from shopping list
   * @param {string} shoppingListName
   * @param {String[]} productCodes an array of products ids to delete
   * @returns {Promise<Object[]>} returns list
   * @returns {Promise<Object>} returns updated list (with entries property updated)
   */
  deleteProductsFromList (shoppingListName, productCodes) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(
        `/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}/products`,
        productCodes
      )
    })
  }

  /**
   * Share a shopping list
   * @param {string} shoppingListName
   * @param {Object} payload
   * @param {string} payload.name senders name
   * @param {String[]} payload.emails an array of emails
   * @param {string} payload.message
   * @returns {Promise<Object>} returns updated list
   */
  shareShoppingList (shoppingListName, payload) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(
        `/rest/api/v2/users/${this._getUserId()}/wishlists/${shoppingListName}/share`, payload)
    })
  }
}

export default ShoppingListService
