import AlgoliaService from '@/services/algolia'
import { transformSearchFlyoutData, transformProducts } from '@/transformers'
import searchHelper from '@/helpers/search'
import router from '@/router'
import { alignPrices } from '@/helpers/vuexHelpers'
import { defineStore } from 'pinia'

import { userStore } from '@/stores/user'
import ContentfulService from '@/services/contentful'
import { catalogsStore } from '@/stores/catalogs'
import { catalogStore } from '@/stores/catalog'
import { appStore } from '@/stores/app'
import settings from '@/settings'

const DEFAULT_PARENT_CATEGORY_FILTER = '"parentCategory":"1"'

const state = {
  flyoutData: null,
  productsData: [],
  initFacetsData: null,
  facetsMetaData: null,
  queryFacetsData: null,
  resultsMetaData: null,
  defaultHitsPerPage: 20,
  algoliaLimitPerSearch: 999,
  locationModal: {
    opened: false,
    isProductLevel: false,
    currentProductStore: null,
    currentProductStockInStores: null,
    storeChangeCallback: null
  },
  queryID: null,
  indexName: null,
  isFacetsVisible: false
}

function isStorePickupSelected (filters) {
  return !!filters.find((facet) => {
    return facet.includes("'deliveryType':'Store Pickup'")
  })
}

function isStorePickupFiltered () {
  return router.currentRoute.query.facets?.includes("'deliveryType':'Store Pickup'")
}

const mutations = {
  SET_FLYOUT_DATA (data) {
    this.flyoutData = Object.freeze(data)
  },
  SET_PRODUCTS_DATA (data) {
    this.productsData = Object.freeze(data)
  },
  SET_INIT_FACETS_DATA (data) {
    this.initFacetsData = Object.freeze(data)
  },
  SET_FACETS_META_DATA (data) {
    this.facetsMetaData = Object.freeze(data)
  },
  SET_QUERY_FACETS_DATA (data) {
    this.queryFacetsData = Object.freeze(data)
  },
  SET_RESULTS_META_DATA (data) {
    this.resultsMetaData = Object.freeze(data)
  },
  SET_LOCATION_MODAL_STATE (newConfig) {
    if (typeof newConfig === 'boolean') {
      this.locationModal = {
        opened: newConfig,
        isProductLevel: false,
        currentProductStore: null,
        currentProductStockInStores: null,
        storeChangeCallback: null
      }
    } else {
      this.locationModal = newConfig
    }
  },
  SET_QUERY_ID (data) {
    this.queryID = data
  },
  SET_INDEX_NAME (data) {
    this.indexName = data
  }
}

const actions = {
  ...mutations,
  hideFacets () {
    this.isFacetsVisible = false
    appStore().SET_OVERFLOW_HIDDEN(false)
  },
  async resetProductsData () {
    await this.SET_RESULTS_META_DATA(null)
    await this.SET_PRODUCTS_DATA([])
  },
  async setFlyoutData (query) {
    const requestsFilters = userStore().parentCategory ? `${DEFAULT_PARENT_CATEGORY_FILTER} OR "parentCategory":"${userStore().parentCategory}"` : DEFAULT_PARENT_CATEGORY_FILTER
    const params = {
      query,
      hitsPerPage: 4,
      filters: requestsFilters,
      facets: '*'
    }
    const { results } = await AlgoliaService.getClient().multipleQueries([
      {
        indexName: AlgoliaService.getProductIndexName(),
        params
      },
      {
        indexName: settings.services.algolia.contentPagesSuggestionsIndex,
        params: {
          query
        }
      }
    ])
    const res = results[0]
    const contentPagesSuggestionsResponse = results[1]
    const transformedData = transformSearchFlyoutData(res, contentPagesSuggestionsResponse)
    this.SET_FLYOUT_DATA(transformedData)
  },
  async setSearchResultsData (
    { filters, query, page, sortBy, hitsPerPage, isCatalog, specialsFilter, pages, ...rest }
  ) {
    let requestsFilters, groupedFacetValues

    if (filters && Array.isArray(filters)) {
      // filters from `SearchComponent.selectedFacetValues`
      const groupedFacetsByName = {}
      filters.forEach(facetValuesArray => {
        const facetName = searchHelper.extractFacetName(facetValuesArray[0])
        if (groupedFacetsByName[facetName]) {
          groupedFacetsByName[facetName].push(...facetValuesArray)
        } else {
          groupedFacetsByName[facetName] = [...facetValuesArray]
        }
      })
      groupedFacetValues = Object.values(groupedFacetsByName)
      requestsFilters = searchHelper.getFilterString(groupedFacetValues, this.facetsMetaData)
    } else if (filters && typeof filters === 'string') {
      // filters from $route.query.facets
      requestsFilters = filters
    }

    // adding store name to the request facets if "Store Pickup" facet is selected
    if (userStore().location?.name && requestsFilters?.includes("('deliveryType':'Store Pickup')")) {
      requestsFilters += ` AND ('stockInStores.storeName':'${userStore().location.name}')`
    }

    // adding specials filter
    if (specialsFilter) {
      if (requestsFilters.length) {
        requestsFilters += ` AND (${specialsFilter})`
      } else {
        requestsFilters = `(${specialsFilter})`
      }
    }

    // adding parent category filter
    if (userStore().parentCategory) {
      if (requestsFilters.length) {
        requestsFilters += ` AND (${DEFAULT_PARENT_CATEGORY_FILTER} OR "parentCategory":"${userStore().parentCategory}")`
      } else {
        requestsFilters = `(${DEFAULT_PARENT_CATEGORY_FILTER} OR "parentCategory":"${userStore().parentCategory}")`
      }
    } else {
      // default filter to not show products without parent category
      if (requestsFilters.length) {
        requestsFilters += ` AND (${DEFAULT_PARENT_CATEGORY_FILTER})`
      } else {
        requestsFilters = `(${DEFAULT_PARENT_CATEGORY_FILTER})`
      }
    }

    const requests = [
      {
        indexName: AlgoliaService.getProductIndexName(sortBy),
        params: {
          query,
          page,
          facets: ['*'],
          hitsPerPage: hitsPerPage * pages < this.algoliaLimitPerSearch
            ? hitsPerPage * pages
            : this.algoliaLimitPerSearch,
          filters: requestsFilters,
          clickAnalytics: true
        }
      }
    ]

    if (!this.initFacetsData || isCatalog) {
      // this request uploads the full facets tree
      requests.push({
        indexName: AlgoliaService.getProductIndexName(sortBy),
        params: {
          query: '',
          facets: ['*'],
          hitsPerPage: 0,
          filters: ''
        }
      })
    }

    const promises = [AlgoliaService.getClient().multipleQueries(requests)]
    if (isCatalog) {
      promises.push(ContentfulService.getEntriesByQuery({
        skip: 0,
        content_type: 'pageCatalog',
        include: 5
      }))
    }

    const [{ results }, contentfulCatalogs] = await Promise.all(promises)
    if (!this.initFacetsData) {
      this.setInitFacetsData(results[1].facets, results[1].userData)
    }
    if (isCatalog && catalogStore().pageData?.key !== isCatalog.toLowerCase()) {
      catalogsStore().setCatalogsData(contentfulCatalogs, results[1].facets)
      catalogStore().UNSET_CATALOG_DATA()
      catalogStore().setCatalogData(isCatalog)
    }
    const { hits, nbHits, nbPages, page: currentPage, queryID, facets } = results[0]

    this.SET_RESULTS_META_DATA(pages > 1
      ? { totalQty: nbHits, totalPages: Math.ceil(nbHits / this.defaultHitsPerPage), page: pages - 1 }
      : { totalQty: nbHits, totalPages: nbPages, page: currentPage })
    this.SET_QUERY_FACETS_DATA(facets)

    let transformedProducts = []
    if (pages > 1) {
      for (let currentPageIndex = page; currentPageIndex < page + pages; currentPageIndex++) {
        for (
          let hitIndex = hitsPerPage * currentPageIndex;
          hitIndex < (hitsPerPage * (currentPageIndex + 1));
          hitIndex++
        ) {
          if (hits[hitIndex]) {
            transformedProducts.push(
              ...transformProducts([hits[hitIndex]], currentPageIndex)
            )
          }
        }
      }
    } else {
      transformedProducts = transformProducts(hits, page)
    }

    transformedProducts = await alignPrices(transformedProducts, true)

    this.SET_PRODUCTS_DATA(page
      ? [...this.productsData, ...transformedProducts]
      : transformedProducts
    )
    if (!rest.isPreSearch && groupedFacetValues) {
      const isStorePickupSelectedAndNoStoreLocation = !isStorePickupFiltered() &&
        isStorePickupSelected(groupedFacetValues) &&
        !userStore().isStoreLocation
      this.SET_LOCATION_MODAL_STATE(isStorePickupSelectedAndNoStoreLocation)
    }
    this.SET_QUERY_ID(queryID)
    this.SET_INDEX_NAME(AlgoliaService.getProductIndexName(sortBy))
  },
  setInitFacetsData (facets, userData) {
    this.SET_INIT_FACETS_DATA(facets)
    this.SET_FACETS_META_DATA(userData[0])
  }
}

export const searchStore = defineStore('search', {
  state: () => ({
    ...state
  }),
  actions
})
