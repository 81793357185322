import { defineStore } from 'pinia'

const _defaultCrumbsData = [
  {
    id: 'home',
    title: 'Home',
    url: '/',
    hasAction: true
  }
]

const state = {
  crumbsData: _defaultCrumbsData
}

const mutations = {
  SET_CRUMBS_DATA (data) {
    this.crumbsData = [..._defaultCrumbsData, ...data]
  }
}

export const useCrumbs = defineStore('crumbs', {
  state: () => ({
    ...state
  }),
  actions: {
    ...mutations
  }
})
