<template>
  <nav
    aria-label="main menu navigation links"
    class="main-menu"
  >
    <ul class="main-menu-inner">
      <mega-menu-item
        v-for="link in links"
        :key="link.id"
        :link="link"
      />
    </ul>
  </nav>
</template>

<script>
import MegaMenuItem from '@/components/menu/MegaMenuItem.vue'

export default {
  name: 'MainMenu',
  components: {
    MegaMenuItem
  },
  props: {
    links: {
      type: Array,
      required: true
    }
  }
}
</script>
