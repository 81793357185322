import {
  enrichCartItemsWithStockErrors,
  getPotentialPromoFromEntry,
  getPotentialPromoFromCartResp,
  transformAddress,
  transformCartEntry,
  transformCartProduct,
  transformDeliveryModeEntry,
  transformSplitOrderEntry,
  transformValidateCartErrors,
  transformProductEntry
} from '@/transformers'
import zApiService from '@/services/z-api'
import Vue from 'vue'
import {
  validateQuantityChange
} from '@/helpers'
import router from '@/router'
import AnalyticsService from '@/services/analytics'
import { handleValidationError } from '@/helpers/handleValidation'
import { OptimizelyService } from '@/services/optimizely'
import { app } from '@/main'
import { defineStore } from 'pinia'

import { userStore, checkoutFlowStore, appStore } from '@/stores'

const cartDefaultState = {
  addProductData: null,
  cartData: null,
  clientSideCartSize: 0,
  clientSidePickupStore: '',
  clientSideCartGUID: '',
  clientSidePotentialPromo: [],
  potentialPromoModalIsVisible: false,
  clientSidePotentialPromoCartRedirect: false,
  isValidCart: true,
  shippingAddress: null,
  deliveryModesData: null,
  cartDeliveryMode: null,
  paymentData: null,
  singleCartNotification: {
    itemId: null,
    isVisible: false
  },
  isProductWarningModalVisible: false,
  isProductWarningModalHomeDelivery: true
}

const state = {
  ...cartDefaultState,
  placedOrderData: null
}

const mutations = {
  SET_ADD_PRODUCT_DATA (data) {
    this.addProductData = data
  },
  SET_CART_DATA (data) {
    this.cartData = data
  },
  SET_IS_VALID_CART (isValid) {
    this.isValidCart = isValid
  },
  SET_SHIPPING_ADDRESS (shippingAddress) {
    this.shippingAddress = shippingAddress
  },
  SET_DELIVERY_MODES_DATA (data) {
    this.deliveryModesData = data
  },
  SET_CART_DELIVERY_MODE (cartDeliveryMode) {
    this.cartDeliveryMode = cartDeliveryMode
  },
  SET_PAYMENT_DATA (data) {
    this.paymentData = data
  },
  SET_PLACED_ORDER_DATA (data) {
    this.placedOrderData = data
  },
  RESET_PLACED_ORDER_DATA () {
    this.placedOrderData = null
  },
  RESET_ADD_PRODUCT_DATA () {
    this.addProductData = null
  },
  RESET_CART () {
    Object.assign(this, cartDefaultState)
  },
  SET_CART_SIZE (updatedCartSize) {
    if (this.cartData) {
      this.cartData.totalItems = updatedCartSize
    } else {
      this.clientSideCartSize = updatedCartSize
    }
  },
  SET_CART_LOCATION (payload) {
    this.clientSidePickupStore = payload
  },
  RESET_CLIENT_SIDE_CART_DATA () {
    // this only reset client side cart data as far as the relevant cart is being received from the BE
    this.clientSideCartSize = cartDefaultState.clientSideCartSize
    this.clientSidePickupStore = cartDefaultState.clientSidePickupStore
    this.clientSidePotentialPromo = cartDefaultState.clientSidePotentialPromo
    this.potentialPromoModalIsVisible = cartDefaultState.potentialPromoModalIsVisible
    this.clientSidePotentialPromoCartRedirect = cartDefaultState.clientSidePotentialPromoCartRedirect
    this.clientSideCartGUID = cartDefaultState.clientSideCartGUID
  },
  SET_PROMO_MODAL (potentialPromoArr) {
    const isAddToCartFlow = potentialPromoArr.length &&
      potentialPromoArr.length === 1 &&
      this.clientSidePotentialPromo.length // something has been already set to the clientSidePotentialPromo

    if (isAddToCartFlow) { // isAddToCartFlow and some potentialPromo s are already in the clientSidePotentialPromo
      const samePromoOnClientSide = this.clientSidePotentialPromo.find((existingPromo) => {
        const isCodeSame = existingPromo.code === potentialPromoArr[0].code
        const isDeliveryPointSame = existingPromo.deliveryPointOfService?.name ===
          potentialPromoArr[0].deliveryPointOfService?.name
        return isCodeSame && isDeliveryPointSame
      })
      if (samePromoOnClientSide) {
        // replace existing on the client side promo with the same promo
        const indexOfExistingPromo = this.clientSidePotentialPromo.indexOf(samePromoOnClientSide)
        const arrayWithRemovedOldPromo = [...this.clientSidePotentialPromo]
        arrayWithRemovedOldPromo.splice(indexOfExistingPromo, 1)
        // increment existing on the client side promo with the same promo code after BE provides the max rule executions from the BE

        // the most recent promo should be with zero index
        // to be displayed on the potential promo modal
        this.clientSidePotentialPromo = [
          // keep the promo with the maximum eligibleYProductQty
          potentialPromoArr[0].eligibleYProductQty
            ? potentialPromoArr[0]
            : samePromoOnClientSide.eligibleYProductQty,
          ...arrayWithRemovedOldPromo
        ]
      } else {
        // the most recent promo should be with zero index
        // to be displayed on the potential promo modal
        this.clientSidePotentialPromo = [potentialPromoArr[0], ...this.clientSidePotentialPromo]
      }
    } else {
      // get current cart flow
      this.clientSidePotentialPromo = potentialPromoArr
    }
  },
  SET_PROMO_MODAL_VISIBILITY (boolean) {
    this.potentialPromoModalIsVisible = boolean
  },
  SET_ELIGIBLE_Y_PRODUCT_QTY (payload) {
    payload.promo.eligibleYProductQty = payload.newQuantity
  },
  CLOSE_PROMO_MODAL () {
    this.potentialPromoModalIsVisible = false
  },
  SET_CART_NOTIFICATION (itemId) {
    this.singleCartNotification = {
      itemId: itemId != null ? itemId : null,
      isVisible: true
    }
  },
  CLOSE_CART_NOTIFICATION () {
    this.singleCartNotification = {
      itemId: null,
      isVisible: false
    }
  }
}

const actions = {
  ...mutations,
  handleCartUpdateError (e, isBOPIS) {
    if (e?.response?.data[0]) {
      const type = e.response.data[0].type
      const message = e.response.data[0].message
      if (type === 'InsufficientStockError' || type === 'CartEntryError') {
        if (type === 'CartEntryError' && e.response.data[0].reason === 'cartLinesExceededLimit') {
          this.isProductWarningModalVisible = true
          this.isProductWarningModalHomeDelivery = !isBOPIS
        } else {
          Vue.toasted.error(message)
        }
      }
    }
  },

  removeClientSidePotentialPromo () {
    this.clientSidePotentialPromo = []
  },
  updateCartSize (updatedCartSize) {
    if (router.currentRoute.path === '/cart') {
      // entire cart should be updated on the Cart page to reflect all changes e.g. amount, etc.
      return this.setCartData({
        recalculate: true
      })
    } else {
      // only the client cart size should be updated on other pages as far as we need to show proper cart quantity in the header
      this.SET_CART_SIZE(updatedCartSize)
      return this.cartData
    }
  },
  async refreshCartWithLoader (recalculate = false) {
    if (!appStore().isInitialized.resolved) {
      // get cart call will be invoked in scope of the setAppData function
      await appStore().isInitialized.promise
    } else {
      try {
        appStore().SET_GLOBAL_LOADER(true)
        await this.setCartData({
          recalculate
        })
      } catch (e) {
        console.error(e)
      } finally {
        appStore().SET_GLOBAL_LOADER(false)
      }
    }
  },
  async setCartData (
    payload = {
      stockErrors: null,
      recalculate: false // taxes and promo discounts calculations should be done on the cart page and before checkout payment
    }
  ) {
    let data
    if (userStore().isRegisteredUser && this.cartData?.isGuestCart) {
      if (userStore().userData?.currentCart?.code && userStore().userData?.currentCart?.guid) {
        // merge carts request should provide the correct 'splitCarts' property DTFO-2488
        data = await mergeCarts({
          oldCartId: this.cartData.guid,
          toMergeCartGuid: userStore().userData.currentCart.guid
        }, payload?.recalculate)
      } else {
        // the registered user doesn't have any previous cart
        data = await mergeCarts({
          oldCartId: this.cartData.guid
        }, payload?.recalculate)
      }
    } else {
      data = await zApiService.cart.getCart(payload.recalculate)
    }

    const cartData = transformCartEntry(data, userStore().isGuest, payload.stockErrors)
    const oldCartId = this.cartData?.id

    this.SET_CART_DATA(cartData)
    if (!payload.stockErrors) {
      this.setCartStockErrors(this.clientSideCartValidationMessages)
      this.SET_IS_VALID_CART(!this.clientSideCartValidationMessages?.length)
    }

    if (oldCartId !== cartData.id) {
      // reset the cart related data
      checkoutFlowStore().resetCheckoutFlow()
      this.SET_CART_DELIVERY_MODE(cartData.deliveryMode?.code || null)
      this.SET_SHIPPING_ADDRESS(
        data?.deliveryAddress?.line1
          ? transformAddress(data?.deliveryAddress)
          : null
      )
    }

    this.RESET_CLIENT_SIDE_CART_DATA()
    const potentialPromoArrFromCart = getPotentialPromoFromCartResp(data)
    this.SET_PROMO_MODAL(potentialPromoArrFromCart)
    return cartData
  },

  setCartStockErrors (stockErrors) {
    if (this.cartData?.isSplitOrder) {
      this.cartData.splitCarts.forEach(el => {
        const stockErrorsList = stockErrors.filter(err => err.isBOPIS === el.isBOPIS)
        enrichCartItemsWithStockErrors(el?.entries, stockErrorsList)
      })
    } else {
      enrichCartItemsWithStockErrors(this.cartData?.entries, stockErrors)
    }
  },

  async resetCart () {
    this.RESET_CART()
    return this.cartData
  },

  async addToCart (product) {
    const requestParams = {
      quantity: product.quantity,
      code: product.id
    }

    let isGoToCartCallBack
    if ('isGoToCartCallBack' in product) {
      isGoToCartCallBack = product.isGoToCartCallBack
      delete product.isGoToCartCallBack
    }

    if (product.pickupStore) requestParams.pickupStore = product.pickupStore
    try {
      const response = await zApiService.cart.addProduct(requestParams)
      let cartSizeDelta

      // handle potential buyXGetY potential promo information from the cart
      const potentialPromoArr = getPotentialPromoFromEntry(
        response?.entry,
        response?.entry?.deliveryPointOfService
      )
      if (potentialPromoArr && router.currentRoute.path !== '/cart') {
        this.SET_PROMO_MODAL([{
          ...potentialPromoArr[0],
          product: transformProductEntry(response.entry.product)
        }])
        this.SET_PROMO_MODAL_VISIBILITY(true)
        this.clientSidePotentialPromoCartRedirect = isGoToCartCallBack
      }

      if (response?.statusCode === 'multiplePickupStores') {
        this.SET_CART_NOTIFICATION(requestParams.code)
      }
      if (response?.statusCode === 'noStock') {
        Vue.toasted.error(response.statusMessage)
      }
      if (response?.statusCode === 'lowStock') {
        Vue.toasted.info(response.statusMessage)
        if (response.quantityAdded) {
          cartSizeDelta = response.quantityAdded
          AnalyticsService.addToCart({
            ...product,
            quantity: response.quantityAdded
          })
          OptimizelyService.productEvent('add_to_cart', product.id)
        }
      }
      if (response?.statusCode === 'success') {
        cartSizeDelta = requestParams.quantity
        Vue.toasted.success('Product successfully added')
        AnalyticsService.addToCart(product)
        OptimizelyService.productEvent('add_to_cart', product.id)
      }
      if (response?.statusCode === 'maxOrderQuantityExceeded') {
        Vue.toasted.info(response.statusMessage)
        if (response.quantityAdded) {
          cartSizeDelta = response.quantityAdded
          AnalyticsService.addToCart({
            ...product,
            quantity: response.quantityAdded
          })
          OptimizelyService.productEvent('add_to_cart', product.id)
        }
      }

      if (product.pickupStore) {
        const noPickupItemsInCart = this.cartData && !this.storePickupProductsQty
        if (!this.cartData || noPickupItemsInCart) {
          this.SET_CART_LOCATION(product.pickupStore)
        }
      }

      if (
        !potentialPromoArr &&
        isGoToCartCallBack
      ) {
        if (router.currentRoute.path === '/cart') {
          window.scrollTo({ top: 0, behavior: 'smooth' })
        } else {
          app.$nextTick(() => {
            router.push('/cart')
          })
        }
      }

      if (!this.cartData && response.guid) {
        this.clientSideCartGUID = response.guid
      }

      await this.updateCartSize(response.totalUnitCount)
      return cartSizeDelta
    } catch (e) {
      this.handleCartUpdateError(e, Boolean(product.pickupStore))
      throw e
    }
  },

  async validateCart () {
    if (this.clientSideCartValidationMessages?.length) {
      this.SET_IS_VALID_CART(false)
      return this.setCartStockErrors({ errors: this.clientSideCartValidationMessages })
    }
    let { valid, errors } = await zApiService.cart.validateCart()
    this.SET_IS_VALID_CART(valid)
    if (this.cartData?.isSplitOrder) {
      errors = transformValidateCartErrors(this.cartData.splitCarts, errors)
    }
    return this.isValidCart ? this.cartData : this.setCartStockErrors(errors)
  },

  async updateCartEntryQty (product, isChangeDelivery = false) {
    try {
      validateQuantityChange(product)
    } catch {
      return
    }
    try {
      const data = {
        quantity: product.quantity
      }
      if (product.pickupStore) data.pickupStore = product.pickupStore
      const entryNumber = this.cartData?.isSplitOrder ? product.parentEntryNumber : product.entryNumber
      if (product.quantity === 0) OptimizelyService.productEvent('remove_from_cart', product.id)
      const { cartCode } = await zApiService.cart.updateProductQty(entryNumber, data)
      if (cartCode !== this.cartData?.id) {
        // cart id may be changed during location(warehouse) change
        const payload = cartCode ? { ...this.cartData, id: cartCode } : { ...this.cartData }
        this.SET_CART_DATA(payload)
      }
      sendCartItemDeltasAnalytics(this.cartData.entries, product)
      return this.setCartData({
        recalculate: true
      })
    } catch (e) {
      // reverse delivery if the switch delivery request failed
      const previousDeliveryMode = isChangeDelivery
        ? !product.pickupStore
        : product.pickupStore
      this.handleCartUpdateError(e, Boolean(previousDeliveryMode))
      if (isChangeDelivery) {
        setProductLocationBack(product, this.cartData)
      }
    }
  },

  async deleteCartEntry (product) {
    const entryNumber = this.cartData?.isSplitOrder ? product.parentEntryNumber : product.entryNumber
    await zApiService.cart.deleteProduct(entryNumber)
    AnalyticsService.removeFromCart([product])
    OptimizelyService.productEvent('remove_from_cart', product.id)
    return this.setCartData({
      recalculate: true
    })
  },

  async deleteCart () {
    await zApiService.cart.deleteCart()
    this.cartData?.entries
      ?.forEach(product => OptimizelyService.productEvent('remove_from_cart', product.id))
    AnalyticsService.removeFromCart(this.cartData?.entries)
    this.RESET_CART()
  },

  async deleteDeliveryMethodCartItems (deliveryMethod) {
    const isBOPIS = deliveryMethod === 'isBOPISCart'
    const filteredProducts = this.cartData.entries.filter((entry) => {
      return entry.isBOPIS === isBOPIS
    })
    await zApiService.cart.deleteCart(deliveryMethod)
    AnalyticsService.removeFromCart(filteredProducts)
    return this.setCartData({
      recalculate: true
    })
  },

  async setGuestUser (requestData) {
    const { uid } = await zApiService.cart.setGuestUser(requestData)
    // uid is a combination of guest user email and cart guid
    // uid should be used to get the guest user cart
    const guestUserData = {
      uid,
      ...requestData,
      isGuest: true
    }
    userStore().setGuestUserData(guestUserData)
    userStore().SET_EMPLOYEE_CONFIRMATION_PENDING(null)
    OptimizelyService.handleGuestSignUp(guestUserData, requestData)
    return this.cartData
  },

  async setDeliveryModes (modes) {
    if (!modes) {
      modes = await zApiService.cart.getDeliveryModes()
    }
    const transformedSortedModes = modes.sort((a, b) => a.deliveryCost?.value - b.deliveryCost?.value).map(el => transformDeliveryModeEntry(el))
    this.SET_DELIVERY_MODES_DATA(transformedSortedModes)
    this.SET_CART_DELIVERY_MODE(transformedSortedModes[0].code)
    if (this.cartData?.isBOPIS) {
      // we need this get current cart request to correctly place the BOPIS orders
      await this.setCartData({
        recalculate: true
      })
    }
  },

  async setCartDeliveryMode (code) {
    try {
      await zApiService.cart.setDeliveryMode({ deliveryModeId: code })
      this.SET_CART_DELIVERY_MODE(code)
      return this.setCartData({
        recalculate: true
      })
    } catch (e) {
      await handleValidationError(e)
    }
  },

  async createNewShippingAddress (requestData) {
    const { deliveryModes } = await zApiService.cart.setShippingAddress(requestData)
    await this.setDeliveryModes(deliveryModes)
  },

  async setShippingAddressFromAddressBook (addressId) {
    const { deliveryModes } = await zApiService.cart.setShippingAddressFromAddressBook(addressId)
    await this.setDeliveryModes(deliveryModes)
  },

  async placeOrder (requestData) {
    const { orders } = await zApiService.cart.placeOrder(requestData)
    this.SET_PLACED_ORDER_DATA(transformSplitOrderEntry(orders))
    orders.forEach((subOrder) => {
      AnalyticsService.purchase({
        id: subOrder.id,
        revenue: subOrder.totalPriceWithTax?.value,
        tax: subOrder.totalTax?.value,
        shipping: subOrder.deliveryCost?.value
      }, subOrder.entries.map(el => transformCartProduct(el)))
    })

    router.push('/checkout/confirmation')
    if (userStore().isRegisteredGuest) {
      userStore().SET_GUEST_DATA()
      await userStore().logout()
    } else {
      await this.resetCart()
    }
  },

  async applyVoucher (voucherId) {
    await zApiService.cart.applyVoucher(voucherId?.toUpperCase())
    await this.setCartData({
      recalculate: true
    })
  },

  async deleteVoucher (voucherId) {
    await zApiService.cart.deleteVoucher(voucherId?.toUpperCase())
    await this.setCartData({
      recalculate: true
    })
  },

  openAddProductModal (data) {
    this.SET_ADD_PRODUCT_DATA(data)
  },

  closeAddProductModal () {
    this.RESET_ADD_PRODUCT_DATA()
  }
}

const getters = {
  cartId: state => state.cartData?.id,
  cartGuid: state => state.cartData?.guid || state.clientSideCartGUID,
  cartSize: state => (state.cartData ? state.cartData.totalItems : state.clientSideCartSize) || null,
  storePickupProducts: state => {
    if (state.cartData?.isSplitOrder) {
      return state.cartData?.splitCarts?.find(cart => cart.isBOPIS)?.entries
    } else {
      return state.cartData?.entries?.filter(product => product.isBOPIS)
    }
  },
  shippedProducts: state => {
    if (state.cartData?.isSplitOrder) {
      return state.cartData?.splitCarts.find(cart => !cart.isBOPIS)?.entries
    } else {
      return state.cartData?.entries.filter(product => !product.isBOPIS)
    }
  },
  storePickupProductsQty: (state) => {
    return state.storePickupProducts?.reduce(
      (previousValue, product) => previousValue + product.quantity,
      0
    )
  },
  shippedProductsQty: (state) => {
    return state.shippedProducts?.reduce(
      (previousValue, product) => previousValue + product.quantity,
      0
    )
  },
  clientSideCartValidationMessages: state => {
    const result = []

    const searchStockErrorOnEntries = (cart) => {
      cart?.entries.forEach((entry) => {
        const entryErrors = []
        let productInStockInTheCart
        let productPickupStore
        // find cart items which are outOfStock
        if (entry.isBOPIS) {
          const productPickupStoreName = entry.deliveryPointOfService.name
          productPickupStore = entry.stockInStores.find((storeObj) => storeObj.storeName === productPickupStoreName)
          productInStockInTheCart = productPickupStore ? productPickupStore.stockLevelStatus !== 'outOfStock' : false
        } else {
          productInStockInTheCart = entry.stock?.stockLevelStatus !== 'outOfStock'
        }

        let productIsInStockForAnotherDeliveryMethod

        if (!productInStockInTheCart) {
          // check whether the product is available for another delivery method
          if (entry.isBOPIS) {
            productIsInStockForAnotherDeliveryMethod = entry.stock.stockLevelStatus === 'inStock'
          } else {
            productIsInStockForAnotherDeliveryMethod = Boolean(entry.stockInStores?.find((storeObj) => storeObj.stockLevelStatus === 'inStock'))
          }
          if (productIsInStockForAnotherDeliveryMethod) {
            entryErrors.push(`${entry.title} is no longer available for your selected location. Please change your location or remove the product from your cart to continue checkout.`)
          } else {
            entryErrors.push(`${entry.title} is no longer available. Please remove the product from your cart to continue checkout.`)
          }
        } else {
          // if product is in stock then check the quantity is proper
          if (entry.isBOPIS) {
            if (entry.quantity > productPickupStore.maxOrderQuantity) {
              entryErrors.push(`The requested quantity for ${entry.title} is no longer available. Please reduce the order quantity before continuing to checkout.`)
            }
          } else {
            if (entry.quantity > entry.maxOrderQuantity) {
              entryErrors.push(`The requested quantity for ${entry.title} is no longer available. Please reduce the order quantity before continuing to checkout.`)
            }
          }
        }
        result.push(...entryErrors.map((message) => ({
          entryNumber: entry.entryNumber,
          isBOPIS: entry.isBOPIS,
          message
        })))
      })
    }

    if (state.cartData?.isSplitOrder) {
      state.cartData.splitCarts.forEach((cart) => {
        searchStockErrorOnEntries(cart)
      })
    } else {
      searchStockErrorOnEntries(state.cartData)
    }
    return result
  },
  deliveryPointOfService: (state) => {
    return state.storePickupProducts?.length
      ? state.storePickupProducts[0].deliveryPointOfService
      : null
  },
  storeData: (state) => {
    return {
      line1: state.deliveryPointOfService?.address?.line1,
      line2: state.deliveryPointOfService?.address?.line2,
      city: state.deliveryPointOfService?.address?.town,
      state: state.deliveryPointOfService?.address?.region?.name,
      zip: state.deliveryPointOfService?.address?.postalCode,
      coords: {
        lat: state.deliveryPointOfService?.geoPoint?.latitude,
        long: state.deliveryPointOfService?.geoPoint?.longitude
      }
    }
  },
  placedOrderDataCount: (state) => {
    if (state.placedOrderData) {
      return (state.placedOrderData[0]?.totalItems ?? 0) + (state.placedOrderData[1]?.totalItems ?? 0)
    }
    return 0
  },
  categoryYPagePotentialPromoLocation: (state) => {
    if (state.categoryYPagePotentialPromo) {
      return state.categoryYPagePotentialPromo.deliveryPointOfService
    }
    return undefined
  },
  buyXGetYModalPromo: (state) => {
    // the most recent promo should be with zero index
    // to be displayed on the potential promo modal
    return state.clientSidePotentialPromo.length
      ? state.clientSidePotentialPromo[0]
      : undefined
  },
  categoryYPagePotentialPromo: (state) => {
    let result
    if (
      app.$route.query?.specials &&
      state.clientSidePotentialPromo.length
    ) {
      result = state.clientSidePotentialPromo.find((promo) => {
        if (promo.eligibleYProductQty) {
          const decodedY = decodeURIComponent(promo?.messageYLink)
          return decodedY.includes(router.currentRoute.query.specials)
        }
        return undefined
      })
    }
    return result
  }
}

export const cartStore = defineStore('cart', {
  persist: {
    paths: [
      'cartData',
      'shippingAddress',
      'deliveryModesData',
      'cartDeliveryMode',
      'clientSideCartSize',
      'clientSidePickupStore',
      'clientSidePotentialPromo',
      'potentialPromoModalIsVisible',
      'clientSidePotentialPromoCartRedirect',
      'clientSideCartGUID'
    ]
  },
  state: () => ({
    ...state
  }),
  getters,
  actions,
  share: {
    enable: true,
    initialize: true
  }
})

function sendCartItemDeltasAnalytics (cartEntries, product) {
  const oldQuantity = cartEntries.find((cartItem) => cartItem.entryNumber === product.entryNumber)?.quantity
  let delta
  if (product.quantity > oldQuantity) {
    delta = product.quantity - oldQuantity
    AnalyticsService.addToCart({
      ...product,
      quantity: delta
    })
  } else if (product.quantity < oldQuantity) {
    delta = oldQuantity - product.quantity
    AnalyticsService.removeFromCart([{
      ...product,
      quantity: delta
    }])
  }
  return delta
}

function setProductLocationBack (product, cartData) {
  // switch the delivery back after unsuccessful attempt
  if (product.pickupStore) {
    product.pickupStore = ''
    product.isBOPIS = false
    if (cartData?.isSplitOrder) {
      let indexOfProduct
      const splitCartWithProduct = cartData.splitCarts.find(({ entries }) => {
        indexOfProduct = entries.indexOf(product)
        return indexOfProduct > -1
      })
      if (indexOfProduct > -1) {
        splitCartWithProduct.entries.splice(indexOfProduct, 1, { ...product })
      }
    } else {
      const indexOfProduct = cartData.entries.indexOf(product)
      if (indexOfProduct > -1) {
        cartData.entries.splice(indexOfProduct, 1, { ...product })
      }
    }
  }
}

const _isCartError = error => error?.response?.status === 400 &&
  error?.response?.data &&
  error?.response?.data[0].type === 'CartError' &&
  error?.response?.data[0].reason === 'cannotRestore'

async function mergeCarts (payload, recalculate = false) {
  let data
  try {
    data = await zApiService.cart.mergeCart(payload)
    if (
      data?.isSplitOrder &&
      (router.currentRoute.path === '/checkout' || router.currentRoute.path === '/cart')
    ) {
      data = await zApiService.cart.getCart(true) // force cart recalculate for registration
    }
    if (data.cartRestorationMessage) {
      Vue.toasted.info(data.cartRestorationMessage, {
        className: 'cart__info-toast',
        duration: 20000,
        action: {
          text: 'X',
          onClick: (e, toastObject) => {
            toastObject.goAway(0)
          }
        }
      })
    }
  } catch (reason) {
    if (_isCartError(reason)) {
      // assume oldCartId doesn't exist anymore
      Vue.toasted.info(reason?.response?.data[0].message || reason?.response?.data[0].type)
      data = await zApiService.cart.getCart(recalculate)
    } else {
      throw reason
    }
  }

  return data
}
