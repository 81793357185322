import ContentfulService from '@/services/contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { getPageMeta } from '@/helpers'
import { defineStore } from 'pinia'

const state = {
  pageData: null
}

const mutations = {
  SET_CUSTOMER_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setCustomerPageData (key) {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageCustomer',
      key
    ))?.fields

    let data = null

    function extractNodeList (nodeList) {
      return documentToHtmlString(nodeList).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
    }

    if (entry) {
      data = {
        key: entry.key || null,
        meta: getPageMeta(entry),
        title: entry.title || null,
        subtitle: entry.subtitle || null,
        sections: entry.sections
          ? entry.sections.map(el => {
            if (el.fields) {
              return {
                key: el.fields?.key || null,
                title: el.fields?.includes.find(field => ContentfulService.extractContentType(field) === 'particleText')?.fields?.textString || null,
                body: extractNodeList(el.fields?.includes.find(field => ContentfulService.extractContentType(field) === 'particleRichText')?.fields?.richText || null)
              }
            }
            return undefined
          }).filter(el => el)
          : null
      }
    }

    this.SET_CUSTOMER_PAGE_DATA(data)
  }
}

export const customerStore = defineStore('customer', {
  state: () => ({
    ...state
  }),
  actions
})
