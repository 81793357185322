<template>
  <form @submit.prevent="onFormSubmit">
    <form-group
      v-model.trim="$v.form.email.$model.value"
      :field="$v.form.email"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.email)"
    />
    <button
      type="submit"
      class="auth__btn btn"
      :class="{'btn-disabled': !$v.form.$model.email.value}"
      :disabled="!$v.form.$model.email.value"
    >
      Reset Password
    </button>
    <button
      class="auth__btn auth__btn--secondary btn btn-text btn-arrow"
      @click="$emit('sign-in')"
    >
      Back to Sign In
    </button>
  </form>
</template>
<script>
import FormGroup from '@/components/FormGroup'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'pinia'
import { userStore } from '@/stores'

export default {
  name: 'ForgotPassword',
  components: { FormGroup },
  mixins: [FormValidationMixin, LoaderMixin],
  data () {
    return {
      isValidationRun: false,
      form: {
        email: {
          id: 'email',
          type: 'email',
          placeholder: 'EMAIL',
          title: 'email',
          value: '',
          autocomplete: 'email',
          errors: {
            required: 'Please provide a valid email address',
            email: 'Please provide a valid email address'
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(userStore, ['sendResetEmail']),
    onFormSubmit () {
      this.withPresendRoutine(() => {
        this.$emit('loading', true)
        return this.sendForm(this.sendResetEmail, encodeURIComponent(this.form.email.value))
          .then(() => {
            this.$emit('reset-password-sent')
            this.$emit('loading', false)
          }).catch(e => {
            if (e.response?.data[0]?.type === 'UnknownIdentifierError') {
              this.$emit('reset-password-sent')
              this.$emit('loading', false)
            }
            this.isValidationRun = true
          })
      })
    }
  },
  validations () {
    return {
      isValidationRun: false,
      form: {
        email: {
          value: {
            required,
            email,
            server: this.server
          }
        }
      }
    }
  }
}
</script>
