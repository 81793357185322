<template>
  <li
    class="main-menu__item"
    tabindex="0"
    @mouseover="eventHandler(true)"
    @mouseleave="eventHandler(false)"
    @keyup.enter="eventHandler(true)"
    @keyup.esc="eventHandler(false)"
  >
    <router-link
      v-if="link && link.url"
      :id="`menu-${_uid}-label`"
      :to="link.url"
      :aria-controls="`menu-${_uid}`"
      :aria-expanded="String(megaMenuIsShowed)"
      aria-haspopup="true"
      class="main-menu__link"
      :class="{'main-menu__link--arrow' : link.megaMenu}"
      tabindex="-1"
    >
      {{ link.title }}
    </router-link>
    <div
      v-show="megaMenuIsShowed"
      :id="`menu-${_uid}`"
      :aria-labelledby="`menu-${_uid}-label`"
      role="menu"
      class="mega-menu"
      @keyup.enter="megaMenuIsShowed = false"
      @click="megaMenuIsShowed = false"
    >
      <mega-menu-default
        v-if="link.megaMenu && link.megaMenu.type == 'particleMegamenuProducts'"
        :data="link.megaMenu.subMenus"
      />
      <mega-menu-default
        v-if="link.megaMenu && link.megaMenu.type == 'particleMegamenuBrands'"
        class="mega-menu-tab--brands"
        :data="link.megaMenu.subMenus"
      />
      <mega-menu-specials
        v-if="link.megaMenu && link.megaMenu.type == 'particleMegamenuPromo'"
        :data="link.megaMenu"
      />
    </div>
  </li>
</template>
<script>
import MegaMenuDefault from './MegaMenuDefault.vue'
import MegaMenuSpecials from './MegaMenuSpecials.vue'

export default {
  name: 'MainMenuItem',
  components: {
    MegaMenuDefault,
    MegaMenuSpecials
  },
  props: {
    link: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      megaMenuIsShowed: false
    }
  },
  methods: {
    eventHandler (state) {
      if (state) {
        if (this.$el.classList.contains('main-menu__item')) {
          const elWidth = this.$el.getBoundingClientRect().width
          if (elWidth) {
            this.$el.style.width = elWidth + 'px'
            this.$el.classList.add('main-menu__item--hovered')
          }
        }
      } else {
        this.$el.style.removeProperty('width')
        this.$el.classList.remove('main-menu__item--hovered')
      }
      this.megaMenuIsShowed = state
    }
  }
}
</script>
