<template>
  <picklist :show-arrow="false">
    <template v-slot:content>
      <div class="picklist__button">
        <img
          src="@/assets/icons/location.svg"
          class="header-link__icon button__icon"
          alt="location"
          loading="lazy"
        >
        <p class="button__text">
          {{ location.zip }}
          {{ location.stateAbbreviation }}
        </p>
        <loader
          v-if="adjustUserLocationToCartAfterLoginLoader"
          class="locations-picklist__picklist-button-loader"
        />
      </div>
    </template>
    <template v-slot:picklist>
      <section class="locations-picklist">
        <h4 class="store__heading">
          {{ location.displayName }}
        </h4>
        <address-group
          class="store__address"
          :data="location"
        />
        <p class="store__hours">
          {{ hoursLabel }}
        </p>
        <section
          aria-label="Store Pickup Location Actions"
          class="store__actions"
        >
          <router-link
            to="/store-finder"
            class="actions__change-location"
          >
            Change Location
          </router-link>
          |
          <button
            type="button"
            class="actions__remove-location"
            @click="handleRemoveLocation"
          >
            Remove Location
          </button>
        </section>
      </section>
    </template>
  </picklist>
</template>

<script>
import Picklist from '@/components/Picklist'
import { hoursLabel } from '@/helpers'
import AddressGroup from '@/components/AddressGroup'
import LoaderMixin from '@/mixins/LoaderMixin'
import Loader from '@/components/Loader'
import { cartStore, userStore } from '@/stores'
import { mapActions, mapGetters, mapState } from 'pinia'

export default {
  name: 'AppHeaderLocationPicklist',
  components: {
    Picklist,
    AddressGroup,
    Loader
  },
  mixins: [LoaderMixin],
  computed: {
    ...mapState(userStore, ['location', 'adjustUserLocationToCartAfterLoginLoader']),
    ...mapGetters(userStore, ['isStoreLocation']),
    ...mapGetters(cartStore, ['storePickupProducts']),
    ...mapState(cartStore, ['clientSidePickupStore']),
    hoursLabel () {
      return this.isStoreLocation ? hoursLabel(this.location.hours) : ''
    }
  },
  methods: {
    ...mapActions(userStore, ['setUserLocation']),
    ...mapActions(cartStore, ['SET_CART_NOTIFICATION']),
    handleRemoveLocation () {
      if (
        this.storePickupProducts?.length ||
        this.clientSidePickupStore
      ) {
        this.SET_CART_NOTIFICATION()
        return
      }
      this.setUserLocation(null)
    }
  }
}
</script>
