import zApiService from '@/services/z-api'
import settings from '@/settings'
import router from '@/router'
import { FullstoryService } from '@/services/fullstory'
import { Deferred } from '@/helpers'
import { defineStore } from 'pinia'

import {
  userStore,
  checkoutFlowStore,
  cartStore,
  maintenanceStore,
  contentStore,
  shoppingListsStore
} from '@/stores'

const state = {
  lastSessionInit: getVuexLastSession(),
  overflowHidden: false,
  modal: {
    isVisible: false
  },
  resetPending: null,
  isGlobalLoaderActive: false,
  isInitialized: new Deferred(true)
}

const mutations = {
  SET_GLOBAL_LOADER (boolean) {
    this.isGlobalLoaderActive = boolean
  },
  SET_LAST_SESSION_INIT (date) {
    this.lastSessionInit = date
  },
  SET_OVERFLOW_HIDDEN (value) {
    this.overflowHidden = value
  },
  SET_CONFIRM_EMPLOYEE_EMAIL_VISIBILITY (data) {
    this.modal.isVisible = data
  },
  SET_RESET_PENDING (data) {
    this.resetPending = data
  },
  SET_INITIALIZED (deferred) {
    this.isInitialized = deferred
  }
}

const actions = {
  ...mutations,

  setResetPending () {
    this.SET_RESET_PENDING(this.reset())
  },

  handleServerError (err) {
    router.push({
      name: 'ErrorPage',
      query: { number: err }
    })
  },

  async reset () {
    this.resetApiSession()
    userStore().resetUser()
    checkoutFlowStore().resetCheckoutFlow()
    await zApiService.auth.initSession()
    await cartStore().resetCart()
  },

  setAppData () {
    this.SET_INITIALIZED(new Deferred())
    maintenanceStore().getMaintenanceTime()
    maintenanceStore().startCheckingDowntime()
    if (!maintenanceStore().isMaintenanceTime) {
      const isCartPage = window?.location?.pathname === '/cart' ||
        window?.location?.pathname === '/checkout'

      if (isCartPage) {
        this.checkSession().then(() => {
          cartStore().setCartData({
            recalculate: isCartPage
          }).then(() => {
            return isCartPage ? this.isInitialized.resolve() : undefined
          })
        })
      } else {
        this.checkSession()
      }

      contentStore().setConditionData(null)
      contentStore().setBadgesData(null)
      contentStore().setMaxWarning(null)
      shoppingListsStore().loadAllProductCodes()
      this.startFSSession()
    }
  },

  checkSession () {
    if (userStore().isGuest) {
      if (!this.isSessionActive) {
        return zApiService.auth.initSession()
      } else {
        return Promise.resolve()
      }
    } else {
      return Promise.resolve()
    }
  },
  setLastSessionInit (date) {
    this.SET_LAST_SESSION_INIT(date)
  },
  resetApiSession () {
    this.SET_LAST_SESSION_INIT(null)
  },
  startFSSession () {
    if (!userStore().isGuest) FullstoryService.identify(userStore().userData?.uid, userStore().userData?.name)
  }
}

const getters = {
  isSessionActive: state => state.lastSessionInit && Math.ceil((new Date() - new Date(state.lastSessionInit)) / 1000) <= settings.services.api.sessionTtl
}

export const appStore = defineStore('app', {
  persist: {
    paths: ['lastSessionInit']
  },
  state: () => ({
    ...state
  }),
  getters,
  actions
})

function getVuexLastSession () {
  let lastSessionInit = null

  if (localStorage.vuex) {
    try {
      lastSessionInit = JSON.parse(localStorage.vuex)?.app.lastSessionInit
    } catch (e) {
      //
    } finally {
      localStorage.removeItem('vuex') // remove vuex persisted state
    }
  }

  return lastSessionInit
}
