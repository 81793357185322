export default {
  props: {
    field: {
      required: true,
      type: Object
    },
    value: {
      required: true
    },
    dynamicMask: {
      type: String,
      default: '',
      required: false
    },
    isValidationRun: Boolean
  },
  computed: {
    model () {
      return this.field.$model || this.field
    },
    errors () {
      if (this.field.$model) {
        return Object.keys(this.field.$model.errors).map(el => {
          return (!this.field.value[el] && typeof this.field.value[el] === 'boolean') ? this.field.$model.errors[el] : null
        }).filter(error => error)
      } else {
        return null
      }
    }
  }
}
