import Vue from 'vue'
import VueRouter from 'vue-router'
import { handleChunkLoadingError } from '@/helpers'
import { RiskifiedService } from '@/services/riskified'
import { OptimizelyService } from '@/services/optimizely'
import { cartStore, userStore } from '@/stores'
import { rootAppCreated } from '@/main'
import { getProductData, getStoresData } from '@/helpers/vuexHelpers'
const AccountShoppingList = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'AccountShoppingList' */'@/views/AccountShoppingList'))
const Home = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Home' */'@/views/Home.vue'))
const Search = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Search' */'@/views/Search.vue'))
const Cart = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Cart' */'@/views/Cart.vue'))
const ProductDetails = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'ProductDetails' */'@/views/ProductDetails.vue'))
const Checkout = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Checkout' */'@/views/Checkout.vue'))
const NotFound = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'NotFound' */'@/views/NotFound.vue'))
const Account = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Account' */'@/views/Account.vue'))
const AccountPersonalInformation = () => import(/* webpackChunkName: 'AccountPersonalInformation' */'@/views/AccountPersonalInformation.vue')
const AccountOrders = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'AccountOrders' */'@/views/AccountOrders.vue'))
const AccountOrderDetails = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'AccountOrderDetails' */'@/views/AccountOrderDetails.vue'))
const AccountAddressBook = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'AccountAddressBook' */'@/views/AccountAddressBook.vue'))
const CustomerPage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'CustomerPage' */'@/views/CustomerPage.vue'))
const ServicePage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'ServicePage' */'@/views/ServicePage.vue'))
const CompanyPage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'CompanyPage' */'@/views/CompanyPage.vue'))
const BrandLanding = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'BrandLanding' */'@/views/BrandLanding.vue'))
const AboutPage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'AboutPage' */'@/views/AboutPage.vue'))
const LocationPage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'LocationPage' */'@/views/LocationPage.vue'))
const Confirmation = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Confirmation' */'@/views/Confirmation.vue'))
const ResetPassword = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'ResetPassword' */'@/views/ResetPassword.vue'))
const Login = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'Login' */'@/views/Login.vue'))
const ValidationPage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'ValidationPage' */'@/views/ValidationPage.vue'))
const ErrorPage = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'ErrorPage' */'@/views/ErrorPage.vue'))
const GuestOrderDetails = () => handleChunkLoadingError(() => import(/* webpackChunkName: 'GuestOrderDetails' */'@/views/GuestOrderDetails.vue'))

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: 'Homepage'
    },
    component: Home
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: true,
    meta: {
      title: 'Search',
      scrollPosition: 0
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    meta: {
      title: 'Cart'
    },
    component: Cart
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    component: ProductDetails,
    beforeEnter: async (to, from, next) => {
      await rootAppCreated.promise
      getProductData(to, from)
      next()
    }
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: {
      title: 'Checkout'
    },
    component: Checkout
  },
  {
    path: '/checkout/confirmation',
    name: 'Confirmation',
    meta: {
      title: 'Confirmation'
    },
    component: Confirmation
  },
  {
    path: '/account',
    name: 'Account',
    redirect: 'account/personal-information',
    component: Account,
    children: [
      {
        path: 'personal-information',
        name: 'AccountPersonalInformation',
        component: AccountPersonalInformation,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'orders',
        name: 'AccountOrders',
        component: AccountOrders,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'shopping-lists',
        name: 'AccountShoppingList',
        component: AccountShoppingList,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'address-book',
        name: 'AccountAddressBook',
        component: AccountAddressBook,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/account/orders/:id',
    name: 'AccountOrderDetails',
    component: AccountOrderDetails
  },
  {
    path: '/guest/order/:id',
    name: 'GuestOrder',
    component: GuestOrderDetails
  },
  {
    path: '/brand/:key',
    name: 'BrandLanding',
    component: BrandLanding
  },
  {
    path: '/customer/:key',
    name: 'CustomerPage',
    component: CustomerPage
  },
  {
    path: '/service/:key',
    name: 'ServicePage',
    component: ServicePage
  },
  {
    path: '/company/:key',
    name: 'CompanyPage',
    component: CompanyPage
  },
  {
    path: '/about/:key',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: '/store-finder',
    name: 'LocationPage',
    component: LocationPage,
    beforeEnter: async (to, from, next) => {
      await rootAppCreated.promise
      getStoresData(to, from)
      next()
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/login/validateRegistration',
    name: 'ValidateRegistration',
    component: ValidationPage
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.path === from.path) return null

    if (savedPosition?.y || to.meta?.scrollPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            x: 0,
            y: savedPosition?.y || to.meta?.scrollPosition,
            behavior: 'smooth'
          })
        }, 1000)
      })
    } else {
      return {
        x: 1,
        y: 0,
        behavior: 'smooth'
      }
    }
  }
})

router.afterEach((to, from) => {
  if (to?.fullPath !== from?.fullPath) {
    const body = document.querySelector('body')
    if (body && body.classList) {
      body.className = ''
      if (to.meta.className) body.classList.add(to.meta.className)
    }
    if (cartStore().buyXGetYModalPromo) {
      Vue.nextTick(() => {
        cartStore().CLOSE_PROMO_MODAL()
      })
    }
    OptimizelyService.pageView(to.name)
    RiskifiedService.go(to.path)
  }
})

router.beforeEach(async (to, from, next) => {
  await rootAppCreated.promise
  if (from.meta && 'scrollPosition' in from.meta && window.scrollY && to.name === 'ProductDetails') {
    // TODO: rework search page with "last page"
    from.meta.scrollPosition = window.scrollY
  }
  if (to.meta?.requiresAuth && !userStore().isRegisteredUser) {
    next('/login')
  }
  if (to.name === 'Search' && from.name === 'Search') {
    next()
  } else {
    document.body.setAttribute('tabindex', '-1')
    document.body.focus()
    document.body.removeAttribute('tabindex')
    next()
  }
})

router.onError((error) => console.error(error))

export default router
