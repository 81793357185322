export default {
  app: {
    environment: process.env.VUE_APP_ENV,
    version: process.env.VUE_APP_VERSION,
    title: {
      defaultTitle: process.env.VUE_APP_TITLE,
      separator: ' | '
    },
    url: process.env.VUE_APP_URL
  },
  services: {
    api: {
      apiKey: process.env.VUE_APP_API_KEY,
      baseUrl: process.env.VUE_APP_API_BASEURL,
      sessionTtl: 1209600 // 14 days in seconds
    },
    algolia: {
      appId: process.env.VUE_APP_ALGOLIA_APPID,
      accessToken: process.env.VUE_APP_ALGOLIA_ACCESS_TOKEN,
      contentPagesSuggestionsIndex: process.env.VUE_APP_ALGOLIA_CONTENT_PAGES_SUGGESTIONS_INDEX,
      productIndexRelevance: process.env.VUE_APP_ALGOLIA_PRODUCT_INDEX_RELEVANCE,
      productIndexPrice: process.env.VUE_APP_ALGOLIA_PRODUCT_PRICE_INDEX,
      productIndexPriceDesc: process.env.VUE_APP_ALGOLIA_PRODUCT_PRICE_DESC_INDEX,
      productIndexNewArrival: process.env.VUE_APP_ALGOLIA_PRODUCT_NEW_ARRIVAL,
      productIndexBestSeller: process.env.VUE_APP_ALGOLIA_PRODUCT_BEST_SELLER,
      analyticsEnabled: process.env.VUE_APP_ALGOLIA_ANALYTICS_ENABLED
    },
    contentful: {
      space: {
        id: process.env.VUE_APP_CONTENTFUL_SPACE_ID,
        name: process.env.VUE_APP_CONTENTFUL_SPACE_NAME,
        environment: process.env.VUE_APP_CONTENTFUL_SPACE_ENV
      },
      accessToken: process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN,
      host: process.env.VUE_APP_CONTENTFUL_HOST
    },
    google: {
      mapsApiKey: process.env.VUE_APP_GOOGLE_API_KEY,
      geocodingApiKey: process.env.VUE_APP_GOOGLE_GEOCODING_API_KEY
    },
    oneTrust: {
      url: process.env.VUE_APP_ONETRUST_URL
    },
    optimizely: {
      apiKey: process.env.VUE_APP_OPTIMIZELY_API_KEY,
      enabled: process.env.VUE_APP_OPTIMIZELY_ENABLED
    },
    riskified: {
      enabled: process.env.VUE_APP_RISKIFIED_BEACON
    },
    sentry: {
      dsn: process.env.VUE_APP_SENTRY_DSN,
      enabled: process.env.VUE_APP_SENTRY_ENABLED
    },
    gtmId: process.env.VUE_APP_GTM_ID
  }
}
