import zApiService from '@/services/z-api'
import AlgoliaService from '@/services/algolia'
import ContentfulService from '@/services/contentful'
import { transformProductEntry, transformProducts } from '@/transformers'
import Vue from 'vue'
import { alignPrices } from '@/helpers/vuexHelpers'
import { getSearchQueryForSimilarProducts } from '@/helpers'
import { userStore } from '@/stores/user'
import { defineStore } from 'pinia'

const state = {
  pageData: null
}

const mutations = {
  SET_PRODUCT_DATA (data) {
    this.pageData = data
  },
  SET_SIMILAR_PRODUCTS (similarProducts) {
    if (this.pageData) this.pageData.similarProducts = similarProducts
  },
  UNSET_PRODUCT_DATA () {
    this.pageData = null
  }
}

const actions = {
  ...mutations,
  async getProduct (id) {
    try {
      const [zProduct, productVideos] = await Promise.all([
        zApiService.products.getProduct(id.toLowerCase()),
        ContentfulService.getEntryByTypeAndKey('pageProduct', id)
      ])
      zProduct.videos = productVideos
      const product = zProduct ? transformProductEntry(zProduct) : null

      if (product.parentCategory && product.parentCategory !== userStore().parentCategory) {
        Vue.toasted.error('Sorry, your user account is not authorized to view this product')
        this.SET_PRODUCT_DATA(null)
      } else {
        this.SET_PRODUCT_DATA(product)
      }
    } catch (error) {
      console.error(error)
      this.SET_PRODUCT_DATA(null)
    }
  },

  async getSimilarProductsFromAlgolia () {
    try {
      // find similar products by category
      const productID = this.pageData.id
      const categories = this.pageData?.categories
      const { searchQuery, searchLevel } = getSearchQueryForSimilarProducts(categories)

      if (searchQuery.length) {
        const { hits } = await AlgoliaService.getProductIndex().search('', {
          filters: `"category.lvl${searchLevel}": "${searchQuery.join(' > ')}" AND NOT modelNumber:"${productID}" `,
          hitsPerPage: 2
        })
        if (hits.length) {
          let transformedProducts = transformProducts(hits)
          transformedProducts = await alignPrices(transformedProducts)
          this.SET_SIMILAR_PRODUCTS(transformedProducts)
        } else {
          this.SET_SIMILAR_PRODUCTS([])
        }
      } else {
        this.SET_SIMILAR_PRODUCTS([])
      }
    } catch {
      this.SET_SIMILAR_PRODUCTS([])
    }
  }
}

export const productStore = defineStore('product', {
  state: () => ({
    ...state
  }),
  actions
})
