import { Loader } from '@googlemaps/js-api-loader'
import LocationSVG from '@/assets/icons/location-filled.svg'
import settings from '@/settings'
import { extractFeatureLocation, extractGoogleMapsDataEntity } from '@/transformers'

export default {
  defaultCenter: { // USA
    lat: 52.27,
    lng: -123.40
  },
  map: null,
  autocomplete: null,
  geocoder: null,
  googleMaps: null,
  markers: [],
  async initMap () {
    const loader = new Loader({
      apiKey: settings.services.google.mapsApiKey,
      version: 'weekly',
      libraries: ['places', 'geometry']
    })
    await loader.load()
    // eslint-disable-next-line no-undef
    this.googleMaps = google.maps
  },
  async renderMap (latLngObj) {
    try {
      this.map = new this.googleMaps.Map(document.getElementById('map'), {
        center: latLngObj ?? this.defaultCenter,
        zoom: latLngObj ? 6 : 3
      })
    } catch (e) {
      console.error(e)
    }
  },
  initAutocomplete () {
    const options = {
      types: ['(regions)'],
      componentRestrictions: { country: 'us' }
    }
    const autocompleteInput = document.getElementById('form-input')
    const autocomplete = new this.googleMaps.places.Autocomplete(autocompleteInput, options)
    autocomplete.setFields(
      ['address_components', 'geometry', 'name'])
    this.autocomplete = autocomplete
  },
  initInfoWindowListener (callback) {
    // eslint-disable-next-line no-unused-expressions
    this.map?.data.addListener('click', (event) => {
      const storeName = event.feature.getProperty('name')
      callback(storeName)
    })
  },
  async calculateDistances (data, origin) {
    const stores = []
    const destinations = []

    // Build parallel arrays for the store IDs and destinations
    data.forEach((store) => {
      let storeNum, storeLoc
      if (this.googleMaps && data instanceof this.googleMaps?.Data) {
        storeNum = store.getProperty('storeid')
        storeLoc = store.getGeometry().get()
      } else {
        storeNum = store.properties?.storeid ?? store.storeName
        storeLoc = {
          lng: store.geometry?.coordinates[0] ?? store.storeLongitude,
          lat: store.geometry?.coordinates[1] ?? store.storeLatitude
        }
      }

      stores.push(storeNum)
      destinations.push(storeLoc)
    })

    // Retrieve the distances of each store from the origin
    // The returned list will be in the same order as the destinations list
    const service = new this.googleMaps.DistanceMatrixService()
    const getDistanceMatrix =
      (service, parameters) => new Promise((resolve, reject) => {
        service.getDistanceMatrix(parameters, (response, status) => {
          if (status !== this.googleMaps.DistanceMatrixStatus.OK) {
            reject(status)
          } else {
            const distances = []
            const results = response.rows[0].elements
            for (let j = 0; j < results.length; j++) {
              const element = results[j]
              if (element.status === 'ZERO_RESULTS') continue
              const distanceText = element.distance.text
              const distanceVal = element.distance.value
              const distanceObject = {
                storeid: stores[j],
                distanceText,
                distanceVal
              }
              distances.push(distanceObject)
            }

            resolve(distances)
          }
        })
      })

    const distancesList = await getDistanceMatrix(service, {
      origins: [origin],
      destinations,
      travelMode: 'DRIVING',
      unitSystem: this.googleMaps.UnitSystem.IMPERIAL
    })

    distancesList.sort((first, second) => {
      return first.distanceVal - second.distanceVal
    })

    return distancesList
  },
  getOrderedStoresList (data, stores) {
    const result = []
    if (!stores.length) {
      return result
    }

    stores.forEach((store, idx) => {
      if (data instanceof this.googleMaps.Data) {
        const googleMapsDataEntity = data.getFeatureById(store.storeid)
        googleMapsDataEntity.setProperty('order', idx + 1)
        result.push({
          ...extractGoogleMapsDataEntity(googleMapsDataEntity),
          distance: store.distanceText,
          distanceValue: store.distanceVal
        })
      } else {
        const entity = data.find((obj) => obj.properties.storeid === store.storeid)
        result.push({
          ...extractFeatureLocation(entity),
          distance: store.distanceText,
          distanceValue: store.distanceVal
        })
      }
    }, this)
    return result
  },
  styleMap () {
    // eslint-disable-next-line no-unused-expressions
    this.map?.data.setStyle(function (feature) {
      if (feature.getProperty('order')) {
        return {
          icon: LocationSVG,
          title: feature.getProperty('order'),
          label: {
            color: '#fff',
            fontSize: '14px',
            fontWeight: '600',
            text: feature.getProperty('order')
          }
        }
      } else {
        return {
          icon: LocationSVG
        }
      }
    })
  },
  initSuggestionsHandler (coordinatesChangeHandler) {
    this.autocomplete.addListener('place_changed', async () => {
      const place = this.autocomplete.getPlace()
      if (!place.geometry) {
        return
      }

      await coordinatesChangeHandler()
    })
  },
  initGeocoder () {
    this.geocoder = new this.googleMaps.Geocoder()
  },
  async getLocalityName (latLngObj) {
    return (await this.geocoder.geocode({ location: latLngObj })).results[0]
      .address_components.find((address) => {
        return address.types.indexOf('locality') !== -1
      })?.long_name
  },
  async getLocalityPostalCode (latLngObj) {
    return (await this.geocoder.geocode({ location: latLngObj })).results[0]
      .address_components.find((address) => {
        return address.types.indexOf('postal_code') !== -1
      })?.long_name
  },
  async getStateCoordinates (state) {
    const response = await this.geocoder.geocode({ address: state })
    if (response.results && response.results[0]) {
      return {
        lat: response.results[0].geometry.location.lat(),
        lng: response.results[0].geometry.location.lng()
      }
    }
  },
  async recalculateNearestStores (latLngObj, data = this.map?.data) {
    const rankedStores = await this.calculateDistances(data, latLngObj)
    return this.getOrderedStoresList(data, rankedStores)
  },
  addMarker (latLngObj) {
    this.markers.forEach(marker => marker.setMap(null))
    this.markers = []
    const marker = new this.googleMaps.Marker({ position: latLngObj, map: this.map })
    this.markers.push(marker)
  }
}
