<template>
  <div
    class="checkbox"
    :class="{ disabled: disabled }"
  >
    <label
      class="checkbox__label"
    >
      <input
        :disabled="disabled"
        type="checkbox"
        class="checkbox__input"
        :checked="value"
        @change="$emit('change', $event.target.checked)"
      >
      <span
        class="checkbox__copy"
        role="checkbox"
        :aria-checked="value ? 'true' : 'false'"
        :aria-label="label"
        tabindex="0"
      >
        <slot>{{ label }}</slot>
      </span>
    </label>
  </div>
</template>
<script>
export default {
  name: 'Checkbox',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
