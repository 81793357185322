<template>
  <div
    ref="svgContainer"
    :style="getSizes"
    role="presentation"
    @mouseenter="active = true"
    @mouseleave="active = false"
  >
    <slot />
  </div>
</template>

<script>

export default {
  name: 'BaseIcon',
  props: {
    width: {
      type: Number,
      required: false,
      default: null
    },
    height: {
      type: Number,
      required: false,
      default: null
    },
    iconViewBox: {
      type: Object,
      required: false,
      default: null
    },
    iconColor: {
      type: String,
      required: false,
      default: null
    },
    hoverIconColor: {
      type: String,
      required: false,
      default: null
    },
    hoverable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      tags: ['path', 'polygon', 'rect', 'polyline', 'circle'],
      active: false,
      colorInActive: this.iconColor,
      colorActive: this.hoverIconColor || '#de182c'
    }
  },
  computed: {
    fillColor () {
      if (this.hoverable) {
        return this.active ? this.colorActive : this.colorInActive
      }
      return this.iconColor
    },
    getSizes () {
      const result = {}
      if (this.width) result.width = this.width + 'px'
      if (this.height) result.height = this.height + 'px'
      return result
    }
  },
  watch: {
    active () {
      if (this.hoverable) this.applyColor(this.fillColor)
    }
  },
  mounted () {
    this.colorInActive = this.colorInActive ?? this.getNativeFillColor()
    if (this.iconViewBox) this.applyIconViewBox()
    if (this.iconColor) this.applyColor(this.iconColor)
  },
  methods: {
    applyIconViewBox () {
      if (this.$refs.svgContainer) return
      this.$refs.svgContainer.getElementsByTagName('svg')[0]
        .setAttribute('viewBox', this.iconViewBox)
    },
    applyColor (color) {
      if (!this.$refs.svgContainer) return
      this.tags.forEach((tag) => {
        const elements = this.$refs.svgContainer.getElementsByTagName(tag)
        Array.from(elements).forEach((el) => {
          el.setAttribute('fill', color)
        })
      }, this)
    },
    getNativeFillColor () {
      if (!this.$refs.svgContainer) return
      let svg
      this.tags.forEach((tag) => {
        const elements = this.$refs.svgContainer.getElementsByTagName(tag)
        if (elements.length) {
          svg = elements[0]
        }
      })
      return svg.getAttribute('fill')
    }
  }
}
</script>
