import router from '@/router'
import AnalyticsService from '@/services/analytics'
import { cartStore } from '@/stores'

export default {
  extractFacetName (facetValue) {
    return facetValue.split("'").join('').split(':')[0].split('.lvl')[0]
  },

  /**
   * @param {string} indexTitle facet name etc.
   * @param {Object} meta an object from Algolia response.body[0].userData[0] property
   * @returns {string|void} returns undefined in case meta doesn't contain facet with indexTitle
   */
  getValueGrouping (indexTitle, meta) {
    const metaFacetName = meta[this.extractFacetName(indexTitle)]
    return metaFacetName ? ` ${meta[this.extractFacetName(indexTitle)].valueGrouping} ` : undefined
  },

  getFacetGrouping (indexTitle, meta) {
    return ` ${meta[this.extractFacetName(indexTitle).split('(')[1]].facetGrouping} `
  },

  getFilterString (facets, meta) {
    const filterStringsList = facets.map(el => `(${el.join(this.getValueGrouping(el[0], meta))})`)
    return filterStringsList.map((el, i, arr) => {
      return i === arr.length - 1 ? el : `${el}${this.getFacetGrouping(el, meta)}`
    }).join('')
  },
  getFacetsFromUrl (facets, facetsMetaData) {
    if (facets) {
      // regex to split by AND / OR
      const keyRegex = /\) [a-zA-Z]+ \(/i
      facets = facets.split(keyRegex)
        .map(el => {
          // remove parentheses
          return el.replace(/\(/g, '').replace(/\)/g, '')
        })
        .map(el => {
          const valueGroupingFoundInMeta = this.getValueGrouping(el, facetsMetaData)
          // return undefined in case facet is absent in meta
          return valueGroupingFoundInMeta ? el.split(valueGroupingFoundInMeta) : undefined
        })
        .flat()
        .filter(el => el) // exclude facets which are not found from the meta
      return facets
    } else {
      return []
    }
  },
  alignFacetsForPromo (grouped) {
    // block category and location change for isBuyXGetY page
    // set the pickup location the same as for X item
    const deliveryTypeFacetGroup = grouped
      .find((facet) => facet.facetTitle === 'deliveryType')
    if (deliveryTypeFacetGroup) {
      deliveryTypeFacetGroup.isBlocked = true
      if (cartStore().categoryYPagePotentialPromoLocation) {
        const storePickupFacetValue = deliveryTypeFacetGroup.facetValues
          ?.find((facetValue) => facetValue?.indexTitle === "'deliveryType':'Store Pickup'")
        if (storePickupFacetValue) storePickupFacetValue.isRefined = true
      } else {
        const shipToHomeFacetValue = deliveryTypeFacetGroup.facetValues
          ?.find((facetValue) => facetValue?.indexTitle === "'deliveryType':'Ship To Home'")
        if (shipToHomeFacetValue) shipToHomeFacetValue.isRefined = true
      }
    }

    const categoryFacetGroup = grouped
      .find((facet) => facet.facetTitle === 'category.lvl0')
    if (categoryFacetGroup) categoryFacetGroup.isBlocked = true
  }
}

export function commonSearchSubmit (
  query = '',
  beforeNavigateCallback = () => {},
  afterNavigatrCallback = () => {},
  specialsQuery
) {
  if (
    router.currentRoute.name === 'Search' &&
    query === router.currentRoute.query.query
  ) {
    // no need to do anything
  } else {
    if (query?.length > 0) {
      AnalyticsService.search(query)
    }
    const options = {
      name: 'Search',
      query: { query },
      params: { lastChange: String(new Date().getTime()) }
    }
    if (specialsQuery) {
      // specials: this.specialsFilter
      options.query.specials = specialsQuery
    }
    beforeNavigateCallback()
    router.push(options)
    afterNavigatrCallback()
  }
}
