<template>
  <div>
    <div class="modal-body-inner modal-body-inner__account">
      <div class="modal-body__col">
        <sign-up @sign-up="() => this.$parent.$emit('closeModal')" />
      </div>
      <div class="modal-body__col">
        <div class="modal-body__separator">
          <span>or</span>
        </div>
        <div class="modal-body__text">
          <p class="modal-body__new-user">
            Existing User?
          </p>
        </div>
        <button
          class="btn"
          @click="changeModal"
        >
          Sign In
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SignUp from '@/components/auth/SignUp.vue'
import LoaderMixin from '@/mixins/LoaderMixin'

export default {
  name: 'AccountModalSignUp',
  components: { SignUp },
  mixins: [LoaderMixin],
  methods: {
    changeModal () {
      this.$parent.$emit('changeModal', 'signIn')
    }
  }
}
</script>
