<template>
  <section
    :id="_uid"
    v-intersection-observer="handleIntersection"
    class="async-component-wrapper"
    :style="collapsedStyle"
    tabindex="0"
    :aria-label="`Details for ${component}-${_uid}`"
  >
    <find-location-home
      v-if="isVisible && component === 'find-location-home'"
      v-bind="$attrs"
    />
    <promo-gallery
      v-else-if="isVisible && component === 'promo-gallery'"
      v-bind="$attrs"
    />
    <featured-carousel
      v-else-if="isVisible && component === 'featured-carousel'"
      v-bind="$attrs"
    />
    <app-footer
      v-else-if="isVisible && component === 'app-footer'"
      v-bind="$attrs"
    />
    <suggested-carousel
      v-else-if="isVisible && component === 'suggested-carousel'"
      v-bind="$attrs"
    />
    <loader v-else />
  </section>
</template>

<script>
import { threshold } from '@/directives/intersectionObserver'
import { handleChunkLoadingError } from '@/helpers'

function isElementInViewport (el) {
  const rect = el.getBoundingClientRect()
  const top = rect.top >= 0
  const left = rect.left >= 0
  const bottom = Math.floor(rect.bottom) <= (window.innerHeight || document.documentElement.clientHeight) +
    (el.offsetHeight * threshold)
  const right = Math.floor(rect.right) <= (window.innerWidth || document.documentElement.clientWidth)
  return (top && left && bottom && right)
}

export default {
  name: 'AsyncComponentWrapper',
  components: {
    'find-location-home': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'find-location-home' */'@/components/home/FindLocationHome')
    ),
    'promo-gallery': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'promo-gallery' */'@/components/home/PromosGallery')
    ),
    'featured-carousel': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'featured-carousel' */'@/components/FeaturedCarousel')
    ),
    'app-footer': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'app-footer' */'@/components/AppFooter')
    ),
    'suggested-carousel': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'suggested-carousel' */'@/components/SuggestedCarousel')
    )
  },
  props: {
    component: {
      type: String,
      required: true
    },
    collapsedStyle: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      isVisible: false
    }
  },
  mounted () {
    const visibility = isElementInViewport(document.getElementById(this._uid))
    this.isVisible = visibility
  },
  methods: {
    handleIntersection (entries) {
      if (!this.isVisible && entries[0].isIntersecting) {
        this.isVisible = true
        this.$emit('isVisible')
      }
    }
  }
}
</script>

<style lang="scss">
.async-component-wrapper {
  position: relative;
  z-index: 0;
}
</style>
