import { isBot } from '@/helpers'

export const FullstoryService = {
  identify (email = null, userName) {
    if (window.FS && email && !isBot) {
      window.FS.identify(email, {
        displayName: userName,
        email
      })
    }
  }
}
