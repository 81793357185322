import ContentfulService from '@/services/contentful'
import { getPageMeta } from '@/helpers'
import { defineStore } from 'pinia'

const state = {
  pageData: null
}

const mutations = {
  SET_COMPANY_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setCompanyPageData (key) {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageCompany',
      key
    ))?.fields

    const pageData = entry
      ? {
        meta: getPageMeta(entry),
        subtitle: entry.subtitle,
        title: entry.title,
        sections: entry.sections.map((section) => ContentfulService.extractBanner(section)).filter(el => el)
      }
      : null

    this.SET_COMPANY_PAGE_DATA(pageData)
  }
}

export const companyStore = defineStore('company', {
  state: () => ({
    ...state
  }),
  actions
})
