<template>
  <div
    :class="{ disabled: disabled }"
    class="form-checkbox"
    @keyup.enter="$emit('change', $event.target.checked)"
  >
    <checkbox
      :value="model.value"
      :label="model.label"
      :disabled="disabled"
      @change="handleChange"
    />
    <template v-if="isValidationRun && errors">
      <span
        v-for="(error, idx) in errors"
        :key="idx"
        class="form-group__error"
        v-text="error"
      />
    </template>
  </div>
</template>
<script>
import FormControlMixin from '@/mixins/FormControlMixin'
import Checkbox from '@/components/Checkbox'

export default {
  name: 'FormCheckbox',
  components: { Checkbox },
  mixins: [FormControlMixin],
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleChange (newVal) {
      this.$emit('change', newVal)
    }
  }
}
</script>
