<template>
  <div class="auth">
    <h4
      v-if="isTitle"
      class="auth__title"
    >
      {{ titleCopy }}
    </h4>
    <loader v-if="isLoading" />
    <sign-in
      v-if="isSignIn"
      @authenticated="$emit('authenticated')"
      @reset-password="toggleSignIn"
      @loading="(boolean) => isLoading = boolean"
    />
    <forgot-password
      v-else-if="!isSuccessPasswordReset"
      @sign-in="toggleSignIn"
      @reset-password-sent="isSuccessPasswordReset = !isSuccessPasswordReset"
      @loading="(boolean) => isLoading = boolean"
    />
    <p
      v-else
      class="auth__desc"
    >
      If the email entered is associated with an existing account, password reset instructions will be sent to your email address. Please contact customer support if you require additional assistance.
    </p>
  </div>
</template>
<script>
import SignIn from '@/components/auth/SignIn'
import ForgotPassword from '@/components/auth/ForgotPassword'
import LoaderMixin from '@/mixins/LoaderMixin'

export default {
  name: 'Authentication',
  components: {
    SignIn,
    ForgotPassword
  },
  mixins: [LoaderMixin],
  props: {
    isTitle: {
      type: Boolean,
      default: false
    },
    isForgotPassword: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSignIn: true,
      isSuccessPasswordReset: false
    }
  },
  computed: {
    titleCopy () {
      return this.isSignIn ? 'Already have an account?' : 'Reset password'
    }
  },
  created () {
    if (this.isForgotPassword) {
      this.isSignIn = false
    }
  },
  methods: {
    toggleSignIn () {
      this.isSignIn = !this.isSignIn
    }
  }
}
</script>
