import { transformLogoEntry } from '@/transformers'
import ContentfulService from '@/services/contentful'
import { defineStore } from 'pinia'

const state = {
  footerData: null,
  headerData: null,
  productData: {
    condition: [],
    badges: [],
    maxWarning: null
  }
}

const mutations = {
  SET_FOOTER_DATA (data) {
    this.footerData = data
  },
  SET_HEADER_DATA (data) {
    this.headerData = data
  },
  SET_CONDITION_DATA (data) {
    this.productData.condition = data
  },
  SET_BADGES_DATA (data) {
    this.productData.badges = data
  },
  SET_MAX_WARNING (data) {
    this.productData.maxWarning = data
  }
}

const actions = {
  ...mutations,
  async setFooterData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'blockFooter',
      'footer'
    ))?.fields

    if (!entry) return

    let promoSection
    if (entry.promoSection?.fields) {
      const content = entry.promoSection.fields
      promoSection = {
        title: content.title || null,
        disclaimer: content.items ? content.items.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields.textString : null,
        btnCopy: content.includes ? content.includes.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields.textString : null
      }
    }

    const logo = entry.logo
      ? transformLogoEntry(entry.logo, null)
      : null

    const navLinks = entry.menuHeaderSections
      ? entry.menuHeaderSections.map(el => {
        if (el.fields) {
          return {
            id: el.fields.key || null,
            title: el.fields.title || null,
            items: el.fields.items.map(item => {
              if (item.fields) {
                return {
                  id: item.fields.key,
                  title: item.fields.title || item.fields.textString,
                  url: item.fields.url
                }
              }
              return undefined
            }).filter(el => el)
          }
        }
        return undefined
      }).filter(el => el)
      : null

    let socialLinks
    if (entry.socialLinks?.fields) {
      socialLinks = {
        title: entry.socialLinks.fields.title,
        items: entry.socialLinks.fields.items.map(el => {
          if (el.fields) {
            return {
              id: el.fields.key || null,
              image: ContentfulService.extractImage(el.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleImage')?.fields.image, '') || null,
              url: el.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields.url || null
            }
          }
          return undefined
        }).filter(el => el)
      }
    } else {
      socialLinks = null
    }

    const copyright = entry.copyrightText ? entry.copyrightText.fields?.textString : null
    const creditCards = entry.creditCards
      ? entry.creditCards.map(el => {
        if (el.fields) return ContentfulService.extractImage(el.fields.image, null)
        return undefined
      }).filter(el => el)
      : null

    const footerData = {
      promoSection,
      logo,
      navLinks,
      socialLinks,
      copyright,
      creditCards
    }

    this.SET_FOOTER_DATA(footerData)
  },

  async setHeaderData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'blockHeader',
      'header'
    ))?.fields

    if (!entry) return

    let headerPromo
    if (entry.promo?.fields?.includes) {
      headerPromo = {
        promo: entry.promo.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields.textString || null,
        btnLink: entry.promo.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields || null
      }
    }

    const getMenuItems = entry => {
      if (entry) {
        const getCategoryLink = item => {
          if (item && item.fields) {
            const key = item.fields.key.toLowerCase()
            return {
              key,
              title: item.fields.title,
              url: item.fields.urlSearchLinkTarget
            }
          } else {
            return null
          }
        }

        let type
        let entryIndex = 0
        while (entryIndex < entry.length) {
          type = ContentfulService.extractContentType(entry[entryIndex]) || null
          if (type) {
            break
          } else {
            entryIndex++
          }
        }

        return {
          type,
          ...type === 'particleMegamenuPromo' && {
            promoItems: entry[0].fields.specialsList
              ? entry[0].fields.specialsList.map(el => {
                if (el.fields) {
                  return {
                    key: el.fields.key || null,
                    image: ContentfulService.extractImage(el.fields?.includes && el.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleImage')?.fields.image, '') || null,
                    link: el.fields?.includes ? el.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields : null
                  }
                }
                return undefined
              }).filter(el => el).slice(0, 3)
              : null
          },
          ...entry[0]?.fields?.ctaLinks && {
            ctaLinks: entry[0].fields.ctaLinks
              ? entry[0].fields.ctaLinks.map(el => {
                if (el.fields) {
                  return {
                    key: el.fields.key || null,
                    title: el.fields.title || null,
                    url: el.fields.url || null
                  }
                }
                return undefined
              }).filter(el => el)
              : null
          },
          ...type !== 'particleMegamenuPromo' && {
            subMenus: entry
              ? entry.map(item => {
                if (item.fields) {
                  return {
                    ...item.fields?.categoriesList && {
                      items: item.fields?.categoriesList ? item.fields.categoriesList.map(el => getCategoryLink(el)).filter(el => el) : null
                    },
                    ...item.fields?.key && { key: item.fields.key || null },
                    ...item.fields?.link && {
                      productLink: getCategoryLink(item.fields.link)
                    },
                    ...item.fields?.logo && { logo: ContentfulService.extractImage(item.fields.logo) }
                  }
                }
                return undefined
              }).filter(el => el)
              : null
          }
        }
      } else {
        return null
      }
    }

    const navLinks = entry.headerLinks
      ? entry.headerLinks.map(item => {
        if (item.fields) {
          return {
            id: item.fields?.headerLink?.fields?.key,
            title: item.fields?.headerLink?.fields?.title,
            url: item.fields?.headerLink?.fields?.url,
            megaMenu: getMenuItems(item.fields?.items ? item.fields.items : null)
          }
        }
        return undefined
      }).filter(el => el)
      : null

    const logo = entry.logo
      ? transformLogoEntry(entry.logo, null)
      : null
    const headerData = {
      headerPromo,
      logo,
      navLinks
    }

    this.SET_HEADER_DATA(headerData)
  },

  async setConditionData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'compositeCollection',
      'product-condition-hover-text'
    ))?.fields

    let condition = null

    if (entry) {
      condition = {
        key: entry.key || null,
        title: entry.title || null,
        items: entry.items
          ? entry.items.map(el => {
            if (el.fields) {
              return {
                key: el.fields.key || null,
                text: el.fields.textString || null
              }
            }
            return undefined
          }).filter(el => el)
          : null
      }
    }

    this.SET_CONDITION_DATA(condition)
  },

  async setBadgesData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'compositeCollection',
      'product-badges'
    ))?.fields

    let badges = null

    if (entry) {
      badges = entry.items
        ? entry.items.map(el => {
          if (el.fields) {
            return {
              key: el.fields.key || null,
              title: el.fields.title || null,
              backgroundColor: el.fields.backgroundColor || null,
              color: el.fields.fontColor || null
            }
          }
          return undefined
        }).filter(el => el)
        : null
    }

    this.SET_BADGES_DATA(badges)
  },

  async setMaxWarning () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'compositeItem',
      'max-quantity-warning'
    ))?.fields

    let warning = null

    if (entry) {
      warning = {
        key: entry.key || null,
        title: entry.includes ? entry.includes.find(el => el.fields?.key === 'max-quantity-warning-title')?.fields?.textString : null,
        body: entry.includes ? entry.includes.find(el => el.fields?.key === 'max-quantity-warning-body')?.fields?.text : null,
        hover: entry.includes ? entry.includes.find(el => el.fields?.key === 'max-quantity-warning-hover')?.fields?.textString : null
      }
    }

    this.SET_MAX_WARNING(warning)
  }
}

const getters = {
  getHeaderPromo: state => state.headerData?.headerPromo
}

export const contentStore = defineStore('content', {
  state: () => ({
    ...state
  }),
  getters,
  actions
})
