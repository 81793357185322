import ContentfulService from '@/services/contentful'
import { transformPromos } from '@/transformers'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { defineStore } from 'pinia'

const state = {
  pageData: null
}

const mutations = {
  SET_BRAND_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setBrandPageData (key) {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageBrandLanding',
      key
    ))?.fields

    let data = null

    if (entry) {
      data = {
        meta: entry.meta ? ContentfulService.extractMeta(entry.meta) : null,
        key: entry.key || null,
        brandColor: entry.brandColor || null,
        heroBanner: ContentfulService.extractImageList(entry.heroBanner?.fields) || null,
        videoSection: entry.videoSection?.fields
          ? {
            title: entry.videoSection.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields?.textString,
            paragraph: documentToHtmlString(entry.videoSection.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleRichText')?.fields?.richText),
            video: ContentfulService.extractVideo(entry.videoSection.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleVideo')?.fields),
            imageList: ContentfulService.extractImageList(entry.videoSection.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleImage')?.fields) || null
          }
          : null,
        productSection: entry.productSection?.fields
          ? {
            key: entry.productSection?.fields.key || null,
            title: entry.productSection?.fields.title || null,
            subtitle: entry.productSection?.fields.items.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields?.textString || null,
            paragraph: documentToHtmlString(entry.productSection?.fields.items.find(el => ContentfulService.extractContentType(el) === 'particleRichText')?.fields?.richText) || null,
            button: entry.productSection?.fields.items.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields || null,
            image: ContentfulService.extractImage(entry.productSection?.fields.items.find(el => ContentfulService.extractContentType(el) === 'particleImage')?.fields.image) || null
          }
          : null,
        promos: entry.promo ? transformPromos(entry.promo) : null,
        categories: entry.categories?.fields
          ? {
            title: entry.categories.fields.title || null,
            button: entry.categories.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields || null,
            categoriesList: entry.categories.fields.items
              ? entry.categories.fields.items
                .filter(el => el.fields?.image && el.fields?.link)
                .map(el => ({
                  key: el.fields.key || null,
                  image: ContentfulService.extractImage(el.fields.image) || null,
                  title: el.fields.title || null,
                  url: el.fields.link.fields.url || null
                }))
              : null
          }
          : null,
        bannersList: entry.bannersList?.fields
          ? entry.bannersList.fields.items.map(el => {
            if (el.fields) {
              return {
                key: el.fields?.key || null,
                imageList: ContentfulService.extractImageList(el.fields) || null
              }
            }
            return undefined
          }).filter(el => el)
          : null,
        featuredProducts: entry.brandFeaturedProducts?.fields
          ? {
            title: entry.brandFeaturedProducts.fields?.includes.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields?.textString || null,
            link: entry.brandFeaturedProducts.fields?.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields || null,
            filter: entry.brandFeaturedProducts.fields?.properties?.filter || null
          }
          : null
      }
    }

    this.SET_BRAND_PAGE_DATA(data)
  }
}

export const brandStore = defineStore('brand', {
  state: () => ({
    ...state
  }),
  actions
})
