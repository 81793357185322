<template>
  <div class="mega-menu-tab">
    <div class="container container--page">
      <div class="mega-menu-inner">
        <div
          v-for="(item, index) in data"
          :key="'mega-menu-tab-item' + item.key + index"
          class="mega-menu-item"
        >
          <template v-if="item.productLink">
            <router-link
              v-if="item.logo && item.logo.url"
              class="mega-menu-item__logo"
              :to="item.productLink.url"
            >
              <img
                :src="item.logo.url"
                :alt="item.logo.alt"
                loading="lazy"
              >
            </router-link>
            <router-link
              :to="item.productLink.url"
              class="mega-menu-item__title"
            >
              {{ item.productLink.title }}
            </router-link>
          </template>
          <ul class="mega-menu-item__list">
            <li
              v-for="(link, index_k) in item.items"
              :key="'mega-menu-tab-item-link' + link.key + index_k"
            >
              <router-link :to="link.url">
                {{ link.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  }
}
</script>
