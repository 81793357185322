<template>
  <div
    ref="picklist"
    class="picklist-wrapper"
  >
    <button
      class="picklist-wrapper__button"
      :class="getClass"
      @click="togglePicklist"
    >
      <slot name="content" />
    </button>
    <div
      v-show="!isDisabled && picklistVisible"
      class="picklist-wrapper__picklist"
      @click="picklistVisible = false"
    >
      <slot name="picklist" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Picklist',
  props: {
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      picklistVisible: false
    }
  },
  computed: {
    getClass () {
      if (this.showArrow) {
        return this.picklistVisible ? 'picklist-wrapper__button--open' : 'picklist-wrapper__button--closed'
      }
      return ''
    }
  },
  watch: {
    async picklistVisible (newVal) {
      this.$emit('toggled-picklist-visibility', newVal)
    }
  },
  mounted () {
    document.addEventListener('click', this.onDocumentClick)
    document.addEventListener('keyup', this.onDocumentKeyUp)
  },
  destroyed () {
    document.removeEventListener('click', this.onDocumentClick)
    document.removeEventListener('keyup', this.onDocumentKeyUp)
  },
  methods: {
    onDocumentClick (event) {
      if (this.isDisabled || !this.picklistVisible || !this.$refs.picklist || this.$refs.picklist === event.target || this.$refs.picklist.contains(event.target)) {
        return
      }

      this.picklistVisible = false
      this.$emit('click-outside')
    },
    onDocumentKeyUp (event) {
      if (this.picklistVisible && event.key === 'Escape') {
        this.picklistVisible = false
      }
    },
    togglePicklist () {
      // toggling should happen after onClick event routine finished
      setTimeout(function () {
        if (!this.isDisabled) this.picklistVisible = !this.picklistVisible
      }.bind(this), 0)
    }
  }
}</script>
