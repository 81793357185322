import ContentfulService from '@/services/contentful'
import { transformPromos, transformHeroBanner } from '@/transformers'
import { defineStore } from 'pinia'

const state = {
  pageData: null
}

const mutations = {
  SET_HOME_PAGE_DATA (data) {
    this.pageData = data
  }
}

const actions = {
  ...mutations,
  async setHomePageData () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'pageHome',
      'homepage'
    ))?.fields

    if (!entry) return

    const meta = entry.meta ? ContentfulService.extractMeta(entry.meta) : null
    let hero
    if (entry.hero?.length) {
      hero = entry.hero ? entry.hero.map(el => transformHeroBanner(el)).filter(el => el) : null
    }

    const categories = getHomepageCategories(entry?.categories)
    const categories2 = getHomepageCategories(entry?.categories2)

    const promos = transformPromos(entry.promotions)
    const bannerA = ContentfulService.extractBanner(entry.bannerA)

    let featuredProducts
    if (entry.featuredProducts?.fields) {
      featuredProducts = {
        title: entry.featuredProducts.fields.title,
        link: ContentfulService.extractEntryFromIncludesByType(entry.featuredProducts, 'particleLink')
      }
    }

    const bannerB = ContentfulService.extractBanner(entry.bannerB)

    let brands
    if (entry.brands?.fields) {
      brands = {
        title: entry.brands.fields.title,
        link: ContentfulService.extractEntryFromIncludesByType(entry.brands, 'particleLink'),
        brandsList: entry.brands.fields.items
          ? entry.brands.fields.items
            .filter(el => el.fields?.image && el.fields?.link)
            .map(el => ({
              key: el.fields.key || null,
              image: ContentfulService.extractImage(el.fields.image) || null,
              title: el.fields.title || null,
              url: el.fields.link.fields.url || null
            }))
          : null
      }
    }

    let location
    if (entry.location?.fields) {
      location = {
        title: entry.location.fields.title,
        subtitle: entry.location.fields?.items.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields.textString,
        imageList: ContentfulService.extractImageList(entry.location.fields?.items.find(el => ContentfulService.extractContentType(el) === 'blockBannerImage')?.fields) || null
      }
    }

    const pageData = {
      meta,
      categories,
      categories2,
      promos,
      bannerA,
      featuredProducts,
      bannerB,
      brands,
      location,
      hero
    }

    this.SET_HOME_PAGE_DATA(pageData)
  }
}

const getHomepageCategories = (categories) => {
  if (categories?.fields) {
    return {
      title: categories.fields.title,
      link: ContentfulService.extractEntryFromIncludesByType(categories, 'particleLink'),
      categoriesList: categories.fields.items
        ? categories.fields.items
          .filter(el => el.fields?.image && el.fields?.link)
          .map(el => ({
            key: el.fields.key || null,
            image: ContentfulService.extractImage(el.fields.image) || null,
            title: el.fields.title || null,
            url: el.fields.link.fields.url || null
          }))
        : null
    }
  }
}

export const homeStore = defineStore('home', {
  state: () => ({
    ...state
  }),
  actions
})
