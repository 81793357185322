import settings from '@/settings'
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import router from '@/router'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'

const ignoreErrors = [
  'Non-Error promise rejection captured with value: Object Not Found Matching Id:',
  'riskified'
]
if (settings.app.environment === 'PRODUCTION') {
  ignoreErrors.push('Network Error')
  ignoreErrors.push('ChunkLoadError')
}

const SentryService = {
  initialized: false,
  init () {
    if (
      settings.services.sentry.enabled === 'true' &&
      settings.services.sentry.dsn &&
      settings.app.environment
    ) {
      Sentry.init({
        Vue,
        release: settings.app.version,
        dsn: settings.services.sentry.dsn,
        normalizeDepth: 6,
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: [settings.app.url, /^\//]
          }),
          new ExtraErrorDataIntegration(
            {
              // Limit of how deep the object serializer should go. Anything deeper than limit will
              // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
              // a primitive value. Defaults to 3.
              // When changing this value, make sure to update `normalizeDepth` of the whole SDK
              // to `depth + 1` in order to get it serialized properly - https://docs.sentry.io/platforms/javascript/configuration/options/#normalize-depth
              depth: 5
            }
          )
        ],
        environment: settings.app.environment,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        // debug: true,
        // attachStacktrace: true,
        tracesSampleRate: 0.1,
        ignoreErrors,
        beforeSend (event, hint) {
          const error = hint?.originalException
          if (
            settings.app.environment === 'PRODUCTION' &&
            (
              error?.message?.includes('Loading CSS chunk') ||
              error?.message?.includes('oading chunk')
            )
          ) {
            return null
          } else {
            return event
          }
        }
      })
      this.initialized = true
    }
  },
  log (infoObj) {
    if (this.initialized) {
      if (infoObj && infoObj instanceof Error) {
        Sentry.captureException(infoObj)
      } else {
        let message = ''
        try {
          message = JSON.stringify(infoObj)
        } catch (e) {
          //
        }
        if (message) Sentry.captureMessage(message)
      }
    }
  }
}

export default SentryService
