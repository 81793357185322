<template>
  <form
    ref="signUpForm"
    class="sign-up"
    @submit.prevent="onFormSubmit"
  >
    <form-group
      v-model.trim="$v.form.firstName.$model.value"
      :field="$v.form.firstName"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.firstName)"
    />
    <form-group
      v-model.trim="$v.form.lastName.$model.value"
      :field="$v.form.lastName"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.lastName)"
    />
    <form-group
      v-model.trim="$v.form.email.$model.value"
      :field="$v.form.email"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.email)"
    />
    <form-group
      v-model.trim="$v.form.phone.$model.value"
      :field="$v.form.phone"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.phone)"
    />
    <form-group
      v-model.trim="$v.form.password.$model.value"
      :field="$v.form.password"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.password)"
    />
    <form-group
      v-model.trim="$v.form.confirmPassword.$model.value"
      :field="$v.form.confirmPassword"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.confirmPassword)"
    />
    <div class="form-group__checkboxes">
      <form-checkbox
        :field="form.subscribe"
        :value="form.subscribe.value"
        @change="form.subscribe.value = !form.subscribe.value"
      >
        I agree to receive promotional emails.
      </form-checkbox>
      <form-checkbox
        :is-validation-run="isValidationRun"
        :field="$v.form.terms"
        :value="$v.form.terms.$model.value"
        @change="$v.form.terms.$model.value = !$v.form.terms.$model.value"
      >
        I agree to the <router-link
          to="/customer/terms-of-use"
          class="form-group__link"
          target="_blank"
          aria-label="Terms of Use (Opens in a new tab)"
        >
          Terms of Use
        </router-link> and <router-link
          to="/customer/privacy-policy"
          class="form-group__link"
          target="_blank"
          aria-label="Privacy Policy (Opens in a new tab)"
        >
          Privacy Policy
        </router-link>
      </form-checkbox>
    </div>

    <button
      class="sign-up__btn btn"
      :class="{ 'btn-disabled': isEmptyFields }"
      :disabled="isEmptyFields"
    >
      Create Account
    </button>
    <loader v-if="isLoading" />
  </form>
</template>
<script>
import FormGroup from '@/components/FormGroup'
import FormCheckbox from '@/components/FormCheckbox'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import { required, email, sameAs, minLength, alpha } from 'vuelidate/lib/validators'
import { mapActions, mapState } from 'pinia'
import { userStore, checkoutFlowStore } from '@/stores'

export default {
  name: 'SignUp',
  components: { FormCheckbox, FormGroup },
  mixins: [FormValidationMixin, LoaderMixin],
  data () {
    return {
      isValidationRun: false,
      form: {
        firstName: {
          id: 'sign-up-first-name',
          type: 'text',
          placeholder: 'FIRST NAME',
          title: 'firstname',
          value: '',
          onlyLetter: true,
          autocomplete: 'given-name',
          errors: {
            required: 'Please provide a name',
            minLength: 'Please provide a name',
            alpha: 'Only letters are allowed'
          }
        },
        lastName: {
          id: 'sign-up-last-name',
          type: 'text',
          placeholder: 'LAST NAME',
          title: 'lastname',
          value: '',
          onlyLetter: true,
          autocomplete: 'family-name',
          errors: {
            required: 'Please provide a name',
            minLength: 'Please provide a name',
            alpha: 'Only letters are allowed'
          }
        },
        email: {
          id: 'sign-up-email',
          type: 'email',
          placeholder: 'EMAIL',
          title: 'email',
          value: '',
          autocomplete: 'email',
          errors: {
            required: 'Please provide a valid email address',
            email: 'Please provide a valid email address'
          }
        },
        phone: {
          id: 'sign-up-phone',
          type: 'tel',
          inputType: 'the-mask',
          mask: '(###)-###-####',
          placeholder: 'PHONE NUMBER',
          title: 'phone',
          value: '',
          autocomplete: 'tel',
          errors: {
            required: 'Please provide a phone number',
            numeric: 'Please provide a phone number',
            minLength: 'Please provide a phone number'
          }
        },
        password: {
          id: 'sign-up-pass',
          type: 'password',
          placeholder: 'PASSWORD',
          title: 'password',
          value: '',
          autocomplete: 'new-password',
          hint: 'Passwords must be between 8 and 15 characters long and contain characters from three of the following four categories: Uppercase characters, Lowercase characters, Numeric: 0-9, Non-alphanumeric: ex. ~!@#$%',
          errors: {
            required: 'Please provide a password',
            password: 'Invalid password. Please try again'
          }
        },
        confirmPassword: {
          id: 'sign-up-confirm-pass',
          type: 'password',
          placeholder: 'CONFIRM PASSWORD',
          title: 'confirm-password',
          value: '',
          autocomplete: 'new-password',
          errors: {
            required: 'Please provide a password',
            sameAs: 'Passwords do not match'
          }
        },
        subscribe: {
          type: 'checkbox',
          label: 'I agree to receive promotional emails',
          value: true
        },
        terms: {
          type: 'checkbox',
          label: 'Terms and Conditions',
          value: true,
          errors: {
            sameAs: 'Please select a checkbox.'
          }
        }
      }
    }
  },
  computed: {
    ...mapState(userStore, ['isEmployeeConfirmationPending']),
    ...mapState(checkoutFlowStore, ['savedUserData']),
    isEmptyFields () {
      // loosing reactivity after validation if filter form
      return !this.$v.form.$model.firstName.value || !this.$v.form.$model.lastName.value || !this.$v.form.$model.email.value || !this.$v.form.$model.phone.value || !this.$v.form.$model.password.value || !this.$v.form.$model.confirmPassword.value || !this.$v.form.$model.terms.value
    }
  },
  mounted () {
    this.populateUserData()
    this.$refs.signUpForm.querySelector('input').focus()
  },
  beforeDestroy () {
    if (this.$route.path === '/checkout') {
      this.SAVE_USER_INPUT(this.form)
    }
  },
  methods: {
    ...mapActions(checkoutFlowStore, ['SET_STEP', 'SAVE_USER_INPUT']),
    ...mapActions(userStore, ['signUp']),
    onFormSubmit () {
      this.withPresendRoutine(() => {
        return this.sendForm(this.signUp, {
          email: encodeURI(this.form.email.value?.toLowerCase()),
          firstName: encodeURIComponent(this.form.firstName.value),
          lastName: encodeURIComponent(this.form.lastName.value),
          phone: encodeURIComponent(this.form.phone.value),
          password: encodeURI(this.form.password.value),
          subscriptionStatus: this.form.subscribe.value,
          uid: encodeURI(this.form.email.value?.toLowerCase())
        })
          .then(() => {
            this.$emit('sign-up')
            this.SET_STEP(this.isEmployeeConfirmationPending ? 'auth' : 'shipping')
          }).catch(e => {
            this.isValidationRun = true
          })
      })
    },
    populateUserData () {
      if (this.savedUserData) {
        Object.keys(this.form).forEach(key => {
          if (this.savedUserData[key]) this.form[key].value = this.savedUserData[key].value
        })
      }
    }
  },
  validations () {
    return {
      form: {
        firstName: {
          value: {
            required,
            minLength: minLength(2),
            alpha
          }
        },
        lastName: {
          value: {
            required,
            minLength: minLength(2),
            alpha
          }
        },
        email: {
          value: {
            required,
            email: (val) => email(this.emailFormatter(val)),
            server: this.server
          }
        },
        phone: {
          value: {
            required,
            minLength: minLength(10)
          }
        },
        password: {
          value: {
            required,
            password: this.password,
            server: this.server
          }
        },
        confirmPassword: {
          value: {
            required,
            sameAs: sameAs(() => this.form.password.value)
          }
        },
        terms: {
          value: {
            required,
            sameAs: sameAs(() => true)
          }
        }
      }
    }
  }
}
</script>
