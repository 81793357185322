import zApiService from '@/services/z-api'
import router from '@/router'
import Vue from 'vue'
import ContentfulService from '@/services/contentful'
import { OptimizelyService } from '@/services/optimizely'
import { FullstoryService } from '@/services/fullstory'
import { transformAddressBookRecordToForm, transformAVSAddress, transformStore } from '@/transformers'
import AnalyticsService from '@/services/analytics'
import { defineStore } from 'pinia'

import {
  shoppingListsStore,
  cartStore,
  checkoutFlowStore,
  appStore
} from '@/stores'

const userDefaultState = {
  userData: null,
  isEmployeeConfirmationPending: false,
  isEmployeeConfirmationPendingText: null,
  addressValidation: {},
  addressConfirmed: false,
  addressBookRecords: [],
  location: null,
  guestRemovedLocation: false,
  adjustUserLocationToCartAfterLoginLoader: false
}

const state = {
  ...userDefaultState,
  guestUserData: null
}

const mutations = {
  SET_USER_LOCATION_LOADER (data) {
    this.adjustUserLocationToCartAfterLoginLoader = data
  },
  SET_USER_DATA (data) {
    this.userData = data
  },
  SET_USER_LOCATION (data) {
    this.location = data
  },
  SET_GUEST_REMOVED_LOCATION (data) {
    this.guestRemovedLocation = data
  },
  SET_EMPLOYEE_CONFIRMATION_PENDING (data) {
    this.isEmployeeConfirmationPending = data
  },
  SET_EMPLOYEE_CONFIRMATION_PENDING_TEXT (data) {
    this.isEmployeeConfirmationPendingText = data
  },
  SET_ADDRESS_VALIDATION (data) {
    this.addressValidation = data
  },
  RESET_ADDRESS_VALIDATION () {
    this.addressValidation = null
  },
  CONFIRM_ADDRESS (data) {
    this.addressValidation.confirmedAddress = data
    this.addressConfirmed = true
  },
  RESET_CONFIRMED_ADDRESS () {
    this.addressConfirmed = false
  },
  SET_ADDRESS_BOOK_RECORDS (data) {
    this.addressBookRecords = data
  },
  RESET_USER () {
    Object.assign(this, userDefaultState)
  },
  SET_GUEST_DATA () {
    this.guestUserData = structuredClone(this.userData)
  },
  RESET_GUEST_DATA () {
    this.guestUserData = null
  }
}

const actions = {
  ...mutations,
  setGuestUserData (data) {
    this.SET_USER_DATA(data)
  },

  resetUser () {
    shoppingListsStore().RESET_SHOPPING_LISTS()
    this.RESET_USER()
  },

  async adjustUserLocationToCartAfterLogin () {
    const { guestRemovedLocation, userData, location } = this
    if (cartStore().storePickupProducts?.length) {
      if (cartStore().deliveryPointOfService.name !== userData.defaultStore) {
        await zApiService.user.setDefaultStore(cartStore().deliveryPointOfService.name)
      }
      if (cartStore().deliveryPointOfService.name !== location) {
        this.SET_USER_LOCATION(transformStore(cartStore().deliveryPointOfService))
        Vue.toasted.info('For your convenience, we have updated your shopping location to match the store for the products in your existing cart.', {
          className: 'cart__info-toast',
          duration: 20000,
          action: {
            text: 'X',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
            }
          }
        })
      }
    } else if (userData.defaultStore) {
      if (location) {
        if (userData.defaultStore !== location.name) {
          await zApiService.user.setDefaultStore(location.name)
        }
      } else if (guestRemovedLocation) {
        await zApiService.user.setDefaultStore(null)
      } else {
        this.SET_USER_LOCATION(transformStore(userData.defaultStoreDetails))
      }
    } else if (location) {
      await zApiService.user.setDefaultStore(location.name)
    }
    this.SET_GUEST_REMOVED_LOCATION(false)
  },

  async login (credentials) {
    if (this.isGuest) {
      if (!cartStore().cartData && cartStore().clientSideCartSize) {
        // get the previous cart for the anonymous user instead of client side "clientSideCartSize"
        // we need to get the real cart id to be merged with the registered cart further
        await cartStore().setCartData()
      }
      const { userId } = await zApiService.auth.login(credentials)
      const { user } = await zApiService.auth.getUserData(encodeURIComponent(userId))
      AnalyticsService.login()
      this.SET_USER_DATA(user)
      shoppingListsStore().SET_SHOPPING_LISTS(user.wishlists || [])
      FullstoryService.identify(this.userData.uid, this.userData.name)
      if (user.defaultStore) {
        if (this.location) {
          if (user.defaultStore !== this.location.name) {
            await zApiService.user.setDefaultStore(this.location.name)
          }
        } else if (this.guestRemovedLocation) {
          await zApiService.user.setDefaultStore(null)
        } else {
          this.SET_USER_LOCATION(transformStore(user.defaultStoreDetails))
        }
      } else if (this.location) {
        await zApiService.user.setDefaultStore(this.location.name)
      }

      this.SET_EMPLOYEE_CONFIRMATION_PENDING(null)
      if (router.currentRoute.path === '/login') {
        if (router.currentRoute.query?.order) {
          router.push(`/account/orders/${router.currentRoute.query.order}`)
        } else {
          router.push('/')
        }
      }
      if (router.currentRoute.path === '/confirmation') {
        this.RESET_GUEST_DATA()
      }
      checkoutFlowStore().RESET_USER_INPUT()
      checkoutFlowStore().SET_STEP('shipping')
      await cartStore().setCartData() // should be fulfilled before adjustUserLocationToCartAfterLogin
      await Promise.all([
        this.adjustUserLocationToCartAfterLogin(),
        shoppingListsStore().loadAllProductCodes()
      ])
      OptimizelyService.accountEvent('login')
      return Promise.resolve()
    }
  },

  async signUp (data) {
    const showPendingMessage = () => {
      if (this.isEmployeeConfirmationPendingText) {
        Vue.toasted.success(this.isEmployeeConfirmationPendingText, {
          duration: 10000
        })
      }
    }
    if (this.isGuest) {
      const user = await zApiService.auth.signUp(data)
      if (user) await OptimizelyService.handleSignUp(user, data)
      if (user?.isEmployee) {
        if (!this.isEmployeeConfirmationPendingText) await this.loadIsEmployeeConfirmationPendingText()
        this.SET_EMPLOYEE_CONFIRMATION_PENDING(true)
        const currentRoute = router.currentRoute.path
        if (currentRoute === '/checkout') {
          router.push('/cart')
        } else if (currentRoute === '/' || currentRoute === '/checkout/confirmation') {
          showPendingMessage()
        } else {
          router.push('/')
          showPendingMessage()
        }
        // employee customers should confirm their registration from their employee's email
        // and then login to the application to get the employee pricing
      } else {
        const credentials = {
          username: data.email,
          password: data.password
        }
        AnalyticsService.signUp()
        return await this.login(credentials)
      }
    }
  },

  async logout (redirectRoutePath) {
    await zApiService.auth.logout()
    if (this.isRegisteredUser) {
      OptimizelyService.accountEvent('logout')
      AnalyticsService.logout()
    }
    await appStore().reset()
    if (redirectRoutePath && router.currentRoute.path !== redirectRoutePath) {
      router.push(redirectRoutePath)
    }
  },

  async loadIsEmployeeConfirmationPendingText () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'particleText',
      'notify-employee-to-confirm-registration-text'
    ))?.fields

    this.SET_EMPLOYEE_CONFIRMATION_PENDING_TEXT(entry.textString)
  },

  async validateAddress (data) {
    this.SET_ADDRESS_VALIDATION({})
    let validationResult = {}
    try {
      validationResult = await zApiService.user.validateAddress(data)
      this.SET_ADDRESS_VALIDATION({
        address: data,
        ...validationResult
      })
      if (validationResult.decision === 'ACCEPT') {
        this.CONFIRM_ADDRESS(transformAVSAddress(data))
      }
      return validationResult.decision
    } catch (e) {
      if (/^5\d{2}$/.test(e?.response?.status)) {
        // if service is unavailable the order will not be stopped
        this.SET_ADDRESS_VALIDATION({
          address: data
        })
        this.CONFIRM_ADDRESS(transformAVSAddress(data))
      } else {
        throw e
      }
    } finally {
      if (
        this.addressValidation?.decision === 'REVIEW' ||
        this.addressValidation?.decision === 'REJECT' ||
        this.addressValidation?.decision === 'UNKNOWN'
      ) {
        appStore().SET_CONFIRM_EMPLOYEE_EMAIL_VISIBILITY(true)
      }
    }
  },

  async sendResetEmail (email) {
    await zApiService.user.sendResetEmail(email)
  },

  async sendResetPassword (data) {
    await zApiService.user.sendResetPassword(data)
  },

  async checkResetPasswordLinkExpiration (data) {
    await zApiService.auth.checkResetPasswordLinkExpiration(data)
  },

  async validateEmployeeEmail (data) {
    await zApiService.auth.validateEmployeeEmail(data)
  },

  async updatePersonalInformation (data) {
    const user = await zApiService.user.updatePersonalInformation(data.userId, data.form)
    if (user) {
      OptimizelyService.accountEvent('update')
      AnalyticsService.update()
    }
    this.SET_USER_DATA(user)
  },

  async updatePassword (data) {
    await zApiService.user.updatePassword(data.userId, data.password)
  },

  async loadAddresses () {
    const addresses = await zApiService.addressBookService.getRecords()
    const transformedAddresses = addresses.map((address) => transformAddressBookRecordToForm(address))
    this.SET_ADDRESS_BOOK_RECORDS(transformedAddresses)
    return this.addressBookRecords
  },

  async addAddress (data) {
    const address = await zApiService.addressBookService.createRecord(data)
    const copyAddressBookRecords = [...this.addressBookRecords]
    if (data.isDefault) {
      // reset the default flags for all records if any record has been set default
      copyAddressBookRecords.forEach((record) => {
        record.isDefault = false
      })
    }
    OptimizelyService.accountEvent('update')
    AnalyticsService.update(true)
    this.SET_ADDRESS_BOOK_RECORDS([address, ...copyAddressBookRecords])
    return address
  },

  async updateAddress (data) {
    await zApiService.addressBookService.patchRecord(data)

    const recordToReplaceWithUpdated = this.addressBookRecords.find(record => record.id === data.id)
    const indexOfRecord = this.addressBookRecords.indexOf(recordToReplaceWithUpdated)
    const copyAddressBookRecords = [...this.addressBookRecords]
    if (data.isDefault) {
      // reset the default flags for all records if any record has been set default
      copyAddressBookRecords.forEach((record) => {
        record.isDefault = false
      })
    } else if (data.isDefault === false && recordToReplaceWithUpdated.isDefault) {
      // if record was default then it remains default
      data.isDefault = true
    }
    copyAddressBookRecords.splice(indexOfRecord, 1, data)
    OptimizelyService.accountEvent('update')
    AnalyticsService.update(true)
    this.SET_ADDRESS_BOOK_RECORDS(copyAddressBookRecords)
    return data
  },

  async deleteAddress (addressId) {
    await zApiService.addressBookService.deleteRecord(addressId)

    const recordToRemove = this.addressBookRecords.find(record => record.id === addressId)
    const indexOfRecord = this.addressBookRecords.indexOf(recordToRemove)
    const copyAddressBookRecords = [...this.addressBookRecords]
    copyAddressBookRecords.splice(indexOfRecord, 1)
    OptimizelyService.accountEvent('update')
    AnalyticsService.update(true)
    this.SET_ADDRESS_BOOK_RECORDS(copyAddressBookRecords)
  },

  async setUserLocation (newLocation) {
    if (this.isRegisteredUser) {
      await zApiService.user.setDefaultStore(newLocation ? newLocation.name : null)
    } else {
      if (newLocation === null) {
        this.SET_GUEST_REMOVED_LOCATION(true)
      } else {
        this.SET_GUEST_REMOVED_LOCATION(false)
      }
    }
    this.SET_USER_LOCATION(newLocation)
  }
}

const getters = {
  isGuest: state => !state.userData || state.userData.isGuest,
  isRegisteredGuest: state => state.userData?.isGuest,
  isRegisteredUser: state => !!state.userData?.uid && !state.userData?.isGuest, // registered user and not the registered guest
  isEmployee: state => state.userData?.isEmployee || false,
  userId: state => state.userData?.uid || null,
  isStoreLocation: state => state.location && typeof state.location === 'object' && 'name' in state.location,
  parentCategory: state => state.userData?.parentCategory ? state.userData.parentCategory : null
}

export const userStore = defineStore('user', {
  persist: {
    paths: ['userData', 'location']
  },
  state: () => ({
    ...state
  }),
  getters,
  actions,
  share: {
    enable: true,
    initialize: true
  }
})
