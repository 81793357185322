<template>
  <div
    role="search"
    class="header-search"
  >
    <form @submit.prevent="$emit('on-search-submit')">
      <div class="input-search">
        <label>
          <input
            :value="value"
            type="search"
            :placeholder="'Search...'"
            aria-label="Search"
            @input="(e) => $emit('input', e.target.value)"
          >
        </label>
        <button
          type="submit"
          class="btn-search"
        >
          <img
            src="@/assets/icons/search.svg"
            alt="search"
            class="btn-search__icon"
            loading="lazy"
            width="16.36"
            height="17.3"
          >
        </button>
      </div>
    </form>
  </div>
</template>
<script>
export default {
  name: 'SearchInput',
  props: {
    value: {
      type: String,
      default: ''
    }
  }
}
</script>
