import ApiBase from '@/services/z-api/v1/api-base'
import { userStore } from '@/stores'

class User extends ApiBase {
  constructor (transport) {
    super(transport)
    this.createNewAddress = this.createNewAddress.bind(this)
    this.updatePersonalInformation = this.updatePersonalInformation.bind(this)
    this.updatePassword = this.updatePassword.bind(this)
    this.sendResetEmail = this.sendResetEmail.bind(this)
    this.sendResetPassword = this.sendResetPassword.bind(this)
    this.setDefaultStore = this.setDefaultStore.bind(this)
  }

  /**
   * Create new address for user
   * @param {Object} data
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.line1
   * @param {string} data.line2
   * @param {string} data.town
   * @param {Object} data.country
   * @param {string} data.country.isocode
   * @param {Object} data.region
   * @param {string} data.region.countryIso
   * @param {string} data.region.isocode
   * @param {string} data.region.name
   * @param {string} data.postalCode
   * @param {string} userId
   */
  createNewAddress (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId(userId)}/addresses`, data)
    })
  }

  /**
   * @typedef SuggestedAddress
   * @property {Object} data
   * @property {string} data.firstName
   * @property {string} data.lastName
   * @property {string} data.phone1?
   * @property {string} data.line1
   * @property {string} data.line2
   * @property {string} data.town
   * @property {Object} data.country
   * @property {string} data.country.isocode
   * @property {Object} data.region
   * @property {string} data.region.isocode
   * @property {string} data.postalCode
   *
   * @typedef {Object} SuggestedAddressDefaultAddress
   * @property {string} defaultAddress
   *
   * @typedef {SuggestedAddress & SuggestedAddressDefaultAddress} SuggestedAddressResponse
   *
   * @typedef ValidationResponse
   * @property {string} decision "ACCEPT" or "REVIEW"(contains suggestions) or "REJECT"
   * @property {SuggestedAddressResponse[]} suggestedAddresses? contains an array of suggestions
   */

  /**
   * Validate a customer address
   * @param {SuggestedAddress} data
   * @returns {Promise<ValidationResponse>} a mandatory decision part and optional array of suggested addresses
   */
  validateAddress (data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId()}/addresses/validate`, data)
    })
  }

  /**
   * Update profile
   * @param {string} userId
   * @param {Object} data
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.email
   * @param {string} data.phone
   */

  updatePersonalInformation (userId, data) {
    return this._withDecoratedPromise(() => {
      return this.transport.patch(`/rest/api/v2/users/${this._getUserId(userId)}/update`, data)
    })
  }

  /**
   * @param {string} userId
   * @param {Object} data
   * @param {string} data.oldPassword
   * @param {string} data.newPassword
   */

  updatePassword (userId, data) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v2/users/${this._getUserId(userId)}/password`, data)
    })
  }

  /**
   * Requests Password Reset Token
   * @param {String} email
   */

  sendResetEmail (email) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/password/request-password-reset?email=${email}`)
    })
  }

  /**
   * Performs Password Reset
   * @param {Object} data
   * @param {string} data.password
   * @param {string} data.checkPassword
   * @param {string} data.token
   */

  sendResetPassword (data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v2/password/reset-password', data)
    })
  }

  /**
   * Sets a default location for pickup for registered user
   * @param {string|null} storeName storeName or null if we want to remove the default location
   */

  setDefaultStore (storeName) {
    return this._withDecoratedPromise(() => {
      return this.transport
        .patch(`/rest/api/v2/users/${encodeURIComponent(userStore().userId)}/store`, {
          defaultStore: storeName
        })
    })
  }
}

export default User
