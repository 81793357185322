import settings from '@/settings'
import { detectSafari, rendererOptions } from '@/helpers'
import { createClient as createContentfulClient } from 'contentful'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

const _client = createContentfulClient({
  space: settings.services.contentful.space.id,
  environment: settings.services.contentful.space.environment,
  accessToken: settings.services.contentful.accessToken,
  host: settings.services.contentful.host
})

export default {
  getEntriesByQuery (query) {
    return _client.getEntries(query).then(response => response.items || null)
  },
  getEntryByTypeAndKey (type, key) {
    return _client.getEntries({
      skip: 0,
      limit: 1,
      content_type: type,
      include: 5,
      'fields.key': key
    }).then(response => response.items ? response.items[0] : null)
  },
  extractContentType (entry) {
    return entry.sys.contentType?.sys.id
  },
  extractEntryFromIncludesByType (field, type) {
    const linkEntry = field.fields.includes
      ? field.fields.includes.find(el => this.extractContentType(el) === type)
      : null
    return linkEntry
      ? {
        title: linkEntry.fields.title,
        url: linkEntry.fields.url
      }
      : null
  },
  extractImage (imageField, format = '', quality = 80, width = '', height = '') {
    if (!imageField || !Object.keys(imageField).length || !('fields' in imageField) || !('file' in imageField.fields)) {
      return null
    }
    if (format === '') {
      format = detectSafari() ? 'fl=progressive' : 'fm=webp'
    } else if (format === null) {
      format = ''
    }
    return {
      url: `${imageField.fields.file.url}?${format}&q=${quality}&w=${width}&h=${height}`,
      alt: imageField.fields.description || imageField.fields.title
    }
  },
  extractImageList (list) {
    return list
      ? {
        default: this.extractImage(list.image) || null,
        laptop: this.extractImage(list.imageLaptop)?.url || null,
        tablet: this.extractImage(list.imageTablet)?.url || null,
        mobile: this.extractImage(list.imageMobile)?.url || null
      }
      : null
  },
  extractMeta (meta) {
    return meta.fields
      ? {
        title: meta.fields.title || null,
        description: meta.fields.description || null
      }
      : null
  },
  extractVideo (videoField) {
    if (!videoField?.video || !Object.keys(videoField?.video).length || !('fields' in videoField?.video) || !('file' in videoField?.video?.fields)) {
      return null
    }
    return {
      url: videoField.video.fields.file.url || null,
      preview: this.extractImage(videoField.preview) || null
    }
  },
  extractBanner (entryBanner) {
    if (entryBanner?.fields) {
      return {
        url: entryBanner.fields.link?.fields?.url,
        imageList: this.extractImageList(entryBanner.fields),
        key: entryBanner.fields.key
      }
    }
  },
  isBannerImage (banner) {
    return banner?.imageList?.default?.url
  },
  extractContent (entry) {
    const result = []
    if (entry?.content?.length) {
      entry.content.forEach((item) => {
        if (item.fields) result.push(this.extractCompositeItemData(item.fields))
      })
    }
    return result
  },
  extractCompositeItemData (compositeItem) {
    const result = {}
    if (compositeItem.includes?.length) {
      compositeItem.includes.forEach((item) => {
        if (item.fields) {
          if (item.fields.image) result.image = this.extractImage(item.fields.image)
          if (item.fields.textString) result.textString = item.fields.textString
          if (item.fields.richText) result.richText = documentToHtmlString(item.fields.richText, rendererOptions)
          if (item.fields.key) result.key = item.fields.key
        }
      })
    }
    return result
  }
}
