<template>
  <modal
    v-if="singleCartNotification.isVisible"
    title="Sorry for the Inconvenience"
    class="single-cart-type-notification"
    @closeModal="CLOSE_CART_NOTIFICATION"
  >
    <article>
      <p class="single-cart-type-notification__description">
        <span v-if="singleCartNotification.itemId">
          We're unable to add {{ singleCartNotification.itemId }} to your cart at this time.
        </span>
        <span v-else>We're unable to change your pickup location.</span>
        We see that you're shopping for "Buy Online Pick Up In Store".
        Currently, we can only support orders with one location.
        Please choose one store and proceed with placing your order.
        Then place a separate order if an additional delivery location is needed.
        In the near future, we will have additional enhancements to the shopping
        cart experience to better serve our customers.
      </p>
      <button
        class="btn single-cart-type-notification__button"
        type="button"
        @click="onCheckOutClick"
      >
        Check Out
      </button>
      <button
        class="btn single-cart-type-notification__button"
        type="button"
        @click="CLOSE_CART_NOTIFICATION"
      >
        Continue Shopping
      </button>
    </article>
  </modal>
</template>

<script>
import Modal from '@/components/Modal'
import { mapActions, mapState } from 'pinia'
import { cartStore } from '@/stores'
import '@/styles/components/_single-cart-type-notification.scss'

export default {
  name: 'SingleCartTypeNotification',
  components: {
    Modal
  },
  computed: {
    ...mapState(cartStore, ['singleCartNotification'])
  },
  methods: {
    ...mapActions(cartStore, ['CLOSE_CART_NOTIFICATION']),
    onCheckOutClick () {
      this.CLOSE_CART_NOTIFICATION()
      if (this.$router.currentRoute.path !== '/cart') this.$router.push('/cart')
    }
  }
}
</script>
