import zApiService from '@/services/z-api'
import { OptimizelyService } from '@/services/optimizely'
import AnalyticsService from '@/services/analytics'
import { transformProductEntry } from '@/transformers'
import { userStore } from '@/stores/user'
import { defineStore } from 'pinia'
import router from '@/router'

const shoppingListNameRegEx = /[^a-zA-Z0-9 ]/g // allows whitespaces, letters, digits

function defaultListChanged (shoppingLists, shoppingListName) {
  return shoppingLists.map((list) => {
    return {
      ...list,
      default: shoppingListName === list.name
    }
  })
}

const defaultState = {
  shoppingLists: [],
  selectedShoppingListProducts: [],
  shoppingListCodes: []
}

const state = {
  ...defaultState
}

const mutations = {
  SET_SHOPPING_LISTS (data) {
    this.shoppingLists = data
  },
  SET_SELECTED_SHOPPING_LIST_PRODUCTS (data) {
    if (data && data[0] && 'addedDate' in data[0]) {
      data = data.map(({ product }) => product)
    }

    this.selectedShoppingListProducts = data
  },
  SET_DEFAULT_LIST (shoppingListName) {
    this.shoppingLists = defaultListChanged(this.shoppingLists, shoppingListName)
  },
  SET_PRODUCT_CODES (productCodes) {
    this.shoppingListCodes = productCodes
  },
  RESET_SHOPPING_LISTS () {
    Object.assign(this, defaultState)
  }
}

const actions = {
  ...mutations,
  async getListDetails ({ listName, isShoppingListEntry = true, compressImage = true }) {
    if (listName) {
      const response = await zApiService.shoppingLists.loadProducts(listName)
      if (response.isDefault) this.SET_DEFAULT_LIST(listName)
      return response?.entries.map(entry => transformProductEntry(entry.product, isShoppingListEntry, compressImage)) || []
    } return []
  },

  async loadSelectedShoppingListDetails (shoppingListName) {
    const response = await this.getListDetails({ listName: shoppingListName })
    this.SET_SELECTED_SHOPPING_LIST_PRODUCTS(response)
  },

  async loadLists () {
    const response = await zApiService.shoppingLists.loadLists()
    this.SET_SHOPPING_LISTS(response.wishlists || [])
  },

  async loadAllProductCodes () {
    if (userStore().isRegisteredUser) {
      const response = await zApiService.shoppingLists.loadAllProductCodes()
      this.SET_PRODUCT_CODES(response?.productCodes || [])
    }
  },

  async setDefaultList (shoppingListName) {
    await zApiService.shoppingLists.updateList(shoppingListName, {
      isDefault: true
    })
    this.SET_DEFAULT_LIST(shoppingListName)
  },

  async getDefaultList () {
    const lists = this.shoppingLists

    if (lists.length > 0) {
      const defaultList = lists.find((item) => item.isDefault === true)
      const payload = {
        listName: defaultList?.name,
        isShoppingListEntry: true,
        compressImage: false
      }
      const response = (await this.getListDetails(payload))?.filter(el => isAvailable(el))
      if (response.length > 9) {
        response.sort(() => 0.5 - Math.random()).slice(0, 9)
      }
      this.SET_SELECTED_SHOPPING_LIST_PRODUCTS(response)
    } else {
      this.SET_SELECTED_SHOPPING_LIST_PRODUCTS([])
    }
  },

  async renameList ({ shoppingListName, newName }) {
    const updatedList = await zApiService.shoppingLists.updateList(shoppingListName, {
      newName: newName.replace(shoppingListNameRegEx, '')
    })
    const updatedShoppingLists = this.shoppingLists.map((shoppingList) => {
      if (shoppingList.name === shoppingListName) {
        return updatedList
      }
      return shoppingList
    })
    this.SET_SHOPPING_LISTS(updatedShoppingLists)
  },

  async deleteList (shoppingListName) {
    await zApiService.shoppingLists.deleteList(shoppingListName)
    if (this.shoppingLists.find(el => el.name === shoppingListName).isDefault) {
      await this.loadLists()
    } else {
      const updatedShoppingList = this.shoppingLists.filter((list) => {
        return shoppingListName !== list.name
      })
      this.SET_SHOPPING_LISTS(updatedShoppingList)
    }
  },

  async addProductToList ({ shoppingListName, productId }) {
    const { entries } = await zApiService.shoppingLists.addProductToList(shoppingListName, {
      products: [productId]
    })
    const updatedShoppinglist = [...this.shoppingListCodes]
    updatedShoppinglist.push(productId)
    OptimizelyService.productEvent('add_to_wishlist', productId)
    const addedProduct = (entries.find(el => el?.product?.code === productId))?.product || null
    if (addedProduct) AnalyticsService.addToWishlist(addedProduct)
    this.SET_PRODUCT_CODES(updatedShoppinglist)

    const isCartPage = router.currentRoute.path === '/cart'
    const isDefaultWishlist = this.shoppingLists
      .find(el => el.name === shoppingListName).isDefault
    if (!isCartPage || (isCartPage && isDefaultWishlist)) {
      // only the default list products are displayed on the cart page
      this.SET_SELECTED_SHOPPING_LIST_PRODUCTS(
        entries.map(entry => transformProductEntry(entry.product, true))
      )
    }
  },

  async deleteProductsFromList ({ shoppingListName, productCodes }) {
    if (productCodes.length === 1) {
      const deletedProduct = this.selectedShoppingListProducts?.find(el => el.id === productCodes[0]) || null
      if (deletedProduct) {
        AnalyticsService.removeFromWishlist(deletedProduct)
        OptimizelyService.productEvent('remove_from_wishlist', productCodes[0])
      }
    }
    const { entries } = await zApiService.shoppingLists.deleteProductsFromList(shoppingListName, { products: productCodes })
    await this.loadAllProductCodes()
    this.SET_SELECTED_SHOPPING_LIST_PRODUCTS(entries.map(entry => transformProductEntry(entry.product, true)) || [])
  },

  async createList (shoppingListName) {
    const entries = await zApiService.shoppingLists.createList(shoppingListName.replace(shoppingListNameRegEx, ''))
    this.SET_SHOPPING_LISTS(entries.wishlists)
  },

  async moveProductsTolist (payload) {
    await zApiService.shoppingLists.moveToList(payload)
  },

  async setSelectedShoppingList (lists) {
    if (lists.length > 0) {
      const defaultList = lists.find((item) => item.isDefault === true)
      return defaultList?.name || lists[0]?.name
    } else {
      return ''
    }
  },

  async shareShoppingList ({ shoppingListName, senderName, emails, message = '' }) {
    await zApiService.shoppingLists.shareShoppingList(shoppingListName, {
      senderName: senderName.replace(shoppingListNameRegEx, ''),
      emails: [emails],
      message: message.replace(shoppingListNameRegEx, ''),
      sendMeACopy: true
    })
  }
}

const isAvailable = (product) => {
  return !product.archived && ((product.stockStatus !== 'outOfStock') || product.availableForPickup)
}

export const shoppingListsStore = defineStore('shoppingLists', {
  persist: {
    paths: ['shoppingLists']
  },
  state: () => ({
    ...state
  }),
  actions
})
