<template>
  <div
    class="header"
    :class="{ 'header--border': isBorderVisible }"
  >
    <a
      class="skip-to-content"
      href="#main-content"
    >
      Skip to main content
    </a>
    <div
      v-if="headerData"
      class="header-wrap"
    >
      <div class="container container--page">
        <div class="header-inner">
          <div class="header-col">
            <logo
              v-if="headerData.logo"
              :image="headerData.logo.image"
              :link="headerData.logo.link"
              class="header-logo"
            />
            <main-menu
              v-if="headerData.navLinks"
              :links="headerData.navLinks"
            />
          </div>
          <div class="header-col">
            <search-input
              :value="searchQuery"
              @on-search-submit="onSearchSubmit"
              @input="updateSearchQuery"
            />
            <search-flyout
              v-if="isFlyoutActive && searchQuery"
              v-click-outside="closeFlyout"
              :search-query="searchQuery"
              :data="getFlyoutData"
              :is-loading="isLoading"
              @see-results="onSearchSubmit"
              @category-clicked="categoryRedirect"
              @page-clicked="pageRedirect"
              @product-redirect="productRedirect"
              @hide-flyout="isFlyoutActive = false"
            />
            <div
              v-if="isTablet"
              class="header-mob-block"
            >
              <button
                class="hamburger-btn"
                aria-label="Show Menu"
                @click="showMenu = true"
              >
                <div class="hamburger-btn-box">
                  <div class="hamburger-btn-inner" />
                </div>
              </button>
              <logo
                v-if="headerData.logo"
                :image="headerData.logo.image"
                :link="headerData.logo.link"
                class="header-logo"
              />
            </div>
            <div class="header-buttons">
              <router-link
                to="/account/shopping-lists"
                aria-label="Shopping Lists"
              >
                <base-icon class="wishlist__icon">
                  <ico-like />
                </base-icon>
              </router-link>
              <router-link
                to="/cart"
                class="header-notification-icon"
                aria-label="Cart"
              >
                <base-icon class="cart__icon">
                  <ico-cart />
                </base-icon>
                <span
                  v-if="cartSize"
                  class="header-notification-icon__count"
                >{{ cartSizeCopy }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <mob-menu
      v-if="isMobMenuExist"
      :links="headerData.navLinks"
      @closeMobMenu="showMenu = false"
    />
    <div
      v-if="showMenu"
      class="mob-menu-undercover"
      @click="showMenu = false"
    />
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import MainMenu from '@/components/menu/MainMenu'
import MobMenu from '@/components/menu/MobMenu'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import debounce from 'lodash.debounce'
import ClickOutside from 'vue-click-outside'
import BaseIcon from '@/components/BaseIcon'
import IcoLike from '@/assets/iconComponents/IcoLike'
import IcoCart from '@/assets/iconComponents/IcoCart'
import { CONTENT_PAGES_CONTENTFUL_TO_VUE_MAPPING, handleChunkLoadingError } from '@/helpers'
import SearchInput from '@/components/SearchInput.vue'
import { commonSearchSubmit } from '@/helpers/search'
import { mapActions, mapGetters, mapState } from 'pinia'
import { cartStore, contentStore, searchStore } from '@/stores'

const DEBOUNCE_TIME = 500

export default {
  name: 'AppHeader',
  directives: { ClickOutside },
  components: {
    SearchInput,
    Logo,
    MainMenu,
    MobMenu,
    'search-flyout': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'search-flyout' */'@/components/search/SearchFlyout')
    ),
    BaseIcon,
    IcoLike,
    IcoCart
  },
  mixins: [ScreenWidthMixin, LoaderMixin],
  data () {
    return {
      showMenu: false,
      searchQuery: '',
      isFlyoutActive: false
    }
  },
  computed: {
    ...mapState(contentStore, ['headerData']),
    ...mapState(searchStore, ['flyoutData']),
    ...mapGetters(cartStore, ['cartSize']),
    isBorderVisible () {
      return this.$route.name !== 'Home'
    },
    getFlyoutData () {
      return this.flyoutData || {}
    },
    isMobMenuExist () {
      return this.headerData && this.headerData.navLinks && this.showMenu && !this.isDesktop
    },
    cartSizeCopy () {
      return this.cartSize > 100 ? '99+' : this.cartSize
    }
  },
  created () {
    this.setHeaderData()
  },
  methods: {
    ...mapActions(contentStore, ['setHeaderData']),
    ...mapActions(searchStore, ['setFlyoutData', 'resetProductsData']),
    closeFlyout () {
      this.isFlyoutActive = false
    },
    categoryRedirect (category) {
      this.$router.push({
        path: 'search',
        name: 'Search',
        query: { facets: `'category.lvl0':'${category}'`, query: this.searchQuery },
        params: { key: new Date().getTime() }
      })
      this.closeFlyout()
    },
    pageRedirect (contentType, key) {
      this.$router.push({
        name: CONTENT_PAGES_CONTENTFUL_TO_VUE_MAPPING[contentType],
        params: { key }
      })
      this.closeFlyout()
    },
    productRedirect () {
      this.closeFlyout()
      this.searchQuery = ''
    },
    onSearchSubmit () {
      commonSearchSubmit(
        this.searchQuery,
        () => this.resetProductsData(),
        () => {
          this.closeFlyout()
          this.searchQuery = ''
        }
      )
    },
    updateSearchQuery (value) {
      this.searchQuery = value
      if (this.searchQuery.length > 2) {
        this.debounceSearch()
      } else {
        this.closeFlyout()
      }
    },
    debounceSearch: debounce(function (e) {
      this.withLoader(() => {
        this.isFlyoutActive = true
        return this.setFlyoutData(this.searchQuery)
      })
    }, DEBOUNCE_TIME)
  }
}
</script>
