import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulService from '@/services/contentful'
import Vue from 'vue'
import STATES from '@/data/states.json'
import Sentry from '@/services/sentry'
const NotFound = () => import(/* webpackChunkName: 'not-found' */'@/views/NotFound')

// constants
export const DEFAULT_MAX_QTY = 999
export const ONLY_LETTERS_REGEX = /^[a-zA-Z\s]*$/
export const CONTENT_PAGES_CONTENTFUL_TO_VUE_MAPPING = {
  // [contentful content type key]: [Vue route name]
  pageAbout: 'AboutPage',
  pageService: 'ServicePage',
  pageCompany: 'CompanyPage'
}

export function getCurrentPosition (handleError) {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        return resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
      },
      (error) => {
        return typeof handleError === 'function' ? handleError(error) : reject(error)
      })
  })
}

export function getQueryCoordinates () {
  let coordsObj = window.localStorage.getItem('storeCoordsObj')
  if (coordsObj) {
    coordsObj = JSON.parse(coordsObj)
    window.localStorage.removeItem('storeCoordsObj')
    return {
      lat: Number(coordsObj.lat),
      lng: Number(coordsObj.long)
    }
  } else {
    return null
  }
}

const windowNavigator = window.navigator.userAgent.toLowerCase()

export const detectSafari = () => {
  return (/^((?!chrome|android).)*safari/gi).test(windowNavigator)
}

export const getSortingKeys = (items) => {
  return items.map(el => el.fields.key)
    .reduce((sum, cur, idx) => {
      sum[cur] = idx
      return sum
    }, {})
}

export const getPhone = (val) => {
  return val
    ? `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`
    : ''
}

export const formatPrice = price => {
  if (price != null && !isNaN(price)) {
    return `$${parseFloat(price)
      .toLocaleString(
        'en-US',
        { minimumFractionDigits: 2, maximumFractionDigits: 2 }
      )}`
  }
  return null
}

export const parsePrice = formattedPrice => {
  if (formattedPrice && typeof formattedPrice === 'string') {
    const numberString = formattedPrice
      .replace(/[^0-9.-]+/g, '')
    return parseFloat(numberString)
  }
  return null
}

export const getPageMeta = entry => {
  if (!entry) return null

  if (entry.meta) {
    return ContentfulService.extractMeta(entry.meta)
  }

  return {
    title: entry.title,
    description: `${entry.title} ${entry?.subtitle || ''}`
  }
}

export const rendererOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node) => {
      const text = node.content[0].value.replace(/\n/g, '<br>')
      return `<p>${text}</p>`
    },
    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const image = ContentfulService.extractImage(node.data?.target)
      if (image) {
        return `<img
              src=${image.url}
              alt=${image.alt}
              loading="lazy"
            />`
      }
    }
  }
}

// weekdayNumber is a number, where 0 is Sunday
export const getOpenningHoursObjByWeekdayNumber = (openingHours, weekdayNumber) => {
  return openingHours.weekDayOpeningList[weekdayNumber - 1 === -1 ? 6 : weekdayNumber - 1]
}

export const hoursLabel = (openingHours) => {
  const weekdayNumberToday = new Date().getDay()
  const todayObj = getOpenningHoursObjByWeekdayNumber(openingHours, weekdayNumberToday)
  const closedToday = todayObj.closed
  let opensAt, closesAt

  if (closedToday) {
    const tomorrowObj = getOpenningHoursObjByWeekdayNumber(openingHours, +weekdayNumberToday + 1)
    if (!tomorrowObj.closed) {
      opensAt = tomorrowObj.openingTime?.formattedHour
      closesAt = tomorrowObj.closingTime?.formattedHour
      return `Open Tomorrow at ${opensAt} - ${closesAt}`
    } else {
      return 'Closed today'
    }
  }

  opensAt = todayObj.openingTime?.formattedHour
  closesAt = todayObj.closingTime?.formattedHour
  const isOpenNow = Date.now() > new Date().setHours(todayObj.openingTime?.hour, todayObj.openingTime?.minute) &&
    Date.now() > new Date().setHours(todayObj.closingTime?.hour, todayObj.closingTime?.minute)
  if (isOpenNow) {
    return `Open until ${closesAt}`
  } else {
    return `Open today ${opensAt} - ${closesAt}`
  }
}

export const makeEditable = (form) => {
  form.editable = true
  for (const input in form.inputs) {
    if (input !== 'email') form.inputs[input].disabled = false
  }
}

export const disableForm = (form) => {
  form.editable = false
  for (const input in form.inputs) {
    form.inputs[input].disabled = true
  }
}

export class Deferred {
  constructor (result) {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject
      this.resolved = false
      if (result) {
        this.resolved = true
        resolve(result)
      } else {
        this.resolve = () => {
          this.resolved = true
          resolve()
        }
      }
    })
  }
}

export const formatDate = (date) => {
  return date.split('T')[0].split('/').join('-')
}

export const formatDateTime = (date) => {
  const dateObj = new Date(date)
  let monthNumber = dateObj.getMonth() + 1
  if (monthNumber === 13) monthNumber = 1
  const month = returnTwoDigits(monthNumber)
  const day = returnTwoDigits(dateObj.getDate())
  const hour = returnTwoDigits(dateObj.getHours())
  return `${dateObj.getFullYear()}-${month}-${day} ${hour}:${dateObj.getMinutes()}`
}

const returnTwoDigits = (dateString) => ('0' + dateString).slice(-2)

export const loadScript = (src, attrs) => {
  if (src) {
    const script = document.createElement('script')
    script.src = src
    script.async = false
    if (attrs) {
      for (const key in attrs) {
        if (key === 'data-domain-script') {
          script.setAttribute('data-domain-script', attrs[key])
        } else {
          script[key] = attrs[key]
        }
      }
    }

    document.head.appendChild(script)
  }
}

export const getProductQuantityLimit = (product) => {
  if (product.pickupStore) {
    const productPickupStore = product.stockInStores.find((storeObj) => storeObj.storeName === product.pickupStore)
    return productPickupStore ? productPickupStore.maxOrderQuantity : 0
  } else {
    return product.maxOrderQuantity
  }
}

export const validateQuantityChange = (product) => {
  const quantityLimit = getProductQuantityLimit(product)

  if (product.quantity > quantityLimit) {
    Vue.toasted.error(`The requested quantity for ${product.id} is no longer available. Please review the adjustment before continuing.`)
    throw Error('InsufficientStockError')
  }
}

export const getRegion = (isocode) => {
  const region = STATES.find(region => region.isocode === isocode)
  if (region) return region.name
}

export const sortFacets = (facetA, facetB) => {
  if (facetA.isHighlighted || facetB.isHighlighted) {
    return facetB.isHighlighted - facetA.isHighlighted
  } else if (facetA.isRefined && !facetB.isRefined) {
    return -1
  } else {
    return facetB.valueQty - facetA.valueQty
  }
}

export const toastChunkLoadingError = (error) => {
  if (isChunkLoadingError(error)) {
    Vue.toasted.error('The application has been updated, please reload this page')
  } else {
    console.error(error)
  }
}

export const isChunkLoadingError = (error) => {
  return /loading chunk chunk-([0-9]|[a-z])* failed./i.test(error.message) || /oading chunk/i.test(error.message)
}
export const isModuleNotFoundError = (error) => {
  return error?.code === 'MODULE_NOT_FOUND' ||
    error?.message?.includes('Failed to fetch dynamically imported module')
}

export const handleChunkLoadingError = async (cb) => {
  try {
    return await cb()
  } catch (e) {
    if (isChunkLoadingError(e) || isModuleNotFoundError(e)) {
      const errorFlag = window.localStorage.getItem('reloadedAfterError')
      if (!errorFlag) {
        Vue.toasted.error('The application has been updated. For correct work, this page will be reloaded in 5 sec', {
          duration: 5000
        })
        setTimeout(() => {
          window.localStorage.setItem('reloadedAfterError', true)
          window.location.reload(true)
        }, 5000)
      } else {
        Sentry.log(e)
        window.localStorage.removeItem('reloadedAfterError')
        return isModuleNotFoundError(e) ? undefined : NotFound()
      }
    } else {
      Sentry.log(e)
      throw e
    }
  }
}

export const onlyLettersNumbersSpaces = (name) => {
  const regex = /[^a-zA-Z0-9 ]/g
  return !regex.test(name)
}

export const processAddProductError = (error) => {
  const err = error.response?.data[0]
  if (err?.type === 'IllegalArgumentError') {
    if (err?.message.includes('have reached the maximum of 99 products')) {
      Vue.toasted.error('This list has reached a maximum of 99 products.')
    } else {
      Vue.toasted.error('That product is already on your shopping list.')
    }
  } else {
    Vue.toasted.error('Product has not been added to the list.')
  }
}

export const getTodayHoursText = (openingHours) => {
  const weekdayNumberToday = new Date().getDay()
  const todayObj = openingHours.weekDayOpeningList[weekdayNumberToday - 1 === -1 ? 6 : weekdayNumberToday - 1]
  const closedToday = todayObj.closed
  if (closedToday) {
    return ''
  }
  const opensAt = todayObj.openingTime?.formattedHour
  const closesAt = todayObj.closingTime?.formattedHour
  return `${todayObj.weekDay}. Store Hours: ${opensAt} - ${closesAt}`
}

export const isBot = /bot|googlebot|crawler|spider|robot|crawling|Google-Inspection/i
  .test(navigator.userAgent)

export const carousel = () => handleChunkLoadingError(
  () => import(/* webpackChunkName: 'vue-carousel' */'vue-carousel')
    .then(({ Carousel }) => Carousel)
)
export const slide = () => handleChunkLoadingError(
  () => import(/* webpackChunkName: 'vue-carousel' */'vue-carousel')
    .then(({ Slide }) => Slide)
)

const getCategoryName = (category) => '_' + category.name.toUpperCase().replace(/ /g, '_').replace('&', 'AND')

const getPreviousLevelCode = (currentCategory, currentLevel, previousLevel) =>
  currentCategory.code.replace(getCategoryName(currentCategory), '').replace(`L${currentLevel}_`, `L${previousLevel}_`)

const getTopLevelCategory = (levels, levelKey) => {
  return levels[levelKey][Math.floor(Math.random() * levels[levelKey].length)]
}

const getLevelCategory = (levels, topLevelCategory, levelNumber, prevLevel = null) => {
  const topLevelCode = getPreviousLevelCode(prevLevel || topLevelCategory, levelNumber + 1, levelNumber)
  return levels[`L_${levelNumber}`].find(el => el.code === topLevelCode)
}

export const getSearchQueryForSimilarProducts = (categories) => {
  const levels = {}
  const searchQuery = []
  let topLevelCategory
  let searchLevel = 2
  if (categories) {
    categories.forEach(category => {
      const match = category.code.match(/L(\d+)/)
      if (match) {
        const level = match[1]
        if (!levels[`L_${level}`]) {
          levels[`L_${level}`] = []
        }
        levels[`L_${level}`].push(category)
      }
    })

    if (levels?.L_2) {
      topLevelCategory = getTopLevelCategory(levels, 'L_2')
      searchQuery.push(topLevelCategory.name)
      const levelOne = getLevelCategory(levels, topLevelCategory, 1)
      searchQuery.unshift(levelOne.name)
      const levelZero = getLevelCategory(levels, topLevelCategory, 0, levelOne)
      searchQuery.unshift(levelZero.name)
    } else if (levels?.L_1) {
      topLevelCategory = getTopLevelCategory(levels, 'L_1')
      searchQuery.push(topLevelCategory.name)
      const levelZero = getLevelCategory(levels, topLevelCategory, 0)
      searchQuery.unshift(levelZero.name)
      searchLevel = 1
    } else if (levels?.L_0) {
      topLevelCategory = getTopLevelCategory(levels, 'L_0')
      searchQuery.push(topLevelCategory.name)
      searchLevel = 0
    }

    return { searchQuery, searchLevel }
  } else {
    return null
  }
}
