import Vue from 'vue'
import settings from '@/settings'
import { appStore, cartStore, userStore } from '@/stores'
import Sentry from '@/services/sentry'

const _is401 = error => error && error.response && error.response.status === 401
const _is403 = error => error && error.response && error.response.status === 403
const _isAbove500 = error => error && error.response && error.response.status >= 500

const _known400s = [
  'Access denied',
  'ModelSavingError',
  'ServerError',
  'JaloObjectNoLongerValidError',
  'NullPointerError',
  'UnknownIdentifierError'
]
const _isInKnown400s = error => error?.response?.status === 400 &&
  error?.response?.data &&
  _known400s.includes(error?.response?.data[0].type)

class ApiBase {
  constructor (transport) {
    if (!transport) {
      throw new Error('Api transport cannot be undefined')
    }
    this.transport = transport
  }

  _getUserId (userId) {
    return userId || (userStore().userId ? encodeURIComponent(userStore().userId) : 'anonymous')
  }

  _getCartId () {
    if (userStore().userData?.isGuest) {
      return cartStore().cartId
    } else {
      return 'current'
    }
  }

  async _withDecoratedPromise (cb) {
    try {
      const response = await cb()
      return response.data
    } catch (reason) {
      if (_is401(reason)) {
        appStore().SET_RESET_PENDING(appStore().reset())
        await Promise.resolve(appStore().resetPending)
        const response = await cb()
        return response.data
      } else if (_isInKnown400s(reason) && settings.app.environment !== 'PRODUCTION') {
        Vue.toasted.error(reason?.response?.data[0].message || reason?.response?.data[0].type)
      } else if (_is403(reason)) {
        Sentry.log(reason)
      } else if (_isAbove500(reason)) {
        appStore().handleServerError(reason.response.status || null)
      }
      throw reason
    }
  }
}

export default ApiBase
