import ApiBase from '@/services/z-api/v1/api-base'

class Optimizely extends ApiBase {
  constructor (transport) {
    super(transport)
    this.sendNewUser = this.sendNewUser.bind(this)
    this.consent = this.consent.bind(this)
  }

  /**
   * Send new user
   * @param {Object} user
   * @param {String} user.uid
   */
  sendNewUser (user) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v2/optimizely/customer', user)
    })
  }

  /**
   * Check the active subscription
   * @param {String} email User email
   * @returns {Promise<Object>}
   */
  consent (email) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/optimizely/subscribe?email=${email}`)
    })
  }
}

export default Optimizely
