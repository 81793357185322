<template>
  <picklist>
    <template v-slot:content>
      <div
        class="picklist__button"
      >
        <img
          src="@/assets/icons/account-white.svg"
          class="header-link__icon button__icon"
          alt="account"
          loading="lazy"
        >
        <p class="button__text">
          Welcome {{ userData.firstName }}
        </p>
      </div>
      <loader
        v-if="isLoading"
        style="position: fixed;"
      />
    </template>
    <template
      v-slot:picklist
      class="login-picklist"
    >
      <nav
        aria-label="account menu navigation links (header)"
        class="login-picklist__container"
      >
        <ul class="login-picklist__list">
          <li
            v-for="nav in accountNavList()"
            :key="nav.key"
          >
            <router-link
              class="login-picklist__list-item"
              :to="nav.url"
            >
              <figure class="list-item__icon-wrapper">
                <img
                  :src="nav.img.url"
                  :alt="nav.img.alt"
                  class="list-item__icon"
                  loading="lazy"
                >
              </figure>
              <p class="list-item__text">
                {{ nav.title }}
              </p>
            </router-link>
          </li>
        </ul>
        <ul class="login-picklist__list login-picklist__list--with-bottom-top">
          <li>
            <button
              type="button"
              class="login-picklist__list-item"
              @click="handleLogout"
            >
              <figure class="list-item__icon-wrapper">
                <img
                  src="@/assets/icons/logout.svg"
                  alt="sign-out"
                  class="list-item__icon"
                  loading="lazy"
                >
              </figure>
              <p class="list-item__text">
                Sign Out
              </p>
            </button>
          </li>
        </ul>
      </nav>
    </template>
  </picklist>
</template>

<script>
import Picklist from '@/components/Picklist'
import LoaderMixin from '@/mixins/LoaderMixin'
import { mapActions, mapState } from 'pinia'
import { userStore } from '@/stores'

export default {
  name: 'AppHeaderAccountPicklist',
  components: {
    Picklist
  },
  mixins: [LoaderMixin],
  computed: {
    ...mapState(userStore, ['userData'])
  },
  methods: {
    ...mapActions(userStore, ['logout']),
    accountNavList () {
      return [
        {
          key: 'AccountPersonalInformation',
          title: 'Personal Information',
          url: '/account/personal-information',
          img: {
            url: require('@/assets/icons/account.svg'),
            alt: 'account'
          }
        },
        {
          key: 'AccountOrders',
          title: 'Orders',
          url: '/account/orders',
          img: {
            url: require('@/assets/icons/orders.svg'),
            alt: 'grid'
          }
        },
        {
          key: 'AccountShoppingList',
          title: 'Shopping Lists',
          url: '/account/shopping-lists',
          img: {
            url: require('@/assets/icons/heart-small.svg'),
            alt: 'heart'
          }
        },
        {
          key: 'AccountShippingAddresses',
          title: 'Address Book',
          url: '/account/address-book',
          img: {
            url: require('@/assets/icons/location.svg'),
            alt: 'location'
          }
        }
      ]
    },
    async handleLogout () {
      this.withLoader(() => this.logout('/'))
    }
  }
}
</script>
