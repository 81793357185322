import zApiService from '@/services/z-api'
import {
  appStore,
  cartStore,
  featuredProductsStore,
  productStore,
  locationStore,
  useCrumbs,
  userStore
} from '@/stores'
import router from '@/router'
import { getCurrentPosition, getQueryCoordinates } from '@/helpers'
import { redirectToNotFound } from '@/helpers/commonHelpers'
import MapService from '@/services/map'

export const alignPrices = async (transformedProducts, isSearchResultsPage) => {
  if (!transformedProducts?.length) return transformedProducts

  const isEligibleForPromoPrices =
    cartStore().clientSidePotentialPromo.length &&
    cartStore().categoryYPagePotentialPromo &&
    cartStore().categoryYPagePotentialPromo.code &&
    cartStore().categoryYPagePotentialPromo.eligibleYProductQty &&
    isSearchResultsPage

  if (!isEligibleForPromoPrices && !userStore().isEmployee) {
    return transformedProducts
  }

  const ids = transformedProducts.map((product) => product.id)
  let response
  try {
    if (isEligibleForPromoPrices) {
      response = await zApiService.products.getPromoProductPrices(
        ids,
        cartStore().categoryYPagePotentialPromo.code
      )
    } else if (userStore().isEmployee) {
      response = await zApiService.products.getProductPrices(ids)
    }
    if (response?.prices?.length) {
      response.prices.forEach((priceObj) => {
        const matchingProduct = transformedProducts.find((product) => priceObj.code === product.id)
        if (userStore().isEmployee) {
          matchingProduct.employeeListPrice = priceObj.formattedPrice
        } else if (isEligibleForPromoPrices) {
          matchingProduct.basePrice = priceObj.formattedPrice
          matchingProduct.isBuyXGetYPrice = true
        }
      })
    } else {
      // BE doesn't confirm that user is eligable for the promo
      cartStore().removeClientSidePotentialPromo()
    }
  } catch (error) {
    console.error(error)
  }
  return transformedProducts
}

export async function getProductData (to, from, next) {
  try {
    if (appStore().isInitialized.resolved) appStore().SET_GLOBAL_LOADER(true)
    productStore().UNSET_PRODUCT_DATA()
    const productId = to.params.id
    await productStore().getProduct(productId)
    if (productStore().pageData) {
      if (
        productStore().pageData.images &&
        productStore().pageData.images[0].type === 'image'
      ) {
        const preloadImage = new Image()
        preloadImage.src = productStore().pageData.images[0].url
      }

      useCrumbs().SET_CRUMBS_DATA([{
        id: productStore().pageData.id,
        title: productStore().pageData.name,
        url: router.currentRoute.path,
        hasAction: false
      }])
      if (next) next()
    } else {
      redirectToNotFound()
    }
  } catch (e) {
    console.error(e)
    redirectToNotFound()
  } finally {
    appStore().SET_GLOBAL_LOADER(false)
  }
}

export async function getStoresData (to, from, next) {
  try {
    if (appStore().isInitialized.resolved) appStore().SET_GLOBAL_LOADER(true)
    let coordinates
    const searchBy = to?.query?.query
    const initialSearchCoordinates = getQueryCoordinates()
    if (initialSearchCoordinates) {
      locationStore().SET_COORDIANTES(initialSearchCoordinates)
    } else if (searchBy) {
      const { sourceLatitude, sourceLongitude } = await locationStore().loadStores({ query: searchBy })
      coordinates = {
        lat: sourceLatitude,
        lng: sourceLongitude
      }
    } else {
      const locationPermission = (await navigator.permissions?.query({ name: 'geolocation' }))?.state || 'prompt'
      if (locationPermission === 'granted') {
        coordinates = await getCurrentPosition()
      } else if (locationPermission === 'denied') {
        locationStore().userDeniedGeolocation = true
      }
    }

    locationStore().SET_COORDIANTES(coordinates)

    const loadPromise = Promise.all([
      searchBy ? Promise.resolve() : locationStore().loadStores(coordinates),
      MapService.initMap(),
      locationStore().setPageData()
    ])
    locationStore().loadPromise = loadPromise
    await loadPromise
    if (next) next()
  } catch (e) {
    console.error(e)
    redirectToNotFound()
  } finally {
    appStore().SET_GLOBAL_LOADER(false)
  }
}

export function collapsedStyleFeaturedComponents () {
  const isNoFeaturedProducts = featuredProductsStore().featuredProductsData &&
    !featuredProductsStore().featuredProductsData.length

  return {
    height: isNoFeaturedProducts ? '0px' : '668px',
    marginTop: isNoFeaturedProducts ? '0px' : window.innerWidth <= 767 ? '30px' : '50px'
  }
}
