import ContentfulService from '@/services/contentful'
import { defineStore } from 'pinia'

const state = {
  maintenanceTime: null,
  isMaintenanceTime: false
}

const mutations = {
  SET_MAINTENANCE_TIME (data) {
    this.maintenanceTime = {
      ...data,
      isDowntimePeriodStarted: new Date(data?.downtimeFrom).getTime() < (new Date()).getTime() + 1000,
      isDowntimePeriodNotFinished: new Date(data?.downtimeTill).getTime() > (new Date()).getTime(),
      isDowntimePeriodSet: data?.downtimeFrom && data?.downtimeTill,
      isCurrentTimeBeforeDowntimePeriod: new Date(data?.downtimeTill).getTime() - (new Date()).getTime() > 0
    }
  },
  SET_DOWNTIME_STATUS () {
    this.isMaintenanceTime = this.maintenanceTime.isDowntimePeriodStarted && this.maintenanceTime.isDowntimePeriodNotFinished
  }
}

const actions = {
  ...mutations,
  async getMaintenanceTime () {
    const entry = (await ContentfulService.getEntryByTypeAndKey(
      'blockTime',
      'maintenance-page'
    ))?.fields
    this.SET_MAINTENANCE_TIME(entry)
    this.SET_DOWNTIME_STATUS()
  },
  startCheckingDowntime () {
    if (process?.env?.VUE_APP_ENV !== 'LOCAL') {
      setInterval(() => {
        this.getMaintenanceTime()
      }, 30000)
    }
  },
  reloadApp () {
    location.reload()
  }
}

export const maintenanceStore = defineStore('maintenance', {
  state: () => ({
    ...state
  }),
  actions,
  share: {
    enable: true,
    initialize: true
  }
})
