<template>
  <form @submit.prevent="onFormSubmit">
    <form-group
      v-model.trim="$v.form.email.$model.value"
      :field="$v.form.email"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.email)"
    />
    <form-group
      v-model.trim="$v.form.password.$model.value"
      :field="$v.form.password"
      :is-validation-run="isValidationRun"
      @input="onFieldChange(form.password)"
    />
    <div class="auth__row form-group__row">
      <form-checkbox
        :value="form.remember.value"
        :field="form.remember"
        @change="form.remember.value = !form.remember.value"
      />
      <button
        class="auth__reset-btn"
        type="button"
        @click="$emit('reset-password')"
      >
        Forgot Password
      </button>
    </div>
    <button
      type="submit"
      class="auth__btn btn"
      :class="{ 'btn-disabled': isEmptyFields }"
      :disabled="isEmptyFields"
    >
      Sign In
    </button>
  </form>
</template>
<script>
import FormGroup from '@/components/FormGroup'
import FormCheckbox from '@/components/FormCheckbox'
import FormValidationMixin from '@/mixins/FormValidationMixin'
import { required, email } from 'vuelidate/lib/validators'
import { mapActions } from 'pinia'
import { userStore } from '@/stores'

export default {
  name: 'SignIn',
  components: { FormCheckbox, FormGroup },
  mixins: [FormValidationMixin],
  data () {
    return {
      isValidationRun: false,
      form: {
        email: {
          id: 'sign-in-email',
          type: 'email',
          placeholder: 'EMAIL',
          title: 'email',
          value: '',
          autocomplete: 'email',
          errors: {
            required: 'Please provide a valid email address',
            email: 'Please provide a valid email address'
          }
        },
        password: {
          id: 'sign-in-pass',
          type: 'password',
          placeholder: 'PASSWORD',
          title: 'password',
          value: '',
          autocomplete: 'current-password',
          errors: {
            required: 'Please provide a password',
            password: 'Invalid login credentials. Please try again'
          }
        },
        remember: {
          id: 'sign-in-remember',
          label: 'Remember Me',
          value: false
        }
      }
    }
  },
  computed: {
    isEmptyFields () {
      return !this.$v.form.$model.email.value || !this.$v.form.$model.password.value
    }
  },
  methods: {
    ...mapActions(userStore, ['login', 'SET_USER_LOCATION_LOADER']),
    async onFormSubmit () {
      this.withPresendRoutine(async () => {
        try {
          await this.sendForm(async () => {
            this.SET_USER_LOCATION_LOADER(true)
            await this.login({
              username: encodeURI(this.form.email.value?.toLowerCase()),
              password: encodeURI(this.form.password.value)
            })
            this.$emit('authenticated')
          })
        } catch (e) {
          this.isValidationRun = true
        } finally {
          this.SET_USER_LOCATION_LOADER(false)
        }
      })
    }
  },
  validations () {
    return {
      isValidationRun: false,
      form: {
        email: {
          value: {
            required,
            email: (val) => email(this.emailFormatter(val)),
            server: this.server
          }
        },
        password: {
          value: {
            required,
            password: this.password,
            server: this.server
          }
        }
      }
    }
  }
}
</script>
