import settings from '@/settings'
import zApiService from '@/services/z-api'
import Vue from 'vue'
import { loadScript } from '@/helpers'
import { userStore } from '@/stores'

const isEnabled = settings.services.optimizely.enabled
const apiKey = settings.services.optimizely.apiKey

const getUserEmail = () => {
  const uid = userStore().userId
  return typeof uid === 'string' ? uid.split('|').pop() : ''
}

export const OptimizelyService = {
  init () {
    if (isEnabled) {
      const zaius = window.zaius || (window.zaius = [])
      zaius.methods = [
        'initialize',
        'onload',
        'customer',
        'entity',
        'event',
        'subscribe',
        'unsubscribe',
        'consent',
        'identify',
        'anonymize',
        'dispatch'
      ]
      zaius.factory = function (method) {
        return (...args) => {
          zaius.push([method, ...args])
          return zaius
        }
      }
      zaius.methods.forEach((method) => {
        zaius[method] = zaius.factory(method)
      })
      const protocol = document.location.protocol === 'https:' ? 'https://' : 'http://'
      const url = `${protocol}d1igp3oop3iho5.cloudfront.net/v2/${apiKey}/zaius-min.js`
      loadScript(url, {
        defer: true,
        type: 'text/javascript'
      })
    }
  },

  sendEvent (eventName, payload) {
    if (isEnabled && eventName) {
      try {
        window.zaius.event(eventName, payload)
      } catch (err) {
        console.error(err)
      }
    }
  },

  async sendNewUser (user) {
    if (isEnabled && (user?.uid || user?.email)) {
      await zApiService.optimizely.sendNewUser(user).catch((err) => console.error(err))
    }
  },

  async handleGuestSignUp (user, registredData) {
    await this.sendNewUser(user)
    if (registredData.subscriptionStatus) await this.toastedSubscribe(registredData.email)
  },

  async handleSignUp (user, registredData) {
    await this.sendNewUser(user)
    await this.accountEvent('register', registredData?.email || user?.email)
    if (registredData.subscriptionStatus) await this.toastedSubscribe(registredData.email)
  },

  async accountEvent (action, uid) {
    const ACTIONS = [
      'login',
      'logout',
      'register',
      'update'
    ]
    const payload = {
      action,
      email: uid || getUserEmail()
    }
    if (ACTIONS.includes(action)) {
      this.sendEvent('account', payload)
    } else {
      console.error('The Optimizely service has encountered an error related to the account event name. The event name provided is not recognized or is unknown.')
    }
  },

  async pageView (pageName) {
    this.sendEvent('pageview', {
      email: getUserEmail(),
      title: pageName || ''
    })
  },

  async productEvent (action, id, title = '') {
    const ACTIONS = [
      'detail',
      'add_to_cart',
      'remove_from_cart',
      'add_to_wishlist',
      'remove_from_wishlist'
    ]
    const payload = {
      action,
      email: getUserEmail(),
      product_id: id || '',
      title
    }
    if (ACTIONS.includes(action)) {
      this.sendEvent('product', payload)
    } else {
      console.error('The Optimizely service has encountered an error related to the product event name. The event name provided is not recognized or is unknown.')
    }
  },

  async toastedSubscribe (email) {
    await this.subscribe(email, false)
      .then(res => {
        if (res?.includes('Subscribed')) Vue.toasted.info(res)
      })
      .catch((error) => {
        Vue.toasted.error(error.message)
      })
  },

  async subscribe (email, sendCustomFields = true) {
    if (isEnabled) {
      try {
        const formattedEmail = email?.toLowerCase()
        const subscriptionStatus = await zApiService.optimizely.consent(
          encodeURIComponent(formattedEmail)
        )
        if (!subscriptionStatus?.consent) {
          const payload = {
            consent: true,
            identifier_field_name: 'email',
            identifier_value: formattedEmail,
            event_data: {
              consent_platform: 'HYBRIS'
            }
          }
          if (sendCustomFields) {
            await OptimizelyService.sendNewUser({
              email: formattedEmail
            })
          }
          window.zaius.consent(payload)
          return 'Subscribed successfully!'
        } else {
          return "You're already subscribed to our updates. Thank you!"
        }
      } catch (error) {
        throw new Error(
          'Unfortunately, we were unable to subscribe you to updates at this time. Please try again later.'
        )
      }
    }
  }
}
