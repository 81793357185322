export default {
  methods: {
    withLoader (cb) {
      this.$emit('loading', true)
      this.isLoading = true
      return cb().finally(() => {
        this.$emit('loading', false)
        this.isLoading = false
      })
    }
  },
  data () {
    return {
      isLoading: false
    }
  }
}
