<template>
  <div class="logo__container">
    <a
      class="logo__link"
      :title="image.alt"
      :href="link"
      @click.prevent="onClick"
    >
      <img
        :src="image.url"
        :alt="image.alt"
        class="logo__img"
        loading="lazy"
        width="180"
        height="28"
      >
    </a>
  </div>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    image: {
      type: Object,
      required: true
    },
    link: {
      type: String,
      default: '/'
    }
  },
  methods: {
    onClick () {
      this.$emit('clicked')
      this.$router.push(this.link)
    }
  }
}
</script>
