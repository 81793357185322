import settings from '@/settings'
import { isBot, parsePrice } from '@/helpers'

const AnalyticsService = {
  init () {
    if (settings.services.gtmId && !isBot) {
      window.dataLayer = window.dataLayer || []

      // Add the GTM configuration
      window.dataLayer.push(
        {
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        }
      )

      const gtmScript = document.createElement('script')
      gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${settings.services.gtmId}`
      gtmScript.defer = true
      gtmScript.type = 'text/javascript'
      gtmScript.setAttribute('data-cookieconsent', 'ignore')

      document.head.appendChild(gtmScript)
    }
  },
  _formatProducts (products) {
    return products.map((product) => ({
      item_name: product.title || product.name,
      item_id: product.id || product.code,
      price: typeof product?.price === 'object' // products from algolia have price as an object
        ? product.price?.value
        : parsePrice(product.price || product.basePrice),
      brand: product.brand ? product.brand.title : product.manufacturer,
      quantity: product?.quantity || 1
    }))
  },
  _event (eventName, payload) {
    if (settings.services.gtmId && !isBot) {
      try {
        window.dataLayer.push({
          event: eventName,
          ...payload
        })
      } catch (e) {
        console.error(e)
      }
    }
  },

  login () {
    this._event('login', {
      method: 'In-Site'
    })
  },

  signUp () {
    this._event('sign_up', {
      method: 'In-Site'
    })
  },

  logout () {
    this._event('logout', {
      reason: 'Manual'
    })
  },

  update (isEditAddress) {
    this._event('account_update', {
      edited: isEditAddress ? 'Shipping address' : 'Personal info'
    })
  },

  addToCart (product) {
    this._event('add_to_cart', {
      items: this._formatProducts([product])
    })
  },

  removeFromCart (products) {
    this._event('remove_from_cart', {
      items: this._formatProducts(products)
    })
  },

  addToWishlist (product) {
    this._event('add_to_wishlist', {
      items: this._formatProducts([product])
    })
  },

  removeFromWishlist (product) {
    this._event('remove_from_wishlist', {
      items: this._formatProducts([product])
    })
  },

  viewProduct (product) {
    this._event('view_item', {
      items: this._formatProducts([product])
    })
  },

  purchase (order, products) {
    products = this._formatProducts(products)
    this._event('purchase', {
      transaction_id: order.id,
      value: order.revenue,
      currency: 'USD',
      tax: order.tax,
      shipping: order.shipping,
      items: [...products]
    })
  },

  search (query) {
    this._event('search', {
      search_term: String(query)
    })
  },

  sort (sortOption) {
    this._event('sort_filter', {
      sort_option: sortOption
    })
  }
}
export default AnalyticsService
