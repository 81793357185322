import { defineStore } from 'pinia'

import { userStore } from '@/stores/user'

const defaultState = {
  step: 'auth',
  savedUserData: null
}

// eslint-disable-next-line no-unused-vars
const _steps = [
  'auth', // skip for registered user
  'shipping',
  'deliveryMode', // skip for registered user
  'payment'
]

const state = { ...defaultState }

const mutations = {
  SET_STEP (step) {
    this.step = step
  },
  SAVE_USER_INPUT (data) {
    this.savedUserData = data
  },
  RESET_USER_INPUT () {
    this.savedUserData = defaultState.savedUserData
  }
}

const actions = {
  ...mutations,
  resetCheckoutFlow () {
    this.SET_STEP(userStore().isGuest ? 'auth' : 'shipping')
  }
}

export const checkoutFlowStore = defineStore('checkoutFlow', {
  persist: {
    paths: ['step', 'savedUserData']
  },
  state: () => ({
    ...state
  }),
  actions,
  share: {
    enable: true,
    initialize: true
  }
})
