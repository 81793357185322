<template>
  <div class="modal-body-inner modal-body-inner__account">
    <div class="modal-body__col">
      <authentication @authenticated="closeModal" />
    </div>
    <div class="modal-body__col">
      <div class="modal-body__separator">
        <span>or</span>
      </div>

      <div class="modal-body__text">
        <p class="modal-body__new-user">
          New User?
        </p>
        <h4 class="modal-body__title">
          Sign Up Today!
        </h4>
      </div>
      <button
        class="btn"
        @click="changeModal"
      >
        Create account
      </button>
    </div>
  </div>
</template>

<script>
import Authentication from '@/components/auth/Authentication'

export default {
  name: 'AccountModalSignIn',
  components: {
    Authentication
  },
  methods: {
    changeModal () {
      this.$parent.$emit('changeModal', 'signUp')
    },
    closeModal () {
      this.$parent.$emit('closeModal')
    }
  }
}
</script>
