<template>
  <section class="header-promo">
    <div
      class="header-topbar"
      :class="{'header-topbar--no-promo' : !isPromoExist}"
    >
      <div class="container container--page">
        <div class="header-topbar-inner">
          <div
            v-if="isPromoExist"
            class="header-notifications header-link"
          >
            <base-icon
              class="header-link__icon"
            >
              <ico-bell />
            </base-icon>
            <p id="header-link-label">
              {{ getHeaderPromo.promo }}
            </p>
            <router-link
              v-if="getHeaderPromo.btnLink"
              class="header-notifications__link"
              :to="getHeaderPromo.btnLink.url"
              aria-labelledby="header-link-label"
            >
              {{ getHeaderPromo.btnLink.title }}
              <img
                src="@/assets/icons/arrow.svg"
                alt="arrow right"
                loading="lazy"
              >
            </router-link>
          </div>
          <div class="header-topbar__block">
            <div class="header-topbar__locations">
              <router-link
                v-if="!isStoreLocation"
                to="/store-finder"
                class="header-link"
              >
                <img
                  src="@/assets/icons/location.svg"
                  class="header-link__icon"
                  alt="location"
                  loading="lazy"
                >
                {{ location || 'Find A Location' }}
              </router-link>
              <app-header-location-picklist v-else />
            </div>
            <div class="header-topbar__login">
              <div v-if="isGuest">
                <a
                  href="#"
                  class="header-link"
                  role="button"
                  @click.prevent="showModalAccount = true"
                >
                  <img
                    src="@/assets/icons/account.svg"
                    class="header-link__icon"
                    alt="account"
                    loading="lazy"
                  >
                  Sign In
                </a>
              </div>
              <app-header-account-picklist
                v-else
                @loading="(boolean) => isLoading = boolean"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showModalAccount"
      :title="modal.title"
      class="modal--scroll-y"
      @changeModal="changeModal"
      @closeModal="closeModal"
      @sign-up="employeePendingCheck"
    >
      <component :is="modal.state" />
    </modal>
  </section>
</template>

<script>
import Modal from '@/components/Modal'
import SignIn from '@/components/account/AccountSignIn'
import SignUp from '@/components/account/AccountSignUp'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import LoaderMixin from '@/mixins/LoaderMixin'
import AppHeaderLocationPicklist from '@/components/AppHeaderLocationPicklist'
import AppHeaderAccountPicklist from '@/components/AppHeaderAccountPicklist'
import BaseIcon from '@/components/BaseIcon'
import IcoBell from '@/assets/iconComponents/IcoBell'
import { mapGetters, mapState } from 'pinia'
import { contentStore, userStore } from '@/stores'

export default {
  name: 'AppHeaderPromo',
  components: {
    AppHeaderLocationPicklist,
    AppHeaderAccountPicklist,
    Modal,
    SignIn,
    SignUp,
    BaseIcon,
    IcoBell
  },
  mixins: [ScreenWidthMixin, LoaderMixin],
  data () {
    return {
      modal: {
        state: 'signIn',
        title: 'Sign In'
      },
      showModalAccount: false
    }
  },
  computed: {
    ...mapGetters(userStore, ['isGuest', 'isStoreLocation']),
    ...mapState(userStore, [
      'location',
      'userData',
      'isEmployeeConfirmationPending',
      'isEmployeeConfirmationPendingText'
    ]),
    ...mapGetters(contentStore, ['getHeaderPromo']),
    getFlyoutData () {
      return this.flyoutData || {}
    },
    isPromoExist () {
      return this.getHeaderPromo?.promo
    }
  },
  methods: {
    changeModal (val) {
      this.modal.state = val
      switch (val) {
        case 'signIn':
          this.modal.title = 'Sign In'
          break
        case 'signUp':
          this.modal.title = 'Create Account'
          break
        default:
          this.modal.title = ''
      }
    },
    closeModal () {
      this.showModalAccount = false
      this.modal.state = 'signIn'
      this.modal.title = 'Sign In'
    },
    employeePendingCheck () {
      if (this.isEmployeeConfirmationPending && this.isEmployeeConfirmationPendingText) {
        this.closeModal()
      }
    }
  }
}
</script>
