import { transformCatalogEntry } from '@/transformers'
import { defineStore } from 'pinia'

const state = {
  catalogsData: null
}

const mutations = {
  SET_CATALOGS_DATA (data) {
    this.catalogsData = data
  }
}

const actions = {
  ...mutations,
  setCatalogsData (contentfulCatalogs, facets) {
    const grouped = Object.keys(facets)
      .filter((facetKey) => /^(category|brand)/.test(facetKey))
      .map(catalogGroup => {
        return {
          groupTitle: catalogGroup,
          catalogs: Object.keys(facets[catalogGroup])
            .map(value => transformCatalogEntry({
              value,
              catalogGroup,
              contentfulCatalogs
            }))
        }
      })

    // @TODO refactor the tree constructor
    grouped.forEach((catalogGroup) => {
      const [nestTitle, nestIdxStr] = catalogGroup.groupTitle.split('.lvl')
      const nestIdxInt = parseInt(nestIdxStr)
      if (nestIdxInt) {
        const parentFacet = grouped.find(el => el.groupTitle === nestTitle + '.lvl' + (nestIdxInt - 1))
        parentFacet.catalogs.forEach(parentValue => {
          parentValue.subValues = catalogGroup.catalogs
            .filter(el =>
              el.originalTitle.split(' > ').slice(0, -1).join(' > ') === parentValue.originalTitle
            )
          if (!parentValue.subValues.length) delete parentValue.subValues
        })
      }
    })

    const catalogs = grouped.map(el => el.catalogs).flat()

    this.SET_CATALOGS_DATA(catalogs)
  }
}

export const catalogsStore = defineStore('catalogs', {
  state: () => ({
    ...state
  }),
  actions
})
