import ApiBase from '@/services/z-api/v1/api-base'

class Cart extends ApiBase {
  constructor (transport) {
    super(transport)
    this.createNewCart = this.createNewCart.bind(this)
    this.getCart = this.getCart.bind(this)
    this.validateCart = this.validateCart.bind(this)
    this.mergeCart = this.mergeCart.bind(this)
    this.addProduct = this.addProduct.bind(this)
    this.updateProductQty = this.updateProductQty.bind(this)
    this.deleteProduct = this.deleteProduct.bind(this)
    this.deleteCart = this.deleteCart.bind(this)
    this.setGuestUser = this.setGuestUser.bind(this)
    this.getDeliveryModes = this.getDeliveryModes.bind(this)
    this.setDeliveryMode = this.setDeliveryMode.bind(this)
    this.setShippingAddress = this.setShippingAddress.bind(this)
    this.placeOrder = this.placeOrder.bind(this)
    this.applyVoucher = this.applyVoucher.bind(this)
    this.deleteVoucher = this.deleteVoucher.bind(this)
    this.setShippingAddressFromAddressBook = this.setShippingAddressFromAddressBook.bind(this)
  }

  /**
   * Create new cart
   * @param {string} userId
   */
  createNewCart (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId(userId)}/carts`, {})
    })
  }

  /**
   * Return cart info, also creates and returns a new cart if there are no existing carts
   * @param {boolean} recalculate? if the cart needs to be re-calculated on the BE side to align the total and taxes
   */
  getCart (recalculate) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId()}/carts/current${recalculate ? '?recalculate=true' : ''}`)
    })
  }

  /**
   * Validate cart, validates if items are in stock precisely
   * @param {string} userId
   * @param {string} cartId
   */
  validateCart (userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/validateForCheckout`)
    })
  }

  /**
   * Merges user's cart with guest cart so the items will be from both carts
   * @param {Object} data
   * @param {string} data.oldCartId user's cart guid with items which have been added before authorization
   * @param {string} data.toMergeCartGuid user's last cart guid from the previous session
   */
  mergeCart (data, userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId(userId)}/carts`, data)
    })
  }

  /**
   * Add product to cart
   * @param {Object} data
   * @param {string} data.productId
   * @param {number} data.quantity
   * @param {string} data.pickupStore
   * @param {string} userId
   * @param {string} cartId
   */
  addProduct (data, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/entries`, data)
    })
  }

  /**
   * Update product quantity in the cart
   * @param {string} entryId
   * @param {Object} data
   * @param {number} data.quantity
   * @param {string} data.pickupStore
   * @param {string} userId
   * @param {string} cartId
   */
  updateProductQty (entryId, data, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/entries/${entryId}`, data)
    })
  }

  /**
   * Delete product from cart
   * @param {string} entryId
   * @param {string} userId
   * @param {string} cartId
   */
  deleteProduct (entryId, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/entries/${entryId}`)
    })
  }

  /**
   * Delete cart
   * @param {'isSTHCart'|'isBOPISCart'} query? is a flag to remove only specific type of products(BOPIS or STH)
   */
  deleteCart (query) {
    return this._withDecoratedPromise(() => {
      const queryToUrl = query ? `?${query}=true` : ''
      return this.transport.delete(`/rest/api/v2/users/${this._getUserId()}/carts/current${queryToUrl}`)
    })
  }

  /**
   * Set Guest Email and cart
   * @param {Object} data
   * @param {string} data.firstName
   * @param {string} data.lastName
   * @param {string} data.email
   * @param {string} data.phone
   * @param {string} userId
   * @param {string} cartId
   * @returns {Promise<Object>} note uid in the returned object is a combination of user email and cart guid CART_GUID|USER_EMAIL
   */
  setGuestUser (data, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/register/guest`, data)
    })
  }

  /**
   * Get delivery modes
   * @param {string} userId
   * @param {string} cartId
   */
  getDeliveryModes (userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/deliverymodes`)
    })
  }

  /**
   * Set delivery mode
   * @param {Object} data
   * @param {string} data.deliveryModeId
   * @param {string} userId
   * @param {string} cartId
   */
  setDeliveryMode (data, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.put(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/deliverymode`, data)
    })
  }

  /**
   * Set delivery address
   * @param {Object} data
   * @param {string} data.addressId
   * @param {string} userId
   * @param {string} cartId
   */
  setShippingAddress (data, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/addresses/delivery`, data)
    })
  }

  /**
   * Set delivery address from address book with its id
   * @param {string} addressId address book's id
   */
  setShippingAddressFromAddressBook (addressId) {
    return this._withDecoratedPromise(() => {
      return this.transport
        .put(`/rest/api/v2/users/${this._getUserId()}/carts/current/addresses/delivery`,
          { addressId })
    })
  }

  /**
   * Place order
   * @param {Object} data
   * @param {string} data.cardHolderName
   * @param {string} data.cardNumber
   * @param {string} data.cvv
   * @param {string} data.expiryMonth
   * @param {string} data.expiryYear
   * @param {Object} data.billingAddress
   * @param {string} userId
   * @param {string} cartId
   * @param {string} data.guid
   */
  placeOrder ({ guid, ...restData }, userId, cartId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId(userId)}/carts/current/placeOrder`,
        restData,
        {
          'directToolsOutlet-cart': guid
        })
    })
  }

  /**
   * Apply promo code/coupon/voucher
   * @param {string} voucherId
   */
  applyVoucher (voucherId) {
    return this._withDecoratedPromise(() => {
      return this.transport.post(`/rest/api/v2/users/${this._getUserId()}/carts/current/vouchers`, { voucherId })
    })
  }

  /**
   * Delete promo code/coupon/voucher
   * @param {string} voucherId
   */
  deleteVoucher (voucherId) {
    return this._withDecoratedPromise(() => {
      return this.transport.delete(`/rest/api/v2/users/${this._getUserId()}/carts/current/vouchers/${voucherId}`)
    })
  }
}

export default Cart
