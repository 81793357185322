export default {
  computed: {
    isDesktop () {
      return (this.screenWidth === null ? window.innerWidth : this.screenWidth) > 1180
    },
    isTablet () {
      return (this.screenWidth === null ? window.innerWidth : this.screenWidth) <= 1180
    },
    isMobile () {
      return (this.screenWidth === null ? window.innerWidth : this.screenWidth) <= 767
    }
  },
  methods: {
    calculateScreenWidth () {
      this.screenWidth = window.innerWidth
    }
  },
  created () {
    this.screenWidth = window.innerWidth
    this.$nextTick(function () {
      window.addEventListener('resize', this.calculateScreenWidth)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.calculateScreenWidth)
  },
  data () {
    return {
      screenWidth: null
    }
  }
}
