<template>
  <div id="app">
    <template v-if="!isMaintenanceTime">
      <a-v-s-modal v-if="modal.isVisible" />
      <single-cart-type-notification />
      <find-location-modal v-if="locationModal && locationModal.opened" />
      <add-product-modal v-if="addProductData" />
      <promo-modal v-if="potentialPromoModalIsVisible" />
      <product-warning-modal v-if="isProductWarningModalVisible" />
      <template v-if="$route.name === 'Checkout'">
        <router-view :key="$route.path" />
        <loader
          v-if="isGlobalLoaderActive"
          style="position: fixed; z-index: 9999"
        />
      </template>
      <template v-else>
        <transition-group
          class="header-spacer"
          role="banner"
          name="appear"
          tag="header"
        >
          <template v-if="isInitialized && isInitialized.resolved">
            <div key="secondary-header">
              <close-facets
                v-if="!isDesktop && $route.name === 'Search' && isFacetsVisible"
              />
              <app-header-promo v-else />
            </div>
            <app-header key="app-header" />
          </template>
        </transition-group>
        <router-view
          id="main-content"
          :key="$route.name !== 'ProductDetails' ? $route.path : ''"
          role="main"
          tag="main"
        >
          <template v-slot:crumbs>
            <crumbs :crumbs-data="crumbsData" />
          </template>
        </router-view>
        <loader
          v-if="isGlobalLoaderActive"
          style="position: fixed; z-index: 9999"
        />
        <async-component-wrapper
          v-if="isInitialized && isInitialized.resolved"
          :collapsed-style="{
            height: isMobile ? '1150px' : isTablet ? '1025px' : '587px',
            marginTop: isMobile ? '90px' : '150px',
            backgroundColor: '#000'
          }"
          :component="'app-footer'"
        />
      </template>
    </template>
    <stub-app v-else-if="isMaintenanceTime" />
  </div>
</template>

<script>
import AppHeaderPromo from '@/components/AppHeaderPromo'
import AppHeader from '@/components/AppHeader'
import Crumbs from '@/components/Crumbs'
import SingleCartTypeNotification from '@/components/SingleCartTypeNotification'
import AsyncComponentWrapper from '@/components/AsyncComponentWrapper.vue'
import ScreenWidthMixin from '@/mixins/ScreenWidthMixin'
import { mapActions, mapState } from 'pinia'
import { appStore, cartStore, maintenanceStore, searchStore, useCrumbs } from '@/stores'
import { handleChunkLoadingError } from '@/helpers'

export default {
  name: 'App',
  metaInfo: {
    title: process.env.VUE_APP_TITLE,
    titleTemplate: `%s | ${process.env.VUE_APP_TITLE}`
  },
  components: {
    'close-facets': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'close-facets' */'@/CloseFacets')
    ),
    'product-warning-modal': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'product-warning-modal' */'@/views/ProductWarningModal')
    ),
    AsyncComponentWrapper,
    'stub-app': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'stub-app' */'@/StubApp')
    ),
    'find-location-modal': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'find-location-modal' */'@/components/FindLocationModal')
    ),
    SingleCartTypeNotification,
    'add-product-modal': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'add-product-modal' */'@/components/cart/AddProductModal')
    ),
    'a-v-s-modal': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'a-v-s-modal' */'@/components/AVSModal')
    ),
    'promo-modal': () => handleChunkLoadingError(
      () => import(/* webpackChunkName: 'promo-modal' */'@/components/cart/PromoModal')
    ),
    AppHeader,
    Crumbs,
    AppHeaderPromo
  },
  mixins: [ScreenWidthMixin],
  computed: {
    ...mapState(searchStore, ['locationModal', 'isFacetsVisible']),
    ...mapState(appStore, ['modal']),
    ...mapState(cartStore, [
      'addProductData',
      'potentialPromoModalIsVisible',
      'isProductWarningModalVisible'
    ]),
    ...mapState(maintenanceStore, ['isMaintenanceTime']),
    ...mapState(useCrumbs, ['crumbsData']),
    ...mapState(appStore, ['overflowHidden', 'isGlobalLoaderActive', 'isInitialized'])
  },
  watch: {
    overflowHidden (val) {
      if (val) {
        document.body.classList.add('overflow-hidden')
      } else {
        document.body.classList.remove('overflow-hidden')
      }
    }
  },
  created () {
    this.setAppData()
  },
  methods: {
    ...mapActions(appStore, ['setAppData'])
  }
}

</script>

<style lang="scss" >
.header-spacer {
  position: sticky;
  top: -$header-promo-height;
  display: block;
  min-height: calc($header-height + $header-promo-height);
  z-index: 10;

  @include media-tablet-desktop {
    min-height: calc($header-height-desktop + $header-promo-height);
  }
}
</style>
