<template>
  <div class="mob-menu">
    <div class="mob-menu-header">
      {{ title }}

      <button
        class="hamburger-btn hamburger-btn--cross"
        aria-label="Close Menu"
        @click="closeMenu"
      >
        <div class="hamburger-btn-box">
          <div class="hamburger-btn-inner" />
        </div>
      </button>
    </div>
    <div class="mob-menu-body">
      <ul
        v-if="!showSubMenu"
        class="mob-menu-list"
      >
        <template v-for="(link, index) in links">
          <li
            v-if="link.megaMenu"
            :key="'mob-menu'+link.id"
            @click="openSub(link.title, index)"
          >
            {{ link.title }}
          </li>
          <li
            v-else
            :key="'mob-menu'+link.id"
          >
            <router-link
              :to="link.url"
              @click.native="closeMenu"
            >
              {{ link.title }}
            </router-link>
          </li>
        </template>
      </ul>
      <sub-menu
        v-if="showSubMenu && links[currentSubMenu].megaMenu"
        :data="links[currentSubMenu].megaMenu.subMenus || links[currentSubMenu].megaMenu"
        :depth="depth + 1"
        :parent-link="links[currentSubMenu]"
        @changeTitle="changeTitle"
        @closeSub="closeParentSub"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import subMenu from './MobMenuSub'

export default {
  components: {
    subMenu
  },
  props: {
    links: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      title: 'Menu',
      showSubMenu: false,
      currentSubMenu: 0,
      depth: 0,
      titles: ['Menu']
    }
  },
  methods: {
    closeMenu () {
      this.$emit('closeMobMenu')
    },
    openSub (title, index) {
      this.titles.push(title)
      this.title = title
      this.showSubMenu = true
      this.currentSubMenu = index
    },
    closeParentSub (childDepth) {
      if (childDepth === 1) {
        this.showSubMenu = false
        this.titles.pop()
        this.title = this.titles[this.titles.length - 1]
      }
    },
    changeTitle (val) {
      if (val) {
        this.titles.push(val)
        this.title = val
      } else {
        this.titles.pop()
        this.title = this.titles[this.titles.length - 1]
      }
    }
  }
}
</script>
