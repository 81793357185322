import Vue from 'vue'
import App from './App'
import './registerServiceWorker'
import router from './router'
import './router/routerPush'
import pinia, { cartStore } from '@/stores'
import Loader from '@/components/Loader'
import '@/styles/app.scss'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'
import { OptimizelyService } from '@/services/optimizely'
import sentry from '@/services/sentry'
import AnalyticsService from '@/services/analytics'
import { RiskifiedService } from '@/services/riskified'
import settings from '@/settings'
import { intersectionObserver } from '@/directives/intersectionObserver'
import { Deferred } from '@/helpers'

// eslint-disable-next-line no-unused-expressions
document.querySelector('noscript')?.remove() // remove noscript from crawlers if Javascript works
Vue.config.productionTip = false
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(Toasted, { className: 'toast', duration: 5000, keepOnHover: true })
Vue.component('loader', Loader)
Vue.directive('intersectionObserver', intersectionObserver)

export const rootAppCreated = new Deferred()
export const app = new Vue({
  router,
  pinia,
  created () {
    rootAppCreated.resolve()
  },
  render: h => h(App)
}).$mount('#app')

RiskifiedService.init(cartStore().cartGuid)
sentry.init()
OptimizelyService.init()
AnalyticsService.init()

console.log(`🛒 zCommerce DTFO v${settings.app.version}`)
