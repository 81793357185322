<template>
  <div class="address-group">
    <p
      v-if="data.line1"
      class="address-group__item"
    >
      {{ data.line1 }}
    </p>
    <p
      v-if="data.line2"
      class="address-group__item"
    >
      {{ data.line2 }}
    </p>
    <p class="address-group__item">
      {{ data.city }}, {{ data.state }} {{ data.zip }}
    </p>
    <p
      v-if="data.phone"
      class="address-group__item"
    >
      <a
        class="address-group__phone"
        :href="`tel:${data.phone}`"
      >
        {{ data.phone }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'AddressGroup',
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
