<template>
  <div
    class="loader"
    :class="{'loader--full': full}"
  >
    <div class="lds-dual-ring" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    full: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss" scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: auto;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  z-index: 10;

  &--full {
    position: fixed;
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;

    &::after {
      content: ' ';
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid;
      border-color: #808080 transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }

    @keyframes lds-dual-ring {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}

</style>
