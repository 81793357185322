import ApiBase from '@/services/z-api/v1/api-base'
import { appStore, userStore } from '@/stores'

class Auth extends ApiBase {
  constructor (transport, apiKey) {
    super(transport)
    this.apiKey = apiKey
    this.initSession = this.initSession.bind(this)
    this.validateApiKey = this.validateApiKey.bind(this)
    this.login = this.login.bind(this)
    this.signUp = this.signUp.bind(this)
    this.getUserData = this.getUserData.bind(this)
    this.validateEmployeeEmail = this.validateEmployeeEmail.bind(this)
  }

  /**
   * @typedef LoginResponse
   * @property {string} userId True if the token is valid.
   * @property {string} userName The user id bound to the token.
   * @property {string} userEmail The user id bound to the token.
   */

  /**
   * allows to verify the user's credentials
   * @param {string} credentials.username The name of the user.
   * @param {string} credentials.password The email of the user.
   * @returns {Promise<LoginResponse>}
   */
  login (credentials) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v2/users/authenticate', credentials)
    })
  }

  /**
   * @typedef SignUpResponse
   * @property {string} email
   * @property {string} firstName
   * @property {boolean} subscriptionStatus
   * @property {string} lastName
   * @property {string} password
   * @property {string} uid
   */

  /**
   * allows to verify the user's credentials
   * @param {string} data.email
   * @param {string} data.firstName
   * @param {boolean} data.subscriptionStatus
   * @param {string} data.lastName
   * @param {string} data.password
   * @param {string} data.uid
   * @param {string} data.orderGuid
   * @returns {Promise<SignUpResponse>}
   */
  signUp (data) {
    return this._withDecoratedPromise(async () => {
      // userRole could be either anonymous or a registered guest
      const userRole = userStore().isRegisteredGuest
        ? encodeURIComponent(userStore().userId)
        : 'anonymous'
      await appStore().setLastSessionInit(new Date())
      return this.transport.post(`/rest/api/v2/users/${userRole}/register`, data)
    })
  }

  /**
   * gets user's data by user id
   * @param {string} userId user's id
   * @returns {Promise<Object>}
   */
  getUserData (userId) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v1/users/${this._getUserId(userId)}/`)
    })
  }

  /**
   * Performs a logout request.
   * @returns {Promise<void>}
   */
  logout () {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/j_spring_security_logout',
        {}, {}, {}, { checkSession: false })
    })
  }

  async initSession () {
    return this._withDecoratedPromise(async () => {
      const response = await this.validateApiKey()
      await appStore().setLastSessionInit(new Date())
      return response
    })
  }

  validateApiKey () {
    const data = `username=${this.apiKey}`
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    return this.transport.post('/j_spring_security_check', data, headers, {}, { checkSession: false })
  }

  checkResetPasswordLinkExpiration (token) {
    return this._withDecoratedPromise(() => {
      return this.transport.get(`/rest/api/v2/password/checktoken?token=${token}`)
    })
  }

  /**
   * Validate employee email
   * @param {Object} data
   * @param {string} data.email
   * @param {string} data.token
   */

  validateEmployeeEmail (data) {
    return this._withDecoratedPromise(() => {
      return this.transport.post('/rest/api/v2/users/validateRegistration', data)
    })
  }
}

export default Auth
