import { defineStore } from 'pinia'

import { catalogsStore } from '@/stores/catalogs'

const state = {
  pageData: null,
  relatedCatalogs: null
}

const mutations = {
  SET_CATALOG_DATA (data) {
    this.pageData = data
  },
  SET_RELATED_CATALOGS (data) {
    this.relatedCatalogs = data
  },
  UNSET_CATALOG_DATA () {
    this.pageData = null
    this.relatedCatalogs = null
  }
}

const actions = {
  ...mutations,
  setCatalogData (id) {
    const catalog = catalogsStore().catalogsData.find(catalog => id.toLowerCase() === catalog.key || id.toLowerCase() === catalog.title.toLowerCase())

    if (catalog) {
      let relatedCatalogs = []
      if (catalog.subValues) {
        relatedCatalogs = catalog.subValues.filter(el => el.image)
      } else {
        const parentKey = catalog.parentKeys[catalog.parentKeys.length - 2]
        if (parentKey) {
          relatedCatalogs = catalogsStore().catalogs.catalogsData.find(cat => parentKey.toLowerCase() === cat.key).subValues.filter(el => el.image && el.key !== catalog.key)
        }
      }

      this.SET_RELATED_CATALOGS(relatedCatalogs)
      this.SET_CATALOG_DATA(catalog)
    }
  }
}

export const catalogStore = defineStore('catalog', {
  state: () => ({
    ...state
  }),
  actions
})
