export const threshold = 0.5
export const intersectionObserver = {
  bind: function (el, callback) {
    if (window && 'IntersectionObserver' in window) {
      // eslint-disable-next-line no-new
      const observer = new IntersectionObserver(
        callback.value,
        {
          rootMargin: '0px',
          threshold
        }
      )
      observer.observe(el)
    }
  }
}
