import AlgoliaService from '@/services/algolia'
import { transformProducts } from '@/transformers'
import { alignPrices } from '@/helpers/vuexHelpers'
import { defineStore } from 'pinia'

const state = {
  featuredProductsData: null
}

const mutations = {
  SET_FEATURED_PRODUCTS_DATA (data) {
    this.featuredProductsData = Object.freeze(data)
  }
}

const actions = {
  ...mutations,
  async setFeaturedProductsData (filters) {
    const params = {
      filters,
      facets: '*'
    }
    const res = await AlgoliaService.getProductIndex().search('', params)
    let transformedProducts = transformProducts(res.hits)
    transformedProducts = await alignPrices(transformedProducts)

    this.SET_FEATURED_PRODUCTS_DATA(transformedProducts)
  }
}

export const featuredProductsStore = defineStore('featuredProducts', {
  state: () => ({
    ...state
  }),
  actions
})
