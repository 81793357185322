import axios from 'axios'
import { appStore } from '@/stores'

class Transport {
  constructor (baseUrl) {
    this.baseUrl = baseUrl
  }

  /**
   * Get an absolute API URL
   * @param endpoint
   * @returns {string}
   */
  getApiUrl (endpoint) {
    return `${this.baseUrl}${endpoint}`
  }

  /**
   * Get HTTP headers for API request
   * @returns {{'Content-Type': string}}
   */
  getHeaders () {
    const headers = {
      'Content-Type': 'application/json'
    }
    return headers
  }

  /**
   * Perform a request.
   * @param method
   * @param endpoint
   * @param data
   * @param headers
   * @returns {Promise<any>}
   */
  async request (method, endpoint, data, headers = {}, requestOptions = {}, transportOptions = {}) {
    if (transportOptions.checkSession !== false) await appStore().checkSession()
    return new Promise((resolve, reject) => {
      try {
        headers = { ...this.getHeaders(), ...headers }
        const options = {
          url: this.getApiUrl(endpoint),
          withCredentials: true,
          method,
          data,
          headers,
          ...requestOptions
        }
        axios(options)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } catch (e) {
        reject(e)
      }
    })
  }

  /**
   * POST request.
   * @param endpoint
   * @param data
   * @param headers
   * @returns {*|Promise<any>}
   */
  post (endpoint, data, headers, requestOptions, transportOptions) {
    return this.request('post', endpoint, data, headers, requestOptions, transportOptions)
  }

  /**
   * DELETE request.
   * @param endpoint
   * @param data
   * @param headers
   * @returns {*|Promise<any>}
   */
  delete (endpoint, data, headers, requestOptions, transportOptions) {
    return this.request('delete', endpoint, data, headers, requestOptions, transportOptions)
  }

  /**
   * GET request.
   * @param endpoint
   * @param data
   * @param headers
   * @returns {*|Promise<any>}
   */
  get (endpoint, data, headers, requestOptions, transportOptions) {
    return this.request('get', endpoint, data, headers, requestOptions, transportOptions)
  }

  /**
   * PATCH request.
   * @param endpoint
   * @param data
   * @param headers
   * @returns {*|Promise<any>}
   */
  patch (endpoint, data, headers, requestOptions, transportOptions) {
    return this.request('patch', endpoint, data, headers, requestOptions, transportOptions)
  }

  /**
   * PUT request.
   * @param endpoint
   * @param data
   * @param headers
   * @returns {*|Promise<any>}
   */
  put (endpoint, data, headers, requestOptions, transportOptions) {
    return this.request('put', endpoint, data, headers, requestOptions, transportOptions)
  }
}

export default Transport
