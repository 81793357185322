<script>
export default {
  name: 'IcoCart',
  render () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 27.197 21.277"
        role="presentation"
      >
        <g>
          <polyline
            points="0 1 3.943 1 3.943 17.415 27.197 17.415"
            fill="none"
            stroke="#646464"
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <rect
            x="6.966"
            y="2.042"
            width="7.53"
            height="12.336"
            rx="1"
            fill="#646463"
          />
          <rect
            x="16.587"
            y="9.299"
            width="8.759"
            height="5.079"
            rx="1"
            fill="#646463"
          />
          <rect
            x="16.587"
            y="2.103"
            width="6.339"
            height="5.048"
            rx="1"
            fill="#646463"
          />
          <circle
            cx="8.61"
            cy="19.507"
            r="1.77"
            fill="#646463"
          />
          <circle
            cx="21.243"
            cy="19.507"
            r="1.77"
            fill="#646463"
          />
        </g>
      </svg>
    )
  }
}
</script>
