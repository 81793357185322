<template>
  <div class="mob-menu-subs">
    <button
      v-if="!showSubMenu"
      class="mob-menu-back"
      @click="closeParentSub(depth)"
    >
      Back
    </button>
    <router-link
      v-if="!showSubMenu && parentLink"
      :to="parentLink.url"
      @click.native="closeMenu"
    >
      {{ parentLink.title }}
    </router-link>
    <ul
      v-if="!showSubMenu"
      :class="{'mob-menu-subs--child' : parentLink}"
    >
      <template v-if="data.promoItems">
        <template v-if="data.promoItems">
          <div
            v-for="(promo, index_l) in data.promoItems"
            :key="'sub-menu-promo-' + depth + promo.key + index_l"
            class="mob-menu-subs__logo"
          >
            <router-link
              v-if="promo.link"
              class="mob-menu-subs__logo-link"
              :to="promo.link.url"
              @click.native="closeMenu"
            >
              <img
                v-if="promo.image"
                :src="promo.image.url"
                :alt="promo.image.alt"
                loading="lazy"
              >
            </router-link>
            <router-link
              v-if="promo.link"
              :to="promo.link.url"
              @click.native="closeMenu"
            >
              {{ promo.link.title }}
            </router-link>
          </div>
        </template>
      </template>
      <template
        v-for="(item, index) in data"
        v-else
      >
        <li
          v-if="item.items"
          :key="'sub-menu-' + depth + index"
          v-on="!item.promoItems ? { click: () => showSub(item.productLink ? item.productLink.title : item.title, index)} : {}"
        >
          <template>
            {{ item.productLink ? item.productLink.title : item.title }}
          </template>
        </li>
        <li
          v-else
          :key="'sub-menu-' + depth + item.key"
        >
          <router-link
            v-if="checkLink(item)"
            :to="item.url || item.productLink.url"
            @click.native="closeMenu"
          >
            {{ item.title || item.productLink.title }}
          </router-link>
        </li>
      </template>
    </ul>
    <menu-sub
      v-if="!data.promoItems && data[currentSubIndex].items && showSubMenu"
      :depth="depth + 1"
      :data="data[currentSubIndex].items"
      :parent-link="data[currentSubIndex].productLink"
      v-on="$listeners"
      @closeSub="closeSub"
    />
  </div>
</template>

<script>

export default {
  name: 'MenuSub',
  props: {
    data: {
      type: [Array, Object],
      require: true,
      default: () => []
    },
    depth: {
      type: Number,
      require: true,
      default: 0
    },
    parentLink: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showSubMenu: false,
      currentSubIndex: 0,
      prevTitle: ''
    }
  },
  methods: {
    showSub (title, index) {
      this.prevTitle = title
      this.$emit('changeTitle', title)
      if (index) this.currentSubIndex = index
      this.showSubMenu = true
    },
    closeParentSub () {
      this.$emit('closeSub', this.depth)
    },
    closeSub (childDepth) {
      if (childDepth === this.depth + 1) {
        this.showSubMenu = false
        this.currentSubIndex = 0
        this.$emit('changeTitle')
      }
    },
    closeMenu () {
      this.$emit('closeMobMenu')
    },
    checkLink (item) {
      if (!item.items && item.url) {
        return true
      } else if (item.productLink?.url) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
