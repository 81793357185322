<template>
  <div
    class="form-group"
    :class="{
      'form-group--inline': model.inline,
      'form-group--disabled': model.disabled,
      'form-group--error': isValidationRun && field.value && field.value.$invalid
    }"
    :disabled="model.disabled"
  >
    <label
      v-if="model.label"
      :for="model.id"
      class="form-group__label"
    >{{ model.label }}</label>

    <the-mask
      v-if="model.inputType === 'the-mask'"
      :id="model.id"
      v-model="model.value"
      :aria-label="model.label || model.ariaLabel"
      :mask="model.mask || dynamicMask"
      :masked="model.masked"
      class="form-group__input form-input"
      :class="{
        'form-input--disabled': model.disabled,
        'form-input--error': isValidationRun && field.value && field.value.$invalid
      }"
      :type="model.type"
      :maxlength="model.maxLength"
      :readonly="model.readonly"
      :placeholder="model.placeholder"
      :disabled="model.disabled"
      :autocomplete="model.autocomplete ? model.autocomplete : 'on'"
      @input="$emit('input', model.value)"
    />

    <template v-else-if="model.type === 'password'">
      <input
        :id="model.id"
        :aria-label="model.label || model.ariaLabel"
        class="form-group__input form-input"
        :class="{
          'form-input--disabled': model.disabled,
          'form-input--error': isValidationRun && field.value && field.value.$invalid
        }"
        :type="passwordInputType"
        :maxlength="model.maxLength"
        :readonly="model.readonly"
        :value="value"
        :placeholder="model.placeholder"
        :disabled="model.disabled"
        :autocomplete="model.autocomplete ? model.autocomplete : 'on'"
        v-on="model.onlyLetter ? { keydown: ($event) => isLetter($event)} : {}"
        @focus="toggleHint"
        @blur="toggleHint"
        @input="$emit('input', $event.target.value)"
      >
      <span
        class="form-group__view-password"
        :class="{'form-group__view-password--active': isViewPassword}"
        @click="toggleViewPassword"
      >
        <img
          src="@/assets/icons/eye.svg"
          alt="eye icon"
          loading="lazy"
        >
      </span>
    </template>

    <textarea
      v-else-if="model.type === 'textarea'"
      :id="model.id"
      :aria-label="model.label || model.ariaLabel"
      class="form-group__input form-input form-group__textarea"
      :class="{
        'form-input--disabled': model.disabled,
        'form-input--error': isValidationRun && field.value && field.value.$invalid
      }"
      :maxlength="model.maxLength"
      :readonly="model.readonly"
      :value="value"
      :placeholder="model.placeholder"
      :disabled="model.disabled"
      :autocomplete="model.autocomplete ? model.autocomplete : 'on'"
      v-on="model.onlyLetter ? { keydown: ($event) => isLetter($event)} : {}"
      @focus="toggleHint"
      @blur="toggleHint"
      @input="$emit('input', $event.target.value)"
    />

    <input
      v-else
      :id="model.id"
      :aria-label="model.label || model.ariaLabel"
      class="form-group__input form-input"
      :class="{
        'form-input--disabled': model.disabled,
        'form-input--error': isValidationRun && field.value && field.value.$invalid
      }"
      :type="model.type"
      :maxlength="model.maxLength"
      :readonly="model.readonly"
      :value="value"
      :placeholder="model.placeholder"
      :disabled="model.disabled"
      :autocomplete="model.autocomplete ? model.autocomplete : 'on'"
      v-on="model.onlyLetter ? { keydown: ($event) => isLetter($event)} : {}"
      @focus="toggleHint"
      @blur="toggleHint"
      @input="$emit('input', $event.target.value)"
    >

    <span
      v-if="model.hint"
      v-show="isHintVisible"
      class="form-group__hint"
    >
      {{ model.hint }}
    </span>

    <template v-if="isValidationRun && errors">
      <span
        v-for="(error, idx) in uniqueErrors"
        :key="idx"
        class="form-group__error"
      >
        {{ error }}
      </span>
    </template>
  </div>
</template>
<script>
import FormControlMixin from '@/mixins/FormControlMixin'
import { TheMask } from 'vue-the-mask'
import { ONLY_LETTERS_REGEX } from '@/helpers'

export default {
  components: {
    TheMask
  },
  mixins: [FormControlMixin],
  data () {
    return {
      isHintVisible: false,
      isViewPassword: false
    }
  },
  computed: {
    passwordInputType () {
      return this.isViewPassword ? 'text' : 'password'
    },
    uniqueErrors () {
      return [...new Set(this.errors)]
    }
  },
  methods: {
    isLetter (e) {
      if (ONLY_LETTERS_REGEX.test(e.key)) return true
      else e.preventDefault()
    },
    toggleHint () {
      if (this.model.hint) this.isHintVisible = !this.isHintVisible
    },
    toggleViewPassword () {
      this.isViewPassword = !this.isViewPassword
    }
  }
}
</script>
