import ContentfulService from '@/services/contentful'
import { formatPrice, formatDate, getRegion } from '@/helpers'
import marked from 'marked'
import STATES from '@/data/states.json'

const _getItemStockLevel = (entry) => {
  if (entry && 'stock' in entry) {
    const status = entry?.stock?.stockLevelStatus?.code ||
      entry?.stock?.stockStatus ||
      entry?.stock?.stockLevelStatus

    return status !== 'outOfStock'
      ? (
        entry.defaultMaxOrderQuantity ||
        // this is only for the cart entries which doesn't have defaultMaxOrderQuantity
        // should be replaced with product.defaultMaxOrderQuantity
        entry.maxOrderQuantity
      )
      : 0
  } else {
    return (entry.stockStatus || entry.stockLevelStatus) !== 'outOfStock'
      ? (
        entry.defaultMaxOrderQuantity ||
        // this is only for the cart entries which doesn't have defaultMaxOrderQuantity
        // should be replaced with product.defaultMaxOrderQuantity
        entry.maxOrderQuantity
      )
      : 0
  }
}

const getItemStockInStores = (entry) => {
  if (!entry.stockInStores) {
    return undefined
  } else {
    return entry.stockInStores.map(store => {
      const stockLevelStatus = store.stockLevelStatus?.code || store.stockLevelStatus

      return {
        ...store,
        stockLevelStatus,
        stockLevel: stockLevelStatus !== 'outOfStock' ? store.maxOrderQuantity : 0,
        maxOrderQuantity: store.maxOrderQuantity
      }
    })
  }
}

const transformLogoEntry = (entry, logoFormat = '') => {
  if (entry.fields) {
    const logo = {
      id: entry.fields.key || null,
      image: ContentfulService.extractImage(entry.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleImage')?.fields.image, logoFormat) || null,
      link: entry.fields.includes.find(el => ContentfulService.extractContentType(el) === 'particleLink')?.fields.url || null
    }
    return logo.id && logo.image && logo.link ? logo : null
  } else {
    return null
  }
}

const transformProductEntry = (entry, isShoppingListEntry, compressImage) => {
  const imageFormat = compressImage ? 'cartIcon' : 'product'
  const shoppingListProductDetails = {
    imageUrl: entry.images?.length ? entry.images.find((image) => image.format === imageFormat)?.url || entry.images[0]?.url : null,
    altText: entry.images?.length
      ? entry.images.find((image) => image.format === imageFormat)?.altText || entry.images[0]?.altText
      : entry.name,
    title: entry.name,
    pickupAvailableFlag: entry.availableForPickup
  }

  const videos = entry.videos?.fields.videos.fields.includes.map(el => ({
    id: el.fields.key,
    url: el.fields.video.fields.file.url,
    alt: el.fields.video.fields,
    type: 'video',
    preview: el.fields.preview.fields.file.url
  }))

  const images = entry.images
    .filter(el => el.format === (window.innerWidth <= 767 ? 'zoom' : 'superZoom'))
    .map(el => ({
      alt: el.altText || entry.name,
      url: el.url,
      id: el.url,
      type: 'image',
      width: window.innerWidth <= 767 ? window.innerWidth - 40 : 530
    }))

  const thumbnailImageUrl = entry.images
    .find(el => el.format === 'thumbnail' || el.format === 'cartIcon')?.url
  const media = videos ? images.concat(videos) : images

  const meta = {
    title: entry.name,
    description: entry.productHighlights?.replace(/<[^>]+>/g, '')
  }

  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    name: entry.name,
    image: thumbnailImageUrl || images?.map((image) => image.url) || [],
    description: entry.productHighlights?.replace(/<[^>]+>/g, ''),
    sku: entry.code,
    brand: {
      '@type': 'Brand',
      name: entry.manufacturer
    },
    offers: {
      '@type': 'Offer',
      priceCurrency: 'USD',
      price: entry.salePrice?.value || entry.price.value,
      itemCondition: !entry.conditionName || /blemished|new/i.test(entry.conditionName)
        ? 'NewCondition'
        : 'RefurbishedCondition',
      availability: `http://schema.org/${entry?.stock?.stockLevelStatus !== 'outOfStock'
        ? 'InStock'
        : 'OutOfStock'
      }`
    },
    url: window?.location?.origin
  }

  const extractPromoMessage = (rawPromo, messageKey) => ({
    message: rawPromo[messageKey] || null,
    link: rawPromo[`${messageKey}Link`] || null
  })

  const rawPromo = entry.potentialPromotionsDisplay
  const potentialPromo = rawPromo && rawPromo.length > 0
    ? rawPromo.map(el => ({
      messages: el.messages ? [...el.messages] : null,
      links: [extractPromoMessage(el, 'messageX'), extractPromoMessage(el, 'messageY')]
    }))
    : null

  return {
    jsonld,
    meta,
    ...shoppingListProductDetails,
    id: entry.code,
    name: entry.name,
    brand: {
      title: entry.manufacturer,
      url: entry.brandLogo
    },
    archived: entry.archived,
    categories: entry?.categories || null,
    condition: entry.conditionName ? entry.conditionName.toLowerCase() : null,
    price: formatPrice(parseFloat(entry.price.value)),
    availableForPickup: entry.availableForPickup,
    productDetails: entry.productHighlights,
    isBattery: transformStringToBoolean(entry.batteryIncluded),
    salePrice: formatPrice(parseFloat(entry.salePrice?.value)),
    potentialPromo: entry.potentialPromotionsDisplay?.length > 0 ? potentialPromo : null,
    similarProducts: entry.similarProducts?.map((product) => transformProductEntry(product)) || [],
    promotionMessage: entry.promotionMessage,
    discount: entry.discountPercentage, // this is for the topYProducts in the buy x get y modal
    images: media,
    classification: entry.classifications
      ? entry.classifications[0]?.features.map(el => ({
        name: el.name,
        value: el.featureValues[0]?.value
      }))
      : null,
    inStock: _getItemStockLevel(entry) > 0,
    stockStatus: entry.stock.stockStatus || entry.stock.stockLevelStatus,
    maxOrderQuantity: entry.defaultMaxOrderQuantity,
    technicalDocumentUrls: entry.technicalDocumentUrls,
    noFurtherDiscounts: entry.noFurtherDiscounts,
    badge: entry.productBadgesImage,
    stockInStores: isShoppingListEntry
      ? undefined
      : getItemStockInStores(entry),
    stock: {
      stockLevel: _getItemStockLevel(entry),
      stockLevelStatus: entry.stock?.stockLevelStatus,
      maxOrderQuantity: entry.defaultMaxOrderQuantity
    },
    features: entry.featuresDisplay,
    included: entry.includedDisplay,
    parentCategory: entry.parentCategory && entry.parentCategory !== '1' ? entry.parentCategory : null,
    shippingRestrictions: _extractShippingRestrictions(entry)
  }
}

const transformProducts = (products, page) => {
  return products.map(el => {
    return {
      hybrisID: el.objectID,
      id: el.modelNumber,
      title: el.title,
      imageUrl: el.images && el.images.length ? el.images[0] : null,
      altText: el.title,
      condition: el.conditionName,
      brand: {
        title: el.brand?.name,
        img: el.brand?.image
      },
      customerListPrice: formatPrice(el.customerListPrice),
      employeeListPrice: formatPrice(el.employeeListPrice),
      basePrice: formatPrice(el.basePrice),
      discount: el.hasDiscount,
      promotionMessage: el.promotionMessage ? el.promotionMessage.toUpperCase() : null,
      isBattery: transformStringToBoolean(el.isBatteryIncluded),
      badge: el.badge && el.badge.length > 0 ? el.badge.map(el => el.code) : null,
      noFurtherDiscounts: el.noFurtherDiscounts,
      pickupAvailableFlag: el.deliveryType.includes('Store Pickup'),
      maxOrderQuantity: el.defaultMaxOrderQuantity,
      stock: {
        stockLevel: _getItemStockLevel(el),
        stockLevelStatus: el.stock?.stockLevelStatus?.code,
        maxOrderQuantity: el.stock?.maxOrderQuantity
      },
      stockInStores: getItemStockInStores(el),
      archived: el.archived,
      shippingRestrictions: _extractShippingRestrictions(el),
      page
    }
  })
}

const _extractShippingRestrictions = (product) => {
  if (product.shippingRestrictions &&
    product.shippingRestrictions.length &&
    !product.isBOPIS
  ) {
    return product.shippingRestrictions.map((obj) => {
      return {
        ...obj,
        shortDescription: obj.shortDescription || 'Additional Shipping Fees Apply',
        message: `<p class="hint-p">${obj.message}</p>`
      }
    })
  }
  return null
}

const transformSearchFlyoutData = (data, contentPagesSuggestionsResponse = []) => {
  const products = transformProducts(data.hits)
  let suggestions
  if (data.facets['category.lvl0']) {
    suggestions = Object.keys(data.facets['category.lvl0']).map(category => ({
      title: category,
      qty: data.facets['category.lvl0'][category]
    })).sort((a, b) => b.qty - a.qty).slice(0, 7)
  }
  const nbHits = data.nbHits
  return {
    contentPagesSuggestions: transformContentPagesSuggestions(contentPagesSuggestionsResponse),
    suggestions,
    products,
    nbHits
  }
}

const transformContentPagesSuggestions = (response) => {
  if (response?.hits?.length > 5) response.hits.length = 5
  return response?.hits?.map(({ contentType, key, title }) => {
    return {
      contentType,
      key,
      title
    }
  })
}

const transformHeroBanner = el => {
  if (el.fields) {
    const getDisclaimer = entry => {
      if (entry) {
        const disclaimerCopy = entry.includes.find(el => ContentfulService.extractContentType(el) === 'particleLongText')?.fields.text
        const disclaimer = {
          title: entry.includes.find(el => ContentfulService.extractContentType(el) === 'particleText')?.fields.textString || null,
          text: disclaimerCopy ? marked.parse(disclaimerCopy) : null
        }
        return disclaimer.title && disclaimer.text ? disclaimer : null
      } else {
        return null
      }
    }
    return {
      id: el.fields.key || null,
      title: el.fields.title || null,
      body: el.fields.body ? marked.parse(el.fields.body) : null,
      banner: ContentfulService.extractImageList(el.fields.banner?.fields || el.fields) || null,
      logo: ContentfulService.extractImage(el.fields.logo?.fields.image) || null,
      button: el.fields.ctaButton?.fields || null,
      disclaimer: getDisclaimer(el.fields.disclaimer?.fields),
      isEmployee: el.fields.isEmployee
    }
  }
}

const transformCatalogContentEntry = entry => {
  const { fields } = entry
  const bannerData = fields.heroBanner ? transformHeroBanner(fields.heroBanner) : null
  return {
    meta: fields.meta ? ContentfulService.extractMeta(fields.meta) : null,
    title: fields.title ? fields.title : null,
    catalogType: fields.catalogType ? fields.catalogType.toLowerCase() : null,
    bannerData,
    image: fields.image ? ContentfulService.extractImage(fields.image) : null,
    url: fields.urlSearchLinkTarget ? fields.urlSearchLinkTarget : null
  }
}

const transformCatalogEntry = ({ value, catalogGroup, contentfulCatalogs }) => {
  const catalogTitle = value.split(' > ').slice(-1).pop()
  const catalogKey = value.toLowerCase()
  const rootGroup = catalogGroup.split('.lvl')[0]

  const contentEntry = contentfulCatalogs.find(el => el.fields?.key && el.fields.key.toLowerCase() === catalogKey)
  const transformedContent = contentEntry ? transformCatalogContentEntry(contentEntry) : null
  const catalogType = transformedContent?.catalogType ? transformedContent.catalogType : 'category'

  // @TODO exclude last key which is entry title itself, treat deps accordingly
  const parentKeys = catalogKey
    .split(' > ')
    .reduce((sum, cur, idx) => {
      const prefix = sum[idx - 1] ? sum[idx - 1] + ' > ' : ''
      return [...sum, prefix + cur]
    }, [])

  return ({
    title: transformedContent?.title || catalogTitle,
    indexTitle: `'${catalogGroup}':'${value}'`,
    type: catalogType,
    url: transformedContent?.url,
    key: catalogKey,
    parentKeys,
    isDecorated: !!transformedContent,
    originalTitle: value,
    rootGroup,
    image: transformedContent?.image,
    bannerData: transformedContent?.bannerData ? transformedContent.bannerData : null,
    meta: transformedContent?.meta
  })
}

const transformCheckoutData = data => {
  if (data) {
    const checkoutData = {}
    Object.keys(data).forEach(key => { checkoutData[key] = data[key].value })
    return checkoutData
  }
}

const transformStringToBoolean = res => {
  if (typeof res === 'boolean') {
    return res
  } else if (typeof res === 'string') {
    return /yes|true/.test(res.toLowerCase())
  } else {
    return false
  }
}

const transformCartProduct = (product, isFreePromoProduct) => {
  const promoWhereProductIsX = product?.buyXGetYPotentialPromotions
    ?.find(({ eligibleYProductQty, productY }) => eligibleYProductQty && productY)
  const promoWhereProductIsY = product?.buyXGetYPotentialPromotions
    ?.find(({ eligibleYProductQty }) => eligibleYProductQty)
  const buyXGetYPotentialPromotion = promoWhereProductIsX || promoWhereProductIsY

  return {
    entryNumber: product.entryNumber,
    parentEntryNumber: product?.parentEntryNumber,
    title: product.productName,
    id: product.sku,
    quantity: product.quantity,
    price: formatPrice(product.productPrice?.value),
    totalPrice: formatPrice(product.totalPrice?.value),
    salePrice: formatPrice(product.salePrice?.value),
    discount: product.discountPercentage,
    imageUrl: product.productImage?.url,
    altText: product.productImage?.altText || product.productName,
    condition: product.conditionName,
    brand: {
      title: product.brand?.name,
      img: product.brand?.image
    },
    isBattery: transformStringToBoolean(product.batteryIncluded),
    maxOrderQuantity: product.maxOrderQuantity, // should be replaced with product.defaultMaxOrderQuantity
    noFurtherDiscounts: product.noFurtherDiscounts,
    stockErrorMsg: '',
    promoList: product.appliedPromotions?.length ? [...new Set(product.appliedPromotions)] : null,
    giveAway: product?.giveAway,
    productY: buyXGetYPotentialPromotion?.productY,
    buyXGetYPromo: buyXGetYPotentialPromotion,
    stockInStores: getItemStockInStores(product),
    pickupAvailableFlag: product.availableForPickup,
    deliveryPointOfService: product.deliveryPointOfService,
    stock: {
      ...product.stock,
      stockLevel: _getItemStockLevel(product),
      stockLevelStatus: product?.stock?.stockLevelStatus
    },
    isBOPIS: product.isBOPIS,
    entryMessages: product.entryMessages,
    isFreePromoProduct,
    shippingRestrictions: _extractShippingRestrictions(product)
  }
}

const enrichCartItemsWithStockErrors = (entries, stockErrors) => {
  if (stockErrors && stockErrors.length) {
    entries.forEach(el => {
      const stockError = stockErrors.find(err => {
        return err.entryNumber === el.entryNumber
      })
      if (stockError) {
        el.stockErrorMsg = stockError.message
      } else {
        el.stockErrorMsg = ''
      }
    })
  }
}

const transformAppliedOrderPromotions = (entry) => {
  return entry.appliedOrderPromotions?.reduce((acc, el) => {
    if (!acc.find((promo) => promo?.promotion?.code === el?.promotion?.code)) {
      acc.push(el)
    }
    return acc
  }, []).map((el) => ({
    description: el.description
  }))
}

const transformOrderSummary = (entry) => {
  const orderPromoList = transformAppliedOrderPromotions(entry)
  const isLtl = /^(ltl|ltl-flat-rate)$/.test(entry.deliveryMode?.code || '')
  const showDashesAsDeliveryCost = entry.isBOPIS ||
    (isLtl && entry.deliveryCost?.value === 0)

  const result = {
    subTotalWithOutDiscounts: entry.totalDiscounts
      ? formatPrice(entry.subTotalWithOutDiscounts?.value || entry.subTotal?.value + entry.totalDiscounts?.value)
      : null,
    totalDiscounts: entry.totalDiscounts?.value ? formatPrice(entry.totalDiscounts?.value) : null,
    subTotal: formatPrice(entry.subTotal?.value),
    deliveryCost: showDashesAsDeliveryCost
      ? null // the order-summary shows _ _ (two dashes) if "deliveryCost" is null
      : formatPrice(entry.deliveryCost?.value),
    totalTax: formatPrice(entry.totalTax?.value),
    totalPriceWithTax: formatPrice(entry.totalPriceWithTax?.value),
    isBOPIS: entry.isBOPIS,
    orderPromoList,
    isLtl
  }

  return result
}

const transformCartEntry = (entry, isGuest, stockErrors) => {
  let entries = []
  let splitCarts = []

  if (entry.isSplitOrder) {
    splitCarts = entry.splitCarts?.map(cart => {
      const uniquePromoOrderEntryNumbers = getFreePromoProductEntryNumbersFromCart(cart)
      const cartEntries = cart.entries.map((el, idx) => {
        const isFreePromoProduct = uniquePromoOrderEntryNumbers.includes(idx)
        return transformCartProduct(el, isFreePromoProduct)
      })
      const stockErrorsList = stockErrors ? stockErrors.filter(err => err.isBOPIS === cartEntries[0].isBOPIS) : []
      enrichCartItemsWithStockErrors(cartEntries, stockErrorsList)
      return {
        ...cart,
        entries: cartEntries
      }
    })
  } else {
    const uniquePromoOrderEntryNumbers = getFreePromoProductEntryNumbersFromCart(entry)
    entries = entry.entries?.map((el, idx) => {
      const isFreePromoProduct = uniquePromoOrderEntryNumbers.includes(idx)
      return transformCartProduct(el, isFreePromoProduct)
    })
    enrichCartItemsWithStockErrors(entries, stockErrors)
  }

  const orderSummary = {
    orderPromoList: transformAppliedOrderPromotions(entry),
    summary: entry.splitCarts?.length ? entry.splitCarts.map(subCart => transformOrderSummary(subCart)) : transformOrderSummary(entry)
  }

  return {
    id: entry.id,
    guid: entry.guid,
    entries,
    totalItems: entry.totalUnitCount || entry.totalItems,
    orderSummary,
    deliveryMode: { ...entry.deliveryMode, deliveryCost: entry.deliveryCost },
    isGuestCart: isGuest,
    isSplitOrder: entry.isSplitOrder,
    splitCarts,
    appliedVouchers: entry.appliedVouchers,
    appliedProductPromotions: entry.appliedProductPromotions,
    isBOPIS: entry.isBOPIS
  }
}

const transformDeliveryModeEntry = entry => ({
  code: entry.code,
  name: entry.name || 'Pickup ',
  description: entry.description || 'Pickup in Store',
  price: entry.deliveryCost ? formatPrice(entry.deliveryCost?.value) : 'Pickup',
  label: `${entry.name || 'Pickup '} - ${formatPrice(entry.deliveryCost?.value) || 'FREE'}`,
  deliveryRateRange: entry.deliveryRateRange || ''
})

const transformFormAddress = form => ({
  firstName: form.firstName?.value,
  lastName: form.lastName?.value,
  line1: form.addressPrimary?.value,
  line2: form.addressSecondary?.value,
  town: form.city?.value,
  region: form.state?.value,
  postalCode: form.zip?.value,
  phone: form.phone?.value,
  country: { isocode: 'US' },
  saveToAddressBook: form.saveToAddressBook?.value
})

const transformFormAddressToAVS = form => ({
  ...transformFormAddress(form),
  firstName: form.firstName?.value || form.cardHolderName?.value || 'firstName', // required for AVS
  lastName: form.lastName?.value || form.cardHolderName?.value || 'lastName' // required for AVS
})

const transformUserAddress = address => ({
  firstName: address.firstName,
  lastName: address.lastName,
  line1: address.addressPrimary,
  line2: address.addressSecondary,
  town: address.city,
  region: address.state,
  postalCode: address.zip,
  phone: address.phone,
  country: { isocode: 'US' },
  defaultAddress: address.isDefault
})

const transformOrderEntry = entry => {
  const store = entry.entries[0].deliveryPointOfService
  return {
    ...transformCartEntry(entry),
    orderDetails: {
      deliveryAddress: {
        firstName: entry.deliveryAddress?.firstName,
        lastName: entry.deliveryAddress?.lastName,
        addressPrimary: entry.deliveryAddress?.line1,
        addressSecondary: entry.deliveryAddress?.line2,
        city: entry.deliveryAddress?.town,
        state: entry.deliveryAddress?.region,
        stateAbbreviation: entry.deliveryAddress?.region?.isocode.replace('US-', ''),
        zip: entry.deliveryAddress?.postalCode
      },
      paymentInfo: {
        accountHolderName: entry.paymentInfo?.accountHolderName,
        cardNumber: entry.paymentInfo?.additionalProperties.displayedCardNumber,
        cardType: entry.paymentInfo?.cardType?.code
      },
      totalItems: entry.totalItems,
      isBOPIS: entry.isBOPIS,
      store: {
        displayName: store?.displayName,
        name: store?.name,
        address: store?.address,
        hours: store?.openingHours,
        geoPoint: {
          lat: store?.geoPoint.latitude,
          long: store?.geoPoint.longitude
        }
      },
      trackingId: entry.orderTrackingId,
      deliveryRateRange: entry.deliveryMode?.deliveryRateRange
    }
  }
}

const transformSplitOrderEntry = (orders = []) => {
  const result = []
  const ordersBOPIS = orders.filter((subOrder) => subOrder.isBOPIS)
  const ordersSTH = orders.filter((subOrder) => !subOrder.isBOPIS)
  ordersSTH.forEach((subOrder) => {
    result.push(transformOrderEntry(subOrder))
  })
  ordersBOPIS.forEach((subOrder) => {
    result.push(transformOrderEntry(subOrder))
  })
  result.confirmationPageAppliedPromotions = transformAppliedOrderPromotions(
    { appliedOrderPromotions: orders.map((order) => order.appliedOrderPromotions).flat() }
  ) // a summary of sub-orders applied promotions but doesn't contain duplicated promotions
  return result
}

const transformOrderDetailsEntry = (entry) => {
  const totalPrice = entry.statusDisplay.toLowerCase() === 'cancelled' ? '$0.00' : formatPrice(entry.totalPriceWithTax?.value)
  return {
    id: entry.id,
    status: entry.statusDisplay,
    totalPrice,
    date: formatDate(entry.created),
    ...transformOrderEntry(entry)
  }
}

const transformOrdersEntry = entry => {
  return entry.orders.map(order => ({
    id: order.code,
    payment: order.paymentMethod,
    quantity: order.quantity,
    date: formatDate(order.placed),
    status: order.statusDisplay,
    total: order.total.formattedValue
  }))
}

const transformAVSAddress = (address) => {
  return {
    firstName: address.firstName,
    lastName: address.lastName,
    addressPrimary: address.line1,
    addressSecondary: address?.line2 || '',
    city: address.town,
    state: STATES.find((region) => region.isocode === address.region?.isocode),
    zip: address.postalCode,
    country: { isocode: 'US' }
  }
}

const transformAddressBookRecordToForm = (address) => {
  return {
    id: address.id,
    firstName: address.firstName,
    lastName: address.lastName,
    addressPrimary: address.line1,
    addressSecondary: address.line2,
    city: address.town,
    state: STATES.find((region) => region.isocode === address.region?.isocode),
    zip: address.postalCode,
    phone: address.phone,
    isDefault: address.defaultAddress,
    country: { isocode: 'US' }
  }
}

const extractFormAddress = form => ({
  firstName: form.firstName?.value,
  lastName: form.lastName?.value,
  addressPrimary: form.addressPrimary?.value,
  addressSecondary: form.addressSecondary?.value,
  city: form.city?.value,
  state: form.state?.value,
  zip: form.zip?.value,
  phone: form.phone?.value,
  country: { isocode: 'US' },
  isDefault: form.isDefault?.value
})

const toFeaturesCollectionGeoJSON = (stores) => {
  const result = {
    type: 'FeatureCollection',
    features: []
  }
  result.features = stores.map((store) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          store.geoPoint.longitude,
          store.geoPoint.latitude
        ]
      },
      properties: {
        url: store.url,
        zip: store.address.postalCode,
        name: store.name,
        hours: store.openingHours,
        phone: store.address.phone,
        line1: store.address?.line1,
        line2: store.address?.line2,
        city: store.address?.town,
        state: getRegion(store.address.region?.isocode),
        stateAbbreviation: store.address.region?.isocode.replace('US-', ''),
        address: store.address.formattedAddress,
        formattedDistance: store.formattedDistance,
        storeid: store.name,
        displayName: store.displayName
      }
    }
  })
  return result
}

const transformPromos = (entries, size = 4) => {
  let promos
  if (entries?.fields) {
    promos = {
      title: entries.fields.title,
      link: ContentfulService.extractEntryFromIncludesByType(entries, 'particleLink'),
      promosList: entries.fields.items
        ? entries.fields.items.map(el => {
          if (el.fields) {
            return {
              id: el.fields.key || null,
              imageList: ContentfulService.extractImageList(el.fields.banner?.fields) || null,
              link: el.fields.banner?.fields?.link?.fields.url,
              isLarge: el.fields.isLargeTile
            }
          }
          return undefined
        }).filter(el => el && el.imageList && el.link)
        : null
    }
    if (promos.promosList && promos.promosList.length >= size) {
      promos.promosList.length = size
      if (promos.promosList.find(el => el.isLarge)) promos.promosList.pop()
      if (promos.promosList[0].isLarge && promos.promosList[1].isLarge) promos.promosList.length = 2
    }
  }
  return promos
}

const transformStore = (store) => {
  return {
    address: store.address.formattedAddress,
    coords: {
      lat: store.geoPoint?.lalitude,
      lng: store.geoPoint?.longitude
    },
    details: store.url,
    distance: store.formattedDistance,
    distanceValue: store.distanceVal,
    hours: store.openingHours,
    name: store.name,
    displayName: store.displayName,
    phone: store.address.phone,
    line1: store.address?.line1,
    line2: store.address?.line2,
    city: store.address?.town,
    state: getRegion(store.address.region?.isocode),
    stateAbbreviation: store.address.region?.isocode.replace('US-', ''),
    zip: store.address.postalCode
  }
}

const extractGoogleMapsDataEntity = (googleMapsDataEntity) => {
  return {
    name: googleMapsDataEntity.getProperty('name'),
    displayName: googleMapsDataEntity.getProperty('displayName'),
    address: googleMapsDataEntity.getProperty('address'),
    zip: googleMapsDataEntity.getProperty('zip'),
    phone: googleMapsDataEntity.getProperty('phone'),
    line1: googleMapsDataEntity.getProperty('line1'),
    line2: googleMapsDataEntity.getProperty('line2'),
    city: googleMapsDataEntity.getProperty('city'),
    hours: googleMapsDataEntity.getProperty('hours'),
    details: googleMapsDataEntity.getProperty('url'),
    stateAbbreviation: googleMapsDataEntity.getProperty('stateAbbreviation'),
    state: googleMapsDataEntity.getProperty('state'),
    coords: {
      lat: googleMapsDataEntity.getGeometry().h?.lat(),
      lng: googleMapsDataEntity.getGeometry().h?.lng()
    }
  }
}

const extractFeatureLocation = (featureLocation) => ({
  name: featureLocation.properties.name,
  displayName: featureLocation.properties.displayName,
  address: featureLocation.properties.address,
  zip: featureLocation.properties.zip,
  phone: featureLocation.properties.phone,
  hours: featureLocation.properties.hours,
  details: featureLocation.properties.url,
  line1: featureLocation.properties?.line1,
  line2: featureLocation.properties?.line2,
  city: featureLocation.properties?.city,
  stateAbbreviation: featureLocation.properties.stateAbbreviation,
  state: featureLocation.properties.state,
  coords: {
    lng: featureLocation.geometry.coordinates[0],
    lat: featureLocation.geometry.coordinates[1]
  }
})

function transformAddress (input) {
  return {
    firstName: input?.firstName,
    lastName: input?.lastName,
    addressPrimary: input?.line1,
    addressSecondary: input?.line2,
    city: input?.town,
    state: {
      isocode: input?.region?.isocode,
      countryIso: input?.region?.countryIso,
      name: input?.region?.name
    },
    zip: input?.postalCode,
    phone: input?.phone,
    country: {
      isocode: input?.country?.isocode
    },
    isDefault: input?.defaultAddress
  }
}

function getPotentialPromoFromEntry (entry, deliveryPointOfService) {
  const filteredPromotions = entry?.buyXGetYPotentialPromotions
    ?.filter(({ eligibleYProductQty }) => eligibleYProductQty > 0)
    ?.map((promo) => {
      const result = {
        ...promo,
        topYProducts: promo.topYProducts.map((item) => transformProductEntry(item))
      }
      // mark potential promotions wit the corresponding deliveryPointOfService if BOPIS
      if (deliveryPointOfService) {
        result.deliveryPointOfService = deliveryPointOfService
      }
      return result
    })

  return filteredPromotions?.length ? filteredPromotions : undefined
}

function getFreePromoProductEntryNumbersFromCart (cart) {
  let promoOrderEntryNumbers = []
  cart?.appliedProductPromotions
    ?.forEach((promo) => {
      const freeItems = promo?.consumedEntries?.filter((entry) => entry?.adjustedUnitPrice === 0)
      const freeItemsEntryNumbers = freeItems.map((e) => e?.orderEntryNumber)
      promoOrderEntryNumbers = [...promoOrderEntryNumbers, ...freeItemsEntryNumbers]
    })
  const uniquePromoOrderEntryNumbers = [...new Set(promoOrderEntryNumbers)]
  return uniquePromoOrderEntryNumbers
}

function getPotentialPromoFromCartResp (cartResp) {
  const resultsArr = []
  let entries
  if (cartResp?.splitCarts?.length) {
    entries = [
      ...cartResp.splitCarts[0]?.entries || [],
      ...cartResp.splitCarts[1]?.entries || []
    ]
  } else {
    entries = cartResp?.entries || []
  }

  entries.forEach((entry) => {
    const potentialPromoArr = getPotentialPromoFromEntry(entry, entry.deliveryPointOfService)
    potentialPromoArr?.forEach((potentialPromo) => {
      const promoAlreadyInResults = resultsArr
        .find((promoInResults) => {
          const isCodeSame = promoInResults.code === potentialPromo.code
          const isDeliveryPointSame = promoInResults.deliveryPointOfService?.name ===
              potentialPromo.deliveryPointOfService?.name
          return isCodeSame && isDeliveryPointSame
        })

      if (
        promoAlreadyInResults &&
          potentialPromo.eligibleYProductQty >
          promoAlreadyInResults.eligibleYProductQty
      ) {
        resultsArr[resultsArr.indexOf(promoAlreadyInResults)] = potentialPromo
      } else if (!promoAlreadyInResults) {
        resultsArr.push(potentialPromo)
      }
    })
  })

  return resultsArr
}

function transformValidateCartErrors (cartsArr, validateCartErrors) {
  // adjust 'entryNumber' to be a sub-cart 'entryNumber' instead of being the root cart's 'entryNumber'
  // duplicate errors for all entries in the nested cart with the same ID
  const result = []
  cartsArr.forEach((cart) => {
    validateCartErrors
      .filter((error) => cart.isBOPIS === error.isBOPIS)
      .forEach((error) => {
        const entriesWithSameID = cart?.entries.filter(({ id }) => {
          return id === error.productCode
        })
        entriesWithSameID.forEach(({ entryNumber }) => {
          result.push({
            ...error,
            entryNumber
          })
        })
      })
  })
  return result
}

export {
  getPotentialPromoFromCartResp,
  getPotentialPromoFromEntry,
  transformLogoEntry,
  transformSearchFlyoutData,
  transformProductEntry,
  transformProducts,
  transformCatalogEntry,
  transformHeroBanner,
  transformCheckoutData,
  transformCartEntry,
  transformDeliveryModeEntry,
  transformFormAddress,
  transformUserAddress,
  transformOrderEntry,
  transformOrdersEntry,
  transformSplitOrderEntry,
  transformOrderDetailsEntry,
  transformAVSAddress,
  transformFormAddressToAVS,
  transformAddressBookRecordToForm,
  extractFormAddress,
  toFeaturesCollectionGeoJSON,
  extractGoogleMapsDataEntity,
  extractFeatureLocation,
  transformPromos,
  transformStore,
  enrichCartItemsWithStockErrors,
  transformCartProduct,
  transformContentPagesSuggestions,
  transformAddress,
  getItemStockInStores,
  transformValidateCartErrors
}
